import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  IconButton,
  Typography,
  SvgIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross-icon.svg";
import { ReactComponent as CodeIcon } from "../../assets/images/icons/code-lg-icon.svg";
import { useFormik } from "formik";
import { handlePostRequest } from "../../services/PostTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { useDispatch } from "react-redux";

const HypotheticalAnalyzerSettingModel = ({
  open,
  handleClose,
  editable,
  DotCode,
  Title,
  resultRowData,
  dotTitleRowData,
  getDOTTitleSettingData,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!editable) {
      formik.resetForm();
    }
  }, [open, editable]);

  useEffect(() => {
    formik.setFieldValue("customEmployment", dotTitleRowData?.customEmployment);
    formik.setFieldValue("note", dotTitleRowData?.note);
  }, [dotTitleRowData]);

  const formik = useFormik({
    initialValues: {
      customEmployment: "",
      note: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.customEmployment) {
        errors.customEmployment = "Custom Employment is required.";
      }
      if (!data.note) {
        errors.note = "Note is required.";
      }

      return errors;
    },
    onSubmit: async (data) => {
      if (editable) {
        const { id, userid, createdAt, updatedAt, ...rest } = dotTitleRowData;

        const response = await dispatch(
          handlePutRequest(
            { ...rest, ...data, dotTitleId: id },
            `dot-titles/v2/`,
            true,
            true
          )
        );
        if (response.statusCode === 200) {
          handleClose();
          getDOTTitleSettingData();
        }
      } else {
        const formattedData = { ...resultRowData, ...data };
        formattedData["MASTCODE"] = formattedData["MasterCode"];
        delete formattedData.Id;
        delete formattedData.id;
        delete formattedData["MasterCode"];
        formattedData["DotCode"] = String(formattedData["DotCode"]);

        const response = await dispatch(
          handlePostRequest(formattedData, `dot-titles/v2/`, true, true)
        );
        if (response.statusCode === 200) {
          handleClose();
          getDOTTitleSettingData();
        }
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const handledClosed = (e) => {
    e.preventDefault();
    handleClose();
  };
  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <form className="login-bg" onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ borderBottom: "1px solid #666672", paddingBottom: 1 }}
            >
              <Typography variant="h6" component={"h6"}>
                {editable ? "Edit DOT Title" : "Add New DOT Title"}
              </Typography>
              <Box>
                <IconButton
                  size="small"
                  className="EyeIconButton"
                  onClick={handleClose}
                  sx={{ padding: "2px", borderRadius: "4px !important" }}
                >
                  <SvgIcon component={CrossIcon} inheritViewBox />
                </IconButton>
              </Box>
            </Stack>
          </DialogTitle>

          <DialogContent sx={{ padding: 3 }}>
            <Box>
              <Typography variant="p" component={"p"} fontWeight={"bold"}>
                {Title}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="p"
                component={"p"}
                color={"text.grey"}
                sx={{ display: "flex" }}
              >
                <CodeIcon />
                Code: {DotCode}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography variant="p" component={"p"} color={"text.grey"}>
                Custom Employment
              </Typography>
              <TextField
                hiddenLabel
                fullWidth
                size="small"
                variant="filled"
                placeholder="Please enter"
                name="customEmployment"
                value={formik.values.customEmployment}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("customEmployment")}
            </Box>
            <Box mt={2}>
              <Typography variant="p" component={"p"} color={"text.grey"}>
                Custom Note
              </Typography>
              <TextField
                hiddenLabel
                fullWidth
                multiline
                rows={6}
                size="small"
                variant="filled"
                placeholder="Please enter"
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
              />
              {getFormErrorMessage("note")}
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: 3, paddingTop: 1 }}>
            <Stack direction={"row"} spacing={2}>
              <Box>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={(e) => handledClosed(e)}
                >
                  {editable ? "Remove" : "Cancel"}
                </Button>
              </Box>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

export default HypotheticalAnalyzerSettingModel;
