import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M9.696 14.12C9.032 14.12 8.468 13.988 8.004 13.724C7.54 13.452 7.184 13.1 6.936 12.668C6.696 12.228 6.576 11.752 6.576 11.24C6.576 11 6.604 10.772 6.66 10.556C6.724 10.332 6.8 10.124 6.888 9.932C6.984 9.732 7.076 9.556 7.164 9.404C7.26 9.244 7.344 9.116 7.416 9.02L9.696 5.84C9.736 5.8 9.784 5.752 9.84 5.696C9.896 5.632 9.98 5.6 10.092 5.6H10.728C10.8 5.6 10.856 5.628 10.896 5.684C10.944 5.732 10.968 5.792 10.968 5.864C10.968 5.896 10.964 5.928 10.956 5.96C10.948 5.984 10.936 6.004 10.92 6.02L9.168 8.468C9.264 8.436 9.372 8.416 9.492 8.408C9.62 8.392 9.74 8.384 9.852 8.384C10.292 8.392 10.692 8.472 11.052 8.624C11.412 8.776 11.72 8.984 11.976 9.248C12.24 9.504 12.44 9.804 12.576 10.148C12.712 10.484 12.78 10.844 12.78 11.228C12.78 11.732 12.66 12.204 12.42 12.644C12.18 13.084 11.828 13.44 11.364 13.712C10.908 13.984 10.352 14.12 9.696 14.12ZM9.684 13.1C10.02 13.1 10.332 13.028 10.62 12.884C10.908 12.74 11.14 12.532 11.316 12.26C11.5 11.98 11.592 11.64 11.592 11.24C11.592 10.832 11.504 10.492 11.328 10.22C11.152 9.94 10.916 9.732 10.62 9.596C10.332 9.452 10.02 9.38 9.684 9.38C9.348 9.38 9.036 9.452 8.748 9.596C8.46 9.732 8.224 9.94 8.04 10.22C7.864 10.492 7.776 10.832 7.776 11.24C7.776 11.64 7.864 11.98 8.04 12.26C8.224 12.532 8.46 12.74 8.748 12.884C9.036 13.028 9.348 13.1 9.684 13.1Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M9.696 14.12C9.032 14.12 8.468 13.988 8.004 13.724C7.54 13.452 7.184 13.1 6.936 12.668C6.696 12.228 6.576 11.752 6.576 11.24C6.576 11 6.604 10.772 6.66 10.556C6.724 10.332 6.8 10.124 6.888 9.932C6.984 9.732 7.076 9.556 7.164 9.404C7.26 9.244 7.344 9.116 7.416 9.02L9.696 5.84C9.736 5.8 9.784 5.752 9.84 5.696C9.896 5.632 9.98 5.6 10.092 5.6H10.728C10.8 5.6 10.856 5.628 10.896 5.684C10.944 5.732 10.968 5.792 10.968 5.864C10.968 5.896 10.964 5.928 10.956 5.96C10.948 5.984 10.936 6.004 10.92 6.02L9.168 8.468C9.264 8.436 9.372 8.416 9.492 8.408C9.62 8.392 9.74 8.384 9.852 8.384C10.292 8.392 10.692 8.472 11.052 8.624C11.412 8.776 11.72 8.984 11.976 9.248C12.24 9.504 12.44 9.804 12.576 10.148C12.712 10.484 12.78 10.844 12.78 11.228C12.78 11.732 12.66 12.204 12.42 12.644C12.18 13.084 11.828 13.44 11.364 13.712C10.908 13.984 10.352 14.12 9.696 14.12ZM9.684 13.1C10.02 13.1 10.332 13.028 10.62 12.884C10.908 12.74 11.14 12.532 11.316 12.26C11.5 11.98 11.592 11.64 11.592 11.24C11.592 10.832 11.504 10.492 11.328 10.22C11.152 9.94 10.916 9.732 10.62 9.596C10.332 9.452 10.02 9.38 9.684 9.38C9.348 9.38 9.036 9.452 8.748 9.596C8.46 9.732 8.224 9.94 8.04 10.22C7.864 10.492 7.776 10.832 7.776 11.24C7.776 11.64 7.864 11.98 8.04 12.26C8.224 12.532 8.46 12.74 8.748 12.884C9.036 13.028 9.348 13.1 9.684 13.1Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M9.696 14.12C9.032 14.12 8.468 13.988 8.004 13.724C7.54 13.452 7.184 13.1 6.936 12.668C6.696 12.228 6.576 11.752 6.576 11.24C6.576 11 6.604 10.772 6.66 10.556C6.724 10.332 6.8 10.124 6.888 9.932C6.984 9.732 7.076 9.556 7.164 9.404C7.26 9.244 7.344 9.116 7.416 9.02L9.696 5.84C9.736 5.8 9.784 5.752 9.84 5.696C9.896 5.632 9.98 5.6 10.092 5.6H10.728C10.8 5.6 10.856 5.628 10.896 5.684C10.944 5.732 10.968 5.792 10.968 5.864C10.968 5.896 10.964 5.928 10.956 5.96C10.948 5.984 10.936 6.004 10.92 6.02L9.168 8.468C9.264 8.436 9.372 8.416 9.492 8.408C9.62 8.392 9.74 8.384 9.852 8.384C10.292 8.392 10.692 8.472 11.052 8.624C11.412 8.776 11.72 8.984 11.976 9.248C12.24 9.504 12.44 9.804 12.576 10.148C12.712 10.484 12.78 10.844 12.78 11.228C12.78 11.732 12.66 12.204 12.42 12.644C12.18 13.084 11.828 13.44 11.364 13.712C10.908 13.984 10.352 14.12 9.696 14.12ZM9.684 13.1C10.02 13.1 10.332 13.028 10.62 12.884C10.908 12.74 11.14 12.532 11.316 12.26C11.5 11.98 11.592 11.64 11.592 11.24C11.592 10.832 11.504 10.492 11.328 10.22C11.152 9.94 10.916 9.732 10.62 9.596C10.332 9.452 10.02 9.38 9.684 9.38C9.348 9.38 9.036 9.452 8.748 9.596C8.46 9.732 8.224 9.94 8.04 10.22C7.864 10.492 7.776 10.832 7.776 11.24C7.776 11.64 7.864 11.98 8.04 12.26C8.224 12.532 8.46 12.74 8.748 12.884C9.036 13.028 9.348 13.1 9.684 13.1Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const SixCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default SixCheck;
