import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M10.812 14C10.732 14 10.664 13.976 10.608 13.928C10.56 13.872 10.536 13.804 10.536 13.724V11.996H6.648C6.568 11.996 6.5 11.972 6.444 11.924C6.396 11.868 6.372 11.8 6.372 11.72V11.24C6.372 11.208 6.38 11.16 6.396 11.096C6.42 11.024 6.456 10.952 6.504 10.88L10.104 5.804C10.2 5.668 10.348 5.6 10.548 5.6H11.412C11.492 5.6 11.556 5.628 11.604 5.684C11.66 5.732 11.688 5.796 11.688 5.876V10.952H12.768C12.856 10.952 12.924 10.98 12.972 11.036C13.028 11.084 13.056 11.148 13.056 11.228V11.72C13.056 11.8 13.028 11.868 12.972 11.924C12.924 11.972 12.86 11.996 12.78 11.996H11.688V13.724C11.688 13.804 11.66 13.872 11.604 13.928C11.556 13.976 11.492 14 11.412 14H10.812ZM7.656 10.976H10.548V6.86L7.656 10.976Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M10.812 14C10.732 14 10.664 13.976 10.608 13.928C10.56 13.872 10.536 13.804 10.536 13.724V11.996H6.648C6.568 11.996 6.5 11.972 6.444 11.924C6.396 11.868 6.372 11.8 6.372 11.72V11.24C6.372 11.208 6.38 11.16 6.396 11.096C6.42 11.024 6.456 10.952 6.504 10.88L10.104 5.804C10.2 5.668 10.348 5.6 10.548 5.6H11.412C11.492 5.6 11.556 5.628 11.604 5.684C11.66 5.732 11.688 5.796 11.688 5.876V10.952H12.768C12.856 10.952 12.924 10.98 12.972 11.036C13.028 11.084 13.056 11.148 13.056 11.228V11.72C13.056 11.8 13.028 11.868 12.972 11.924C12.924 11.972 12.86 11.996 12.78 11.996H11.688V13.724C11.688 13.804 11.66 13.872 11.604 13.928C11.556 13.976 11.492 14 11.412 14H10.812ZM7.656 10.976H10.548V6.86L7.656 10.976Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M10.812 14C10.732 14 10.664 13.976 10.608 13.928C10.56 13.872 10.536 13.804 10.536 13.724V11.996H6.648C6.568 11.996 6.5 11.972 6.444 11.924C6.396 11.868 6.372 11.8 6.372 11.72V11.24C6.372 11.208 6.38 11.16 6.396 11.096C6.42 11.024 6.456 10.952 6.504 10.88L10.104 5.804C10.2 5.668 10.348 5.6 10.548 5.6H11.412C11.492 5.6 11.556 5.628 11.604 5.684C11.66 5.732 11.688 5.796 11.688 5.876V10.952H12.768C12.856 10.952 12.924 10.98 12.972 11.036C13.028 11.084 13.056 11.148 13.056 11.228V11.72C13.056 11.8 13.028 11.868 12.972 11.924C12.924 11.972 12.86 11.996 12.78 11.996H11.688V13.724C11.688 13.804 11.66 13.872 11.604 13.928C11.556 13.976 11.492 14 11.412 14H10.812ZM7.656 10.976H10.548V6.86L7.656 10.976Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const FourCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default FourCheck;
