import { useLocation, useNavigate } from "react-router-dom";

const usePrimaryRouteHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBackToPrimaryRoute = () => {
    const mainFiltersPage = [
      "/HypotheticalAnalyzer",
      "/HypotheticalToolSummaryORS",
      "/WorkHistory",
      "/TransferableSkill",
    ];

    if (mainFiltersPage.includes(location.pathname)) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return { goBackToPrimaryRoute };
};

export default usePrimaryRouteHistory;
