import {
  Box,
  Container,
  Stack,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  IconButton,
  SvgIcon,
  TextField,
  InputAdornment,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatString } from "../HypotheticalAnalyzer/utils";

const SOCGroupInfo = () => {
  let rowData = localStorage.getItem("row");
  if (rowData) rowData = JSON.parse(rowData);

  const location = useLocation();
  const { showOrsFilters = false } = location.state || {};

  const navigate = useNavigate();

  const [soc2918Definations, setSoc2918Definations] = useState([]);
  const [orsData, setOrsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetch("Soc2918Definations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const foundObject = data.find(
        (obj) => obj.SocCode === rowData["2018SocCode"]
      );
      setSoc2918Definations(foundObject);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("GeneratedOrsDataWithDash.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const socCode = rowData["2018SocCode"]?.replace("-", "");
      let formattedData = data
        .filter((d) => {
          return d.Soc2018Code === socCode;
        })
        .map((d) => ({
          Requirements: d.EstimateText,
          StandardError: d.StandardError,
          Estimate: d.Estimate,
        }));
      setOrsData(formattedData);
      return;
    });
  }, []);

  const onSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = orsData.filter((item) =>
    Object.values(item).some((val) =>
      val.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const redirectTo = (link) => {
    navigate(link, { state: { showOrsFilters } });
  };

  return (
    <>
      <Container
        maxWidth
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Stack
          spacing={1}
          className="bg-white"
          padding={1}
          borderRadius={"10px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={0}
          >
            <Box>
              <Typography variant="h6" component={"h6"}>
                SOC Information
              </Typography>
            </Box>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
            >
              {showOrsFilters && (
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    color="greyPrimary"
                    onClick={() =>
                      redirectTo("/SOCGroupORSFilterTransferableSkill")
                    }
                  >
                    ORS Filter
                  </Button>
                </Box>
              )}
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  color="greyPrimary"
                  onClick={() => redirectTo("/SOCGroupInfo")}
                >
                  Info
                </Button>
              </Box>
              <Box>
                <Button variant="contained" size="small" color="primary">
                  ORS Data
                </Button>
              </Box>
            </Stack>
          </Stack>
          <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
            <Stack padding={1}>
              <Box
                sx={{
                  borderBottom: "1px solid #666672",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="h6" component={"h6"}>
                  SOC {rowData["2018SocCode"]} {rowData["Title"]}
                </Typography>
              </Box>
              <Box sx={{ paddingTop: "5px" }}>
                <Typography variant="p" component={"p"}>
                  {formatString(rowData.DescriptionCombined)}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack
          spacing={1}
          className="bg-white"
          padding={1}
          borderRadius={"10px"}
          marginTop={"10px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box flexGrow={1}>
              <Typography variant="h6" component={"h6"}>
                {soc2918Definations ? soc2918Definations.SocTitle : "SOC Title"}{" "}
                in SOC {rowData["2018SocCode"]} {rowData["Title"]}
              </Typography>
            </Box>
            <Box flexGrow={1} textAlign={"end"}>
              <TextField
                hiddenLabel
                fullWidth
                size="small"
                variant="filled"
                placeholder="Please enter"
                onChange={onSearch}
                sx={{ maxWidth: "400px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <TableContainer
            component={Paper}
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
              boxShadow: "none",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Requirements</TableCell>
                  <TableCell align="left">Standard Error</TableCell>
                  <TableCell align="left">Estimate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.map((ors,index) => (
                  <TableRow
                  key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& td, & th": { paddingY: "4px !important" },
                      "&:hover": { backgroundColor: "#E6F3FB66" },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {ors.Requirements}
                    </TableCell>
                    <TableCell align="left">
                      {ors.StandardError || "-"}
                    </TableCell>
                    <TableCell align="left">
                      {ors.Estimate?.replace(">", "").replace("<", "")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </>
  );
};

export default SOCGroupInfo;
