import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  Stack,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@mui/material";
import DeleteModelIcon from "../../assets/images/icons/delete-modal-icon.svg";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { handlePutRequest } from "../../services/PutTemplate";

const ChangePasswordModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    password: "",
    confirmpassword: "",
    currentPassword: "",
  });

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangePassword = async () => {
    // Add your code here
    await dispatch(
      handlePutRequest(
        { password: "Faizan@123", confirmpassword: "Faizan@123", currentPassword: "1234" },
        `auth/change-password`,
        true,
        true
      )
    );
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "25%", borderRadius: "25px" } }}
      >
        <DialogContent sx={{ padding: 3 }}>
          <Stack gap={1} alignItems={"center"}>
            <Typography variant="p" paddingTop={1} sx={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#000000",
              textAlign: "center",
            }}>
              Change Password
            </Typography>
          </Stack>
          <Box mt={4}>
            <Box mt={3}>
              <InputLabel>Current Password</InputLabel>
              <TextField
                name="currentPassword"
                onChange={onChange}
                placeholder="Enter your password"
                variant="outlined"
                color="primary"
                autoComplete="off"
                type={"text"} // Add type attribute for password field
                sx={{
                  width: "100%",
                }}
              />
              {/* {getFormErrorMessage("password")} */}
            </Box>
          </Box>
          <Box mt={4}>
            <Box mt={3}>
              <InputLabel>New Password</InputLabel>
              <TextField
                name="password"
                onChange={onChange}
                placeholder="Enter your password"
                variant="outlined"
                color="primary"
                autoComplete="off"
                type={"text"} // Add type attribute for password field
                sx={{
                  width: "100%",
                }}
              />
              {/* {getFormErrorMessage("password")} */}
            </Box>
          </Box>
          <Box mt={4}>
            <Box mt={3}>
              <InputLabel>Rewrite new Password</InputLabel>
              <TextField
                name="confirmpassword"
                onChange={onChange}
                placeholder="Enter your password"
                variant="outlined"
                color="primary"
                autoComplete="off"
                type={"text"} // Add type attribute for password field
                sx={{
                  width: "100%",
                }}
              />
              {/* {getFormErrorMessage("password")} */}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 3, paddingTop: 0, gap: 2 }}>
          <Box flexGrow={1}>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              color="error"
              onClick={handleChangePassword}
            >
              Change Password
            </Button>
          </Box>
          <Box flexGrow={1}>
            <Button
              color="grey"
              variant="contained"
              sx={{ width: "100%" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ChangePasswordModal;
