import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/images/icons/search-icon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/images/icons/filter-icon.svg";

export default function AnalyzerFilters({
  filtersSorting,
  setFiltersSorting,
  OrsFilters = false,
}) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchText, setSearchText] = useState(filtersSorting.searchText);

  const filtersOptions = [
    "Custom Employment",
    "Oes Employment",
    "SVP",
    "Physical",
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = () => {
    setFiltersSorting({
      ...filtersSorting,
      filters: selectedOption,
      searchText,
    });
    handleClose();
  };

  return (
    <>
      <Box>
        <IconButton
          size="small"
          className="EyeIconButton"
          color="grey"
          onClick={handleClickOpen}
        >
          <SvgIcon component={FilterIcon} inheritViewBox />
        </IconButton>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          sx: {
            position: "absolute",
            right: 30,
            top: 150,
            borderRadius: "25px",
            maxHeight: "50vh",
            height: "fitContent",
            width: "400px", // Adjust the width as needed
            margin: 0,
          },
        }}
      >
        <DialogTitle color={"#000000"} fontSize={"20px"}>
          Apply Filters
        </DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          <Stack gap={1} alignItems={"center"}>
            <TextField
              hiddenLabel
              variant="filled"
              size="small"
              fullWidth
              placeholder="Search by title"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText === "" ? (
                      <SearchIcon />
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setSearchText("");
                          handleApply();
                        }}
                      >
                        X
                      </div>
                    )}
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                e.stopPropagation();

                if (e.key === "Enter") {
                  e.preventDefault(); // Prevent the default behavior of the Enter key
                  handleApply();
                }
              }}
            />
            {!OrsFilters && (
              <>
                <RadioGroup
                  value={selectedOption}
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    handleApply();
                  }}
                  sx={{
                    "& .Mui-checked + .MuiFormControlLabel-label": {
                      color: "primary.main",
                    },
                    width: "100%",
                  }}
                >
                  <FormControlLabel
                    sx={{ justifyContent: "space-between" }}
                    value="1"
                    control={<Radio disableRipple />}
                    label={filtersOptions[0]}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    sx={{ justifyContent: "space-between" }}
                    value="2"
                    control={<Radio disableRipple />}
                    label={filtersOptions[1]}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    sx={{ justifyContent: "space-between" }}
                    value="3"
                    control={<Radio disableRipple />}
                    label={filtersOptions[2]}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    sx={{ justifyContent: "space-between" }}
                    value="4"
                    control={<Radio disableRipple />}
                    label={filtersOptions[3]}
                    labelPlacement="start"
                  />
                </RadioGroup>
              </>
            )}
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                width: "95%",

                justifyContent: "flex-end",
                marginLeft: "10px",
              }}
            >
              <Box flexGrow={1}>
                <Button
                  variant="contained"
                  fullWidth
                  color="greyPrimary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
              {/* <Box flexGrow={1}>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleApply}
                >
                  Apply
                </Button>
              </Box> */}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
