import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M9.556 14.12C8.876 14.12 8.292 14.004 7.804 13.772C7.316 13.532 6.94 13.168 6.676 12.68C6.412 12.184 6.264 11.564 6.232 10.82C6.224 10.468 6.22 10.132 6.22 9.812C6.22 9.484 6.224 9.144 6.232 8.792C6.264 8.056 6.416 7.444 6.688 6.956C6.968 6.46 7.352 6.092 7.84 5.852C8.336 5.604 8.908 5.48 9.556 5.48C10.212 5.48 10.784 5.604 11.272 5.852C11.768 6.092 12.156 6.46 12.436 6.956C12.716 7.444 12.868 8.056 12.892 8.792C12.908 9.144 12.916 9.484 12.916 9.812C12.916 10.132 12.908 10.468 12.892 10.82C12.868 11.564 12.72 12.184 12.448 12.68C12.184 13.168 11.808 13.532 11.32 13.772C10.832 14.004 10.244 14.12 9.556 14.12ZM9.556 13.1C10.172 13.1 10.672 12.916 11.056 12.548C11.448 12.18 11.66 11.584 11.692 10.76C11.708 10.4 11.716 10.08 11.716 9.8C11.716 9.512 11.708 9.192 11.692 8.84C11.676 8.288 11.572 7.84 11.38 7.496C11.196 7.152 10.948 6.9 10.636 6.74C10.324 6.58 9.964 6.5 9.556 6.5C9.164 6.5 8.812 6.58 8.5 6.74C8.188 6.9 7.936 7.152 7.744 7.496C7.56 7.84 7.456 8.288 7.432 8.84C7.424 9.192 7.42 9.512 7.42 9.8C7.42 10.08 7.424 10.4 7.432 10.76C7.464 11.584 7.676 12.18 8.068 12.548C8.46 12.916 8.956 13.1 9.556 13.1Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M9.556 14.12C8.876 14.12 8.292 14.004 7.804 13.772C7.316 13.532 6.94 13.168 6.676 12.68C6.412 12.184 6.264 11.564 6.232 10.82C6.224 10.468 6.22 10.132 6.22 9.812C6.22 9.484 6.224 9.144 6.232 8.792C6.264 8.056 6.416 7.444 6.688 6.956C6.968 6.46 7.352 6.092 7.84 5.852C8.336 5.604 8.908 5.48 9.556 5.48C10.212 5.48 10.784 5.604 11.272 5.852C11.768 6.092 12.156 6.46 12.436 6.956C12.716 7.444 12.868 8.056 12.892 8.792C12.908 9.144 12.916 9.484 12.916 9.812C12.916 10.132 12.908 10.468 12.892 10.82C12.868 11.564 12.72 12.184 12.448 12.68C12.184 13.168 11.808 13.532 11.32 13.772C10.832 14.004 10.244 14.12 9.556 14.12ZM9.556 13.1C10.172 13.1 10.672 12.916 11.056 12.548C11.448 12.18 11.66 11.584 11.692 10.76C11.708 10.4 11.716 10.08 11.716 9.8C11.716 9.512 11.708 9.192 11.692 8.84C11.676 8.288 11.572 7.84 11.38 7.496C11.196 7.152 10.948 6.9 10.636 6.74C10.324 6.58 9.964 6.5 9.556 6.5C9.164 6.5 8.812 6.58 8.5 6.74C8.188 6.9 7.936 7.152 7.744 7.496C7.56 7.84 7.456 8.288 7.432 8.84C7.424 9.192 7.42 9.512 7.42 9.8C7.42 10.08 7.424 10.4 7.432 10.76C7.464 11.584 7.676 12.18 8.068 12.548C8.46 12.916 8.956 13.1 9.556 13.1Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M9.556 14.12C8.876 14.12 8.292 14.004 7.804 13.772C7.316 13.532 6.94 13.168 6.676 12.68C6.412 12.184 6.264 11.564 6.232 10.82C6.224 10.468 6.22 10.132 6.22 9.812C6.22 9.484 6.224 9.144 6.232 8.792C6.264 8.056 6.416 7.444 6.688 6.956C6.968 6.46 7.352 6.092 7.84 5.852C8.336 5.604 8.908 5.48 9.556 5.48C10.212 5.48 10.784 5.604 11.272 5.852C11.768 6.092 12.156 6.46 12.436 6.956C12.716 7.444 12.868 8.056 12.892 8.792C12.908 9.144 12.916 9.484 12.916 9.812C12.916 10.132 12.908 10.468 12.892 10.82C12.868 11.564 12.72 12.184 12.448 12.68C12.184 13.168 11.808 13.532 11.32 13.772C10.832 14.004 10.244 14.12 9.556 14.12ZM9.556 13.1C10.172 13.1 10.672 12.916 11.056 12.548C11.448 12.18 11.66 11.584 11.692 10.76C11.708 10.4 11.716 10.08 11.716 9.8C11.716 9.512 11.708 9.192 11.692 8.84C11.676 8.288 11.572 7.84 11.38 7.496C11.196 7.152 10.948 6.9 10.636 6.74C10.324 6.58 9.964 6.5 9.556 6.5C9.164 6.5 8.812 6.58 8.5 6.74C8.188 6.9 7.936 7.152 7.744 7.496C7.56 7.84 7.456 8.288 7.432 8.84C7.424 9.192 7.42 9.512 7.42 9.8C7.42 10.08 7.424 10.4 7.432 10.76C7.464 11.584 7.676 12.18 8.068 12.548C8.46 12.916 8.956 13.1 9.556 13.1Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const OCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default OCheck;
