import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Logo from "../../assets/images/logos/ve-toolkit-logo.svg";
import PaymentConfirmationIcon from "../../assets/images/icons/payment-confirmation.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross-icon.svg";
import { useNavigate } from "react-router";

function SubscriptionPackage() {
  const [paymentModal, setPaymentModal] = useState(false);

  const navigate = useNavigate();

  const handlePayment = () => {
    setPaymentModal(true);
  };

  const handleClose = () => {
    setPaymentModal(false);
  };

  return (
    <>
      <Dialog
        open={paymentModal}
        onClose={handleClose}
        PaperProps={{ sx: { width: "30%", borderRadius: "25px" } }}
      >
        <DialogContent sx={{ padding: 3 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              size="small"
              className="EyeIconButton"
              onClick={() => navigate("/")}
              sx={{ padding: "2px", borderRadius: "4px !important" }}
            >
              <SvgIcon component={CrossIcon} inheritViewBox />
            </IconButton>
          </Box>
          <Stack gap={1} alignItems={"center"}>
            <Box>
              <img src={PaymentConfirmationIcon} alt="" />
            </Box>
            <Typography variant="h5" paddingTop={1} textAlign={"center"}>
              Payment Transferred Successfully
            </Typography>
            <Typography textAlign={"center"} variant="p">
              Congratulations on Your Purchase of the 6-Month Subscription
              Package!
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
      <div className="Subscription-bg">
        {" "}
        <Container maxWidth sx={{ paddingBottom: "20px", px: { xs: "40px" } }}>
          <Box paddingTop={"40px"} paddingBottom={"50px"}>
            <img src={Logo} alt="" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              mb={"28px"}
              fontSize={"28px"}
              variant="h6"
              component={"h6"}
              color={"primary.main"}
            >
              Select Subscription&nbsp;
              <Typography
                variant="h6"
                component={"span"}
                color={"text.primary"}
                fontSize={"28px"}
              >
                Package to Continue
              </Typography>
            </Typography>
            <IconButton
              size="small"
              className="EyeIconButton"
              onClick={() => navigate("/subscriptionplan")}
              sx={{
                padding: "2px",
                borderRadius: "4px !important",
              }}
            >
              <SvgIcon component={CrossIcon} inheritViewBox />
            </IconButton>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Card Holder Name</Typography>
              <TextField
                fullWidth
                placeholder="Enter Name"
                variant="filled"
                hiddenLabel
                sx={{
                  "& .MuiFilledInput-root": {
                    backgroundColor: "white",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <PersonOutlineIcon /> */}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Card Number</Typography>
              <TextField
                fullWidth
                placeholder="Enter your Number"
                variant="filled"
                hiddenLabel
                sx={{
                  "& .MuiFilledInput-root": {
                    backgroundColor: "white",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <CreditCardIcon /> */}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Card Expiry Date</Typography>
              <TextField
                fullWidth
                placeholder="Enter Date"
                variant="filled"
                hiddenLabel
                sx={{
                  "& .MuiFilledInput-root": {
                    backgroundColor: "white",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <DateRangeIcon /> */}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">CVC</Typography>
              <TextField
                fullWidth
                placeholder="Enter Name"
                variant="filled"
                hiddenLabel
                sx={{
                  "& .MuiFilledInput-root": {
                    backgroundColor: "white",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <PaymentIcon /> */}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box mt={2} width="100%" display={"flex"} justifyContent={"right"}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handlePayment}
            >
              Continue to Pay
            </Button>
          </Box>
        </Container>
      </div>
    </>
  );
}

export default SubscriptionPackage;
