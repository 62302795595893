import {
  Box,
  Container,
  Stack,
  TextField,
  Grid,
  Button,
  IconButton,
  Typography,
  Tab,
  Tabs,
  Pagination,
  Checkbox,
  InputLabel,
  InputAdornment,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow-icon.svg";
import { useEffect, useRef, useState } from "react";
import {
  NCheck,
  OCheck,
  FCheck,
  CCheck,
  SCheck,
} from "../../Common/CustomCheckbox";
import { Link, useNavigate } from "react-router-dom";
import CustomSwitch from "../../Common/Switch/CustomSwitch";
import { useFormik } from "formik/dist";
import SortByDialog from "../HypotheticalAnalyzer/SortByDialog";
import AnalyzerFilters from "../HypotheticalAnalyzer/helperComponents/analyzerFilter";
import { onAddNewFilters } from "../HypotheticalAnalyzer/utils";
import AnalyzerSorting from "../HypotheticalAnalyzer/helperComponents/analyzerSorting";
import { getAllOrsfilters } from "../../services/getORSFilters";
import { postAllOrsfilters } from "../../services/postORSFilters";
import { putAllOrsfilters } from "../../services/putORSFilters";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { handleGetRequest } from "../../services/GetTemplate";
import { useOrsSearchWorker } from "./../../hooks/useOrsSearchWorker";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HypotheticalToolSummaryORS = () => {
  let dbName = "veToolkitDB";
  let dbCurrentVersion;

  const localStorageNotes = localStorage.getItem("notes");
  const localStoragePaginatedValue = sessionStorage.getItem("paginatedValue");

  const [occupationsHeight, setOccupationsHeight] = useState();
  const [physicalTabHeight, setPhysicalTabHeight] = useState();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  // const [orsData, setOrsData] = useState([]);
  const [actualOrsData, setActualOrsData] = useState([]);
  const [soc2018, setSoc2018] = useState([]);

  // data states
  const [orsPhysicalDemandData, setOrsPhysicalDemandData] = useState([]);
  const [orsCognitiveDemandData, setOrsCognitiveDemandData] = useState([]);
  const [orsEducationalDemandData, setOrsEducationalDemandData] = useState([]);
  const [orsEnviromentalDemandData, setOrsEnviromentalDemandData] = useState(
    []
  );
  const [orsFilterSummaryDemandData, setOrsFilterSummaryDemandData] = useState(
    []
  );
  const [orsFilterPhysicalDemandData, setOrsFilterPhysicalDemandData] =
    useState([]);
  const [orsFilterCognitiveDemandData, setOrsFilterCognitiveDemandData] =
    useState([]);
  const [orsFilterEducationalDemandData, setOrsFilterEducationalDemandData] =
    useState([]);
  const [orsFilterEnviromentalDemandData, setOrsFilterEnviromentalDemandData] =
    useState([]);
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState([]);
  // to store the filtered Estimate Code
  const [selectedEstimateCode, setSelectedEstimateCode] = useState([]);
  // to store the result Estimate Code
  const [selectedFilteredOccupationsCode, setSelectedFilteredOccupationsCode] =
    useState(null);
  const [openSortByDialog, setOpenSortByDialog] = useState(false);
  const [searchFilters, setSearchFilters] = useState("");
  const [notes, setNotes] = useState(localStorageNotes || "");
  //filteredOccupations are use to show the results after searching and filtering occupations
  // on the basis of search text written in apply filters modal.
  const [filteredOccupations, setFilteredOccupations] = useState([]);
  //allfilteredOccupations are use to store the result of all filters
  const [allFilteredOccupations, setAllFilteredOccupations] = useState([]);
  const [orsFiltersData, setOrsFiltersData] = useState([]);
  const [counts, setCounts] = useState(0);
  const [showingFiltersId, setShowingFiltersId] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    Number(localStoragePaginatedValue)
  );
  const [isLoading, setLoadingState] = useState(false);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const [isDeleteloading, setIsDeleteloading] = useState(false);
  const [isAddNewloading, setIsAddNewloading] = useState(false);
  const [movePaginationToLast, setMovePaginationToLast] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  const textareaRef = useRef(null);
  const navigate = useNavigate();
  const { result, runOrsSearchWorker, runOrsSortingWorker } =
    useOrsSearchWorker();

  // sorting and filtering
  const [filtersSorting, setFiltersSorting] = useState({
    sorting: 1,
    filters: "",
    searchText: "",
  });

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [notes]);

  useEffect(() => {}, [filteredOccupations]);

  useEffect(() => {
    localStorage.setItem("SearchRequest", "ORS");
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;
      // console.log("Current IndexedDB version:", currentVersion);

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  useEffect(() => {
    if (!selectedFilteredOccupationsCode || !Array.isArray(filteredOccupations))
      return;
    const items = filteredOccupations?.filter((item) =>
      selectedFilteredOccupationsCode.includes(item.Soc2018Code)
    );
    sessionStorage.setItem("ORSResults", JSON.stringify(items ? items : []));
  }, [selectedFilteredOccupationsCode]);

  useEffect(() => {
    const hypotheticalFilters = localStorage.getItem("hypotheticalFilters");
    if (hypotheticalFilters) {
      const data = JSON.parse(hypotheticalFilters);
      setOrsFiltersData(data);
      setCounts(data.count);
    }
  }, []);

  useEffect(() => {
    if (
      isFirstTimeLoading &&
      (!orsCognitiveDemandData ||
        !orsCognitiveDemandData.length ||
        !orsPhysicalDemandData ||
        !orsPhysicalDemandData.length ||
        !orsEducationalDemandData ||
        !orsEducationalDemandData.length ||
        !orsEnviromentalDemandData ||
        !orsEnviromentalDemandData.length ||
        !orsFilterSummaryDemandData ||
        !orsFilterSummaryDemandData.length)
    )
      return;
    setIsFirstTimeLoading(false);
  }, [
    orsCognitiveDemandData,
    orsPhysicalDemandData,
    orsEducationalDemandData,
    orsEnviromentalDemandData,
    orsFilterSummaryDemandData,
  ]);

  useEffect(() => {
    if (
      isFirstTimeLoading ||
      !actualOrsData.length ||
      !oewsNationalM2022Data.length
    )
      return;

    const ORSFiltersData = JSON.parse(sessionStorage.getItem("ORSFiltersData"));
    const localHypothicalAnalyzer = JSON.parse(
      localStorage.getItem("hypotheticalFilters")
    );
    if (ORSFiltersData && Object.keys(ORSFiltersData).length) {
      const appliedCodes = ORSFiltersData?.filters
        ? ORSFiltersData?.filters
        : [];
      renderedAppliedFilters({
        localFilters: ORSFiltersData,
        seletedEstimateCode: appliedCodes,
      });
      setSelectedEstimateCode(appliedCodes);
    } else if (localHypothicalAnalyzer) {
      const filters = localHypothicalAnalyzer.orsFilters[currentPage - 1];
      if (!filters) return;
      // Formting the data to match the ORSFiltersData
      const filtersData = {
        filters: filters.filters.OrsFilters,
        results: filters.results.OrsResults || [],
        id: filters.id,
      };
      const appliedCodes = filters.filters.OrsFilters
        ? filters.filters.OrsFilters
        : [];
      renderedAppliedFilters({
        localFilters: filtersData,
        seletedEstimateCode: appliedCodes,
      });
      setSelectedEstimateCode(appliedCodes);
    }
  }, [isFirstTimeLoading, actualOrsData, oewsNationalM2022Data]);

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setOccupationsHeight(innerHeight - 173 - 47 - 30);
      setPhysicalTabHeight(innerHeight - 340);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetch("OrsPhysicalDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsPhysicalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("CofnitiveOrsDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsCognitiveDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("OrsEducationalTrainingDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsEducationalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("OrsEnvironmentalDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsEnviromentalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["ORSMasterTable"], "readonly");
      const objectStore = transaction.objectStore("ORSMasterTable");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setActualOrsData(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("ORSMasterTable", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (loadData) {
      setLoadData(false);
      getTableData();
    }
  }, [loadData]);

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["2010To2018Soc"], "readonly");
      const objectStore = transaction.objectStore("2010To2018Soc");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setSoc2018(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("2010To2018Soc", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  const onSave = async () => {
    setIsAddNewloading(true);
    await onAddNewFilters();
    setLoadData(true);
    setIsAddNewloading(false);
  };

  const onAddnew = async () => {
    setIsAddNewloading(true);
    await onAddNewFilters();

    // sessionStorage.removeItem("paginatedValue");
    sessionStorage.removeItem("selectedFiltersId");

    sessionStorage.removeItem("DOTFiltersData");
    sessionStorage.removeItem("ORSFiltersData");
    localStorage.removeItem("notes");
    setNotes("");

    await onAddNewFilters(true);
    setDefaultFilters();
    setMovePaginationToLast(true);
    setLoadData(true);
    setIsAddNewloading(false);
  };

  const onDuplicate = async () => {
    localStorage.setItem("duplicate", true);
    localStorage.setItem("request", JSON.stringify("ORS"));
    setIsAddNewloading(true);
    await onAddNewFilters();

    sessionStorage.removeItem("paginatedValue");
    sessionStorage.removeItem("selectedFiltersId");
    localStorage.removeItem("hypotheticalFilters");

    await onAddNewFilters();
    setMovePaginationToLast(true);
    setLoadData(true);
    setIsAddNewloading(false);
  };

  const setDefaultFilters = () => {
    const updateState = (state) => {
      return state.map((button) => ({ ...button, isChecked: false }));
    };
    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return { ...subtitle, isChecked: false };
        });
        return { ...button, subtitles };
      });
      return newData;
    };

    setOrsFilterSummaryDemandData((prevState) =>
      updateSummary(prevState, orsFilterSummaryDemandData)
    );
    setOrsFilterPhysicalDemandData((prevState) =>
      updateState(prevState, orsFilterPhysicalDemandData)
    );
    setOrsFilterCognitiveDemandData((prevState) =>
      updateState(prevState, orsFilterCognitiveDemandData)
    );
    setOrsFilterEducationalDemandData((prevState) =>
      updateState(prevState, orsFilterEducationalDemandData)
    );
    setOrsFilterEnviromentalDemandData((prevState) =>
      updateState(prevState, orsFilterEnviromentalDemandData)
    );
    setFilteredOccupations([]);
    setAllFilteredOccupations([]);
  };

  const getTableData = async () => {
    const hypotheticalFilters = localStorage.getItem("hypotheticalFilters");
    if (hypotheticalFilters) {
      const data = JSON.parse(hypotheticalFilters);
      setOrsFiltersData(data);
      setCounts(data.count);
    }

    const profileId = Number(localStorage.getItem("profileId"));
    const response = await handleGetRequest(`filter?profileId=${profileId}`);
    // have to map this values to showing data
    if (response?.data) {
      if (!response?.data?.count) {
        sessionStorage.removeItem("paginatedValue");
        sessionStorage.removeItem("selectedFiltersId");
      }
      localStorage.setItem(
        "hypotheticalFilters",
        JSON.stringify(response?.data)
      );
      setOrsFiltersData(response?.data);
      setCounts(response.data.count);

      if (movePaginationToLast || currentPage === 0) {
        const filters = response?.data?.orsFilters;
        setCurrentPage(filters.length);
        sessionStorage.setItem("paginatedValue", filters.length);
        sessionStorage.setItem(
          "selectedFiltersId",
          filters[filters.length - 1]?.id
        );
        if (movePaginationToLast) {
          localStorage.setItem(
            "notes",
            filters[filters.length - 1].filters.notes
          );
        }
      }
    }
    setMovePaginationToLast(false);
  };

  const onDelete = async () => {
    if (!orsFiltersData || !orsFiltersData.orsFilters) return;
    setIsDeleteloading(true);
    const asd = orsFiltersData.orsFilters[currentPage - 1];

    const profileId = Number(localStorage.getItem("profileId"));
    if (asd.id)
      await handleDeleteRequest(
        `filter?filterId=${asd.id}&profileId=${profileId}`
      );

    const updateState = (state) => {
      return state.map((button) => ({ ...button, isChecked: false }));
    };
    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return { ...subtitle, isChecked: false };
        });
        return { ...button, subtitles };
      });
      return newData;
    };

    setOrsFilterSummaryDemandData((prevState) =>
      updateSummary(prevState, orsFilterSummaryDemandData)
    );
    setOrsFilterPhysicalDemandData((prevState) =>
      updateState(prevState, orsFilterPhysicalDemandData)
    );
    setOrsFilterCognitiveDemandData((prevState) =>
      updateState(prevState, orsFilterCognitiveDemandData)
    );
    setOrsFilterEducationalDemandData((prevState) =>
      updateState(prevState, orsFilterEducationalDemandData)
    );
    setOrsFilterEnviromentalDemandData((prevState) =>
      updateState(prevState, orsFilterEnviromentalDemandData)
    );
    setFilteredOccupations([]);
    setAllFilteredOccupations([]);
    onChangePagination({}, 1);
    await getTableData();
    setIsDeleteloading(false);
  };

  useEffect(() => {
    fetch("HypotheticalSummeryCodes.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const formattedSummaryData = formateSummaryData(data);
      setOrsFilterSummaryDemandData(formattedSummaryData);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setOewsNationalM2022Data(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  // Updating filters data
  useEffect(() => {
    setOrsFilterPhysicalDemandData(orsPhysicalDemandData);
  }, [orsPhysicalDemandData]);
  useEffect(() => {
    setOrsFilterCognitiveDemandData(orsCognitiveDemandData);
  }, [orsCognitiveDemandData]);
  useEffect(() => {
    setOrsFilterEducationalDemandData(orsEducationalDemandData);
  }, [orsEducationalDemandData]);
  useEffect(() => {
    setOrsFilterEnviromentalDemandData(orsEnviromentalDemandData);
  }, [orsEnviromentalDemandData]);

  useEffect(() => {
    /**
     * On filterSorting (contains filters, search, and sorting order) changes
     * filter all the data
     */
    setLoadingState(true);
    const data = {
      sorting: true,
      data: allFilteredOccupations,
      filtersSorting,
      oewsNationalM2022Data,
    };
    runOrsSortingWorker(data);
  }, [filtersSorting]);

  const onViewDetailsClick = (item) => {
    navigate("/SOCDOTGroupInfo", { state: item });
  };

  const formateSummaryData = (data) => {
    const uniqueTitles = [
      ...new Set(data.map((item) => item.HypotheticalOrsSummerySection)),
    ];

    // Group RenameTitleTo by HypotheticalOrsSummerySection
    const groupedData = uniqueTitles.map((title) => ({
      HypotheticalOrsSummerySection: title,
      selectedEstimateCode: null,
      subtitles: data
        .filter((item) => item.HypotheticalOrsSummerySection === title)
        .map(({ RenameTitleTo, EstimateCode }) => {
          return {
            RenameTitleTo,
            EstimateCode,
            // svp 1 & 2 has 2 estimateCode so first one should be checked true by default
            isChecked: EstimateCode === "00065" ? true : false,
          };
        }),
    }));
    return groupedData;
  };

  function getFirstCharOfLastWord(str) {
    let words = str.split(" ");
    let lastWord = words[words.length - 1];
    return lastWord.charAt(0);
  }

  // Function to render radio button based on value
  const getCheckBox = (value, name, subtitle) => {
    switch (value) {
      case "N":
        return (
          <NCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "S":
        return (
          <SCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "O":
        return (
          <OCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "F":
        return (
          <FCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "C":
        return (
          <CCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      default:
        return null;
    }
  };

  function findTotalEmp(searchString, data) {
    let totalEmp = 0;
    data?.forEach((item) => {
      const searchStringWithDash =
        searchString.slice(0, 2) + "-" + searchString.slice(2);
      if (item.OccCode.includes(searchStringWithDash)) {
        totalEmp += Number(item.TotalEmp);
      }
    });
    return totalEmp;
  }

  // const mapSocToOrsData = (arr1, arr2) => {
  //   return arr1.map((obj1) => {
  //     const match = arr2.find((obj2) => {
  //       return obj1.Soc2018Code === obj2["2018SocCode"];
  //     });
  //     if (match) {
  //       console.log("matched", obj1["2018SocTitle"]);
  //       obj1["2018SocTitle"] = match["2018SocTitle"];
  //     }
  //     return obj1;
  //   });
  // };

  const onSaveResultInLocalStorage = (filters, results, value) => {
    let obj = {
      filters,
      results: {},
    };

    sessionStorage.removeItem("ORSFiltersData");
    sessionStorage.setItem("ORSFiltersData", JSON.stringify(obj));
    if (value) {
      sessionStorage.setItem("paginatedValue", value);
      setCurrentPage(value);
      const data = orsFiltersData.orsFilters[value - 1];
      obj = {
        filters: data?.filters?.DotFilters,
        results: data?.results?.DotResults,
      };

      sessionStorage.setItem("DOTFiltersData", JSON.stringify(obj));
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  function sortArrayByPresence(array1, array2) {
    // Create a set of IDs from array1 for quick lookup
    //
    if (!Array.isArray(array1) && !Array.isArray(array2)) return [];

    const idsInArray1 = new Set(array1.map((obj) => obj.Id));
    if (!idsInArray1.length) return array2;

    array2.forEach((obj) => {
      if (idsInArray1.has(obj.Id)) {
        obj.isChecked = true;
      }
    });
    // Sort array2 such that elements present in both arrays appear first
    array2.sort((a, b) => {
      const aInArray1 = idsInArray1.has(a.Id) ? 1 : 0;
      const bInArray1 = idsInArray1.has(b.Id) ? 1 : 0;
      return bInArray1 - aInArray1;
    });

    return array2;
  }

  useEffect(() => {
    if (result?.isSorting) {
      delete result.isSorting;
      setFilteredOccupations(result.result);
    } else {
      delete result.isSorting;
      setAllFilteredOccupations(result.result);
      setFilteredOccupations(result.result);
    }
    setLoadingState(false);
  }, [result]);

  const searchLocally = ({ seletedEstimateCode = null, saveData = false }) => {
    setLoadingState(true);

    let filteredObjects;
    let selectedEstimateCodeLocal = selectedEstimateCode;
    if (seletedEstimateCode) {
      selectedEstimateCodeLocal = seletedEstimateCode;
    }

    if (!selectedEstimateCodeLocal || !selectedEstimateCodeLocal.length) {
      // if no filter is selected
      filteredObjects = [];
    } else {
      // filteredObjects = actualOrsData.filter((obj) =>
      //   selectedEstimateCodeLocal.includes(obj.EstimateCode)
      // );
    }

    const preSelectedResults = JSON.parse(sessionStorage.getItem("ORSResults"));

    if (preSelectedResults && preSelectedResults.length) {
      const Soc2018Code = preSelectedResults.map((item) => item.Soc2018Code);
      setSelectedFilteredOccupationsCode(Soc2018Code);
    }
    // setFilteredOccupations([]);

    const estimateCodeWithSvpCase = [...selectedEstimateCodeLocal];
    if (
      estimateCodeWithSvpCase.includes("00064") &&
      !estimateCodeWithSvpCase.includes("00065")
    ) {
      estimateCodeWithSvpCase.push("00065");
    }
    console.log("estimateCodeWithSvpCase", estimateCodeWithSvpCase);
    const data = {
      dataset: actualOrsData,
      desiredEstimateCodes: estimateCodeWithSvpCase,
      oewsNationalM2022Data,
      preSelectedResults,
    };
    runOrsSearchWorker(data);
    // going to save the filters and results in local storage
    onSaveResultInLocalStorage(selectedEstimateCodeLocal, filteredObjects);
    if (saveData) {
      onSave();
    }
  };

  const onSearch = ({ seletedEstimateCode, saveData = false }) => {
    setDisabledSaveButton(true);
    searchLocally({ seletedEstimateCode, saveData });
  };

  function filterByDefinition(array, searchText) {
    // Convert search text to lowercase for case insensitive search
    const lowerCaseSearchText = searchText.toLowerCase();

    // Filter the array
    return array.filter((item) => {
      // Ensure the item has a definition key and it's a string
      //! have to update this key (defination) for searching on
      //! filtered occupations.
      if (item.Defination && typeof item.Defination === "string") {
        // Convert the Defination to lowercase and check if it includes the search text
        return item.Defination.toLowerCase().includes(lowerCaseSearchText);
      }
      return false;
    });
  }
  const OnFiltersSearch = (text) => {
    // filtering data from All physical
    const phyFilteredData = filterByDefinition(orsPhysicalDemandData, text);
    const cogFilteredData = filterByDefinition(orsCognitiveDemandData, text);
    const envFilteredData = filterByDefinition(orsEnviromentalDemandData, text);
    const eduFilteredData = filterByDefinition(orsEducationalDemandData, text);
    setOrsFilterPhysicalDemandData(phyFilteredData);
    setOrsFilterCognitiveDemandData(cogFilteredData);
    setOrsFilterEnviromentalDemandData(envFilteredData);
    setOrsFilterEducationalDemandData(eduFilteredData);
    setSearchFilters(text);
  };

  const handleValueToggle = (value, toggle) => {
    console.log(
      "the estimated code in handleValueToggle",
      selectedEstimateCode
    );
    const arr = selectedEstimateCode;
    if (toggle) {
      // Add value if it doesn't exist
      if (!arr.includes(value)) {
        arr.push(value);
      }
    } else {
      // Remove value if it exists
      const index = arr.indexOf(value);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setSelectedEstimateCode([...arr]);
  };

  const handleResultToggle = (item) => {
    let arr = selectedFilteredOccupationsCode;

    const value = item.Soc2018Code;

    if (!arr) {
      arr = [value];
    } else if (!item.isChecked) {
      // Add value if it doesn't exist
      if (!arr.includes(value)) {
        arr.push(value);
      }
    } else {
      // Remove value if it exists
      const index = arr.indexOf(value);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setSelectedFilteredOccupationsCode([...arr]);
    setFilteredOccupations((prevItems) => {
      const updatedState = prevItems.map((prevItem) =>
        prevItem.Id === item.Id
          ? { ...prevItem, isChecked: !prevItem.isChecked }
          : prevItem
      );
      const sortedState = updatedState.sort((a, b) => {
        if (a.isChecked === b.isChecked) {
          return 0;
        } else if (a.isChecked === true) {
          return -1;
        } else if (b.isChecked === true) {
          return 1;
        } else if (a.isChecked === false) {
          return -1;
        } else if (b.isChecked === false) {
          return 1;
        } else {
          return 0;
        }
      });
      // putFiltersResult(selectedEstimateCode, sortedState);'
      onSaveResultInLocalStorage(selectedEstimateCode, sortedState);
      return sortedState;
    });

    setAllFilteredOccupations((prevItems) => {
      const updatedState = prevItems.map((prevItem) =>
        prevItem.Id === item.Id
          ? { ...prevItem, isChecked: !prevItem.isChecked }
          : prevItem
      );
      const sortedState = updatedState.sort((a, b) => {
        if (a.isChecked === b.isChecked) {
          return 0;
        } else if (a.isChecked === true) {
          return -1;
        } else if (b.isChecked === true) {
          return 1;
        } else if (a.isChecked === false) {
          return -1;
        } else if (b.isChecked === false) {
          return 1;
        } else {
          return 0;
        }
      });
      return sortedState;
    });
    setDisabledSaveButton(false);
  };

  function toggleValueInArray(array, value) {
    const index = array.indexOf(value);
    if (index === -1) {
      // Value is not present, add it
      array.push(value);
    } else {
      // Value is present, remove it
      array.splice(index, 1);
    }
    return array;
  }

  const handleRadioButtonChange = (estimateCode) => {
    const updatedEsCode = toggleValueInArray(
      selectedEstimateCode,
      estimateCode
    );
    setSelectedEstimateCode([...updatedEsCode]);
  };

  const handleToggleChange = (EstimateCode, isChecked) => {
    const updateState = (state) => {
      return state.map((button) =>
        button.EstimateCode === EstimateCode ? { ...button, isChecked } : button
      );
    };
    // console.log("the updated state is",updateState)
    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return subtitle.EstimateCode === EstimateCode
            ? { ...subtitle, isChecked }
            : subtitle;
        });
        return { ...button, subtitles };
      });
      console.log("the EstimateCode", EstimateCode, isChecked);
      if (EstimateCode === "00064") {
        newData[0].subtitles[1].isChecked = isChecked;
      }
      return newData;
    };

    if (value === 0)
      setOrsFilterSummaryDemandData((prevState) =>
        updateSummary(prevState, orsFilterSummaryDemandData)
      );
    if (value === 1 || value === 5)
      setOrsFilterPhysicalDemandData((prevState) =>
        updateState(prevState, orsFilterPhysicalDemandData)
      );
    if (value === 2 || value === 5)
      setOrsFilterCognitiveDemandData((prevState) =>
        updateState(prevState, orsFilterCognitiveDemandData)
      );
    if (value === 3 || value === 5)
      setOrsFilterEducationalDemandData((prevState) =>
        updateState(prevState, orsFilterEducationalDemandData)
      );
    if (value === 4 || value === 5)
      setOrsFilterEnviromentalDemandData((prevState) =>
        updateState(prevState, orsFilterEnviromentalDemandData)
      );

    handleValueToggle(EstimateCode, isChecked);
  };

  const onChangePagination = async (e, value) => {
    // if (!orsFiltersData || !orsFiltersData.orsFilters) return;
    setLoadingState(true);
    const asd = orsFiltersData.orsFilters[value - 1];
    const localFilters = {
      filters: asd?.filters?.OrsFilters,
      results: asd?.results?.OrsResults,
      id: asd?.id,
    };
    const seletedEstimateCode = asd?.filters?.OrsFilters
      ? [...asd?.filters?.OrsFilters]
      : [];
    setSelectedEstimateCode(seletedEstimateCode);
    const note = asd.filters.notes;
    setNotes(note);
    localStorage.setItem("notes", note);
    let results = asd?.results?.OrsResults;

    if (results) {
      results = results.map((data) => {
        const totalEmp = findTotalEmp(data.Soc2018Code, oewsNationalM2022Data);
        return {
          ...data,
          totalEmp,
        };
      });
    }
    sessionStorage.setItem(
      "ORSResults",
      JSON.stringify(results ? results : [])
    );
    setFilteredOccupations(results ? results : []);
    setAllFilteredOccupations(results ? results : []);

    renderedAppliedFilters({ value, localFilters, seletedEstimateCode });
    sessionStorage.setItem("selectedFiltersId", asd?.id);
  };

  const renderedAppliedFilters = ({
    value,
    localFilters,
    seletedEstimateCode,
  }) => {
    const filteredData = localFilters || orsFiltersData.orsFilters[value - 1];
    if (!filteredData) {
      setDefaultFilters();
      return;
    }

    setShowingFiltersId(filteredData.id);

    const updateState = (state) => {
      return state.map((button) => {
        return filteredData.filters?.includes(button.EstimateCode)
          ? { ...button, isChecked: true }
          : { ...button, isChecked: false };
      });
    };
    const updateSummary = (state) => {
      console.log("the states are", state);
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return filteredData.filters?.includes(subtitle.EstimateCode)
            ? { ...subtitle, isChecked: true }
            : { ...subtitle, isChecked: false };
        });
        return { ...button, subtitles };
      });
      console.log("the new data is", newData);
      if (filteredData.filters?.includes("00064")) {
        newData[0].subtitles[1].isChecked = false;
      }
      return newData;
    };

    setOrsFilterSummaryDemandData((prevState) =>
      updateSummary(prevState, orsFilterSummaryDemandData)
    );

    setOrsFilterPhysicalDemandData((prevState) =>
      updateState(prevState, orsFilterPhysicalDemandData)
    );
    setOrsFilterCognitiveDemandData((prevState) =>
      updateState(prevState, orsFilterCognitiveDemandData)
    );
    setOrsFilterEducationalDemandData((prevState) =>
      updateState(prevState, orsFilterEducationalDemandData)
    );
    setOrsFilterEnviromentalDemandData((prevState) =>
      updateState(prevState, orsFilterEnviromentalDemandData)
    );

    // if (
    //   typeof filteredData.results[filteredData.results.length - 1] === "string"
    // ) {
    //   const note = filteredData.results?.pop();
    //   setNotes(note);
    // }
    setFilteredOccupations(filteredData.results);
    setAllFilteredOccupations(filteredData.results);
    onSaveResultInLocalStorage(
      localFilters.filters,
      filteredData.results,
      value
    );
    onSearch({ seletedEstimateCode });
  };

  const handleCloseSortByDialog = () => {
    setOpenSortByDialog(false);
  };

  const [isFocused, setIsFocused] = useState(true);

  const PaginatedResults = () => (
    <Pagination
      count={counts || 0}
      color="primary"
      page={Number(currentPage)}
      onChange={onChangePagination}
    />
  );

  function getFirstCharOfLastWord(str) {
    let words = str.split(" ");
    let lastWord = words[words.length - 1];
    return lastWord.charAt(0);
  }

  const formik = useFormik({
    initialValues: {
      svp: false,
      noDriving: false,
      humidity: false,
      fastPace: false,
      sedentary: false,
      wetness: false,
      hearing: false,
      light: false,
      movingMachinery: false,
      quietNoise: false,
      vision: false,
      medium: false,
      srt: false,
      farVision: false,
      heavy: false,
      extremeHeat: false,
      prodPace: false,
      hazardousContaminants: false,
      noPublic: false,
      noFarVision: false,
      ladderRope: false,
      reachBilateral: "",
      overheadBilateral: "",
      handling: "",
      fingering: "",
      feeling: "",
      footControls: "",
      rampStairs: "",
      balance: "",
      stoop: "",
      crouch: "",
      kneel: "",
      crawl: "",
    },
    onSubmit: async (data) => {},
  });

  // const [CalEst, setCalEst] = useState([]);
  // const [overallValue, setOverallValue] = useState(null);
  // const [orsData, setOrsData] = useState(null);
  // const [product, setProduct] = useState(1); // State to store the product of array values

  // const addUniqueEstimate = (estimate) => {
  //   if (!CalEst.includes(estimate)) {
  //     setCalEst((prevCalEst) => {
  //       const uniqueValues = new Set([...prevCalEst, estimate]);
  //       const uniqueArray = Array.from(uniqueValues);
  //       // Calculate the product of the unique array values
  //       const productResult = uniqueArray.reduce((acc, val) => acc * val, 1);
  //       setProduct(productResult); // Update the product state
  //       return uniqueArray;
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (oewsNationalM2022Data && product !== 1) {
  //     const calculatedValue = oewsNationalM2022Data.TotalEmp * product;
  //     setOverallValue(
  //       calculatedValue.toLocaleString("en-US", {
  //         minimumFractionDigits: 0,
  //         maximumFractionDigits: 0,
  //       })
  //     );
  //   }
  // }, [oewsNationalM2022Data, product]);

  // const getEstimate = (estimateCode) => {
  //   if (!orsData) return;

  //   let estimate;
  //   const filteredData = orsData.find((ors) => {
  //     return (
  //       ors.EstimateCode === estimateCode &&
  //       ors.Soc2018Code.replace("-", "") ===
  //       filteredOccupations["Soc2018Code"].replace("-", "")
  //     );
  //   });
  //   estimate = Number(filteredData.Estimate.replace("<", "").replace(">", ""));
  //   if (estimateCode === "00064") {
  //     const extraEst = orsData.find((ors) => {
  //       return (
  //         ors.EstimateCode === "00065" &&
  //         ors.Soc2018Code.replace("-", "") ===
  //         filteredOccupations["Soc2018Code"].replace("-", "")
  //       );
  //     });
  //     if (extraEst) {
  //       estimate += Number(extraEst.Estimate.replace("<", "").replace(">", ""));
  //     }
  //   }
  //   console.log("estimate",estimate)
  //   if (estimate) {
  //     addUniqueEstimate(estimate / 100);
  //   }

  //   if (filteredData) return estimate;
  //   return "-";
  // };

  // useEffect(() => {
  //   setCalEst([]);
  //   fetch("GeneratedOrsDataWithDash.json").then(async (response) => {
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data = await response.json();
  //     const socCode = filteredOccupations["Soc2018Code"]?.replace("-", "");
  //     let formattedData = data.filter((d) => {
  //       return d.Soc2018Code === socCode;
  //     });
  //     setOrsData(formattedData);
  //     return;
  //   });
  // }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        {actualOrsData && actualOrsData.length ? (
          <Grid container spacing={"10px"}>
            <Grid item xs={9}>
              <Box className="bg-white" padding={2} borderRadius={"10px"}>
                <form onSubmit={formik.handleSubmit}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                  >
                    <Typography variant="h6">Filter</Typography>
                    <Stack
                      direction={"row"}
                      gap={1}
                      className="flex flex-wrap ml-1"
                    >
                      <div className="flex gap-1">
                        {isFocused ? (
                          // <div className="AnimatedTextField">
                          <TextField
                            hiddenLabel
                            size="small"
                            variant="filled"
                            placeholder="Search"
                            autoFocus
                            value={searchFilters}
                            className="w-40"
                            onChange={(e) => OnFiltersSearch(e.target.value)}
                            // onBlur={() => setIsFocused(false)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {searchFilters === "" ? (
                                    <SearchIcon />
                                  ) : (
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => setSearchFilters("")}
                                    >
                                      X
                                    </div>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          // </div>
                          <Box>
                            <IconButton
                              className="EyeIconButton"
                              sx={{
                                padding: "12px",
                                backgroundColor: "#F5F5F5 !important",
                              }}
                              onClick={() => setIsFocused(true)}
                            >
                              <SearchIcon />
                            </IconButton>
                          </Box>
                        )}
                        {/* top tabs */}
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          indicatorColor="none"
                          sx={{
                            "& .MuiTabs-flexContainer": {
                              justifyContent: "flex-end",
                              gap: 1,
                            },
                          }}
                        >
                          <Tab
                            label="Summary"
                            {...a11yProps(0)}
                            sx={{
                              color:
                                value === 0
                                  ? "#ffffff !important"
                                  : "#666672 !important",
                              backgroundColor:
                                value === 0 ? "#0089D7" : "#FFFFFF",
                              minHeight: "20px",
                              minWidth: "16px",
                              paddingX: "15px",
                              paddingY: "10px",
                              alignItems: "end",
                              border:
                                value === 0 ? "none" : "1px solid #B8B8BE",
                            }}
                          />
                          <Tab
                            label="Physical"
                            {...a11yProps(1)}
                            sx={{
                              color:
                                value === 1
                                  ? "#ffffff !important"
                                  : "#666672 !important",
                              backgroundColor:
                                value === 1 ? "#0089D7" : "#FFFFFF",
                              minHeight: "20px",
                              minWidth: "16px",
                              paddingX: "15px",
                              paddingY: "10px",
                              alignItems: "end",
                              border:
                                value === 1 ? "none" : "1px solid #B8B8BE",
                            }}
                          />
                          <Tab
                            label="Cognitive"
                            {...a11yProps(2)}
                            sx={{
                              color:
                                value === 2
                                  ? "#ffffff !important"
                                  : "#666672 !important",
                              backgroundColor:
                                value === 2 ? "#0089D7" : "#FFFFFF",
                              minHeight: "20px",
                              minWidth: "16px",
                              paddingX: "15px",
                              paddingY: "10px",
                              alignItems: "end",
                              border:
                                value === 2 ? "none" : "1px solid #B8B8BE",
                            }}
                          />
                          <Tab
                            label="Educational"
                            {...a11yProps(3)}
                            sx={{
                              color:
                                value === 3
                                  ? "#ffffff !important"
                                  : "#666672 !important",
                              backgroundColor:
                                value === 3 ? "#0089D7" : "#FFFFFF",
                              minHeight: "20px",
                              minWidth: "16px",
                              paddingX: "15px",
                              paddingY: "10px",
                              alignItems: "end",
                              border:
                                value === 3 ? "none" : "1px solid #B8B8BE",
                            }}
                          />
                          <Tab
                            label="Environmental"
                            {...a11yProps(5)}
                            sx={{
                              color:
                                value === 4
                                  ? "#ffffff !important"
                                  : "#666672 !important",
                              backgroundColor:
                                value === 4 ? "#0089D7" : "#FFFFFF",
                              minHeight: "20px",
                              minWidth: "16px",
                              paddingX: "15px",
                              paddingY: "10px",
                              alignItems: "end",
                              border:
                                value === 4 ? "none" : "1px solid #B8B8BE",
                            }}
                          />
                          <Tab
                            label="All"
                            {...a11yProps(5)}
                            sx={{
                              color:
                                value === 5
                                  ? "#ffffff !important"
                                  : "#666672 !important",
                              backgroundColor:
                                value === 5 ? "#0089D7" : "#FFFFFF",
                              minHeight: "20px",
                              minWidth: "16px",
                              paddingX: "12px",
                              paddingY: "8px",
                              alignItems: "end",
                              border:
                                value === 5 ? "none" : "1px solid #B8B8BE",
                            }}
                          />
                        </Tabs>
                      </div>
                    </Stack>
                  </Stack>

                  <TabPanel value={value} index={0}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        item
                        sx={{
                          "& > .MuiGrid-item:not(:nth-child(3n)) > .MuiStack-root":
                            {
                              borderRight: "1px solid #666672",
                              paddingRight: "15px",
                            },
                        }}
                      >
                        {orsFilterSummaryDemandData.map((data, index) => (
                          <Grid item xs={4} key={index}>
                            {/* checkbox logic */}
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.subtitles.length === 1 &&
                                data.subtitles[0].RenameTitleTo !== "NULL"
                                  ? data.subtitles[0].RenameTitleTo
                                  : data.HypotheticalOrsSummerySection}
                              </Typography>
                              <div>
                                {data.HypotheticalOrsSummerySection !==
                                  "SVP 1 & 2" && data.subtitles.length > 1 ? (
                                  data.subtitles.map((subtitle) => {
                                    return getCheckBox(
                                      getFirstCharOfLastWord(
                                        subtitle.RenameTitleTo
                                      ),
                                      data.HypotheticalOrsSummerySection,
                                      subtitle
                                    );
                                  })
                                ) : (
                                  <CustomSwitch
                                    name="choiceOfSitting1"
                                    id="choiceOfSitting1"
                                    value={!!data.subtitles[0].isChecked}
                                    checked={!!data.subtitles[0].isChecked}
                                    onChange={(e) =>
                                      handleToggleChange(
                                        data.subtitles[0].EstimateCode,
                                        !data.subtitles[0].isChecked
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </Stack>
                          </Grid>
                        ))}
                        {/* <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": { backgroundColor: "#E6F3FB66" },
                          }}
                        >
                          <Typography variant="p" component={"p"}>
                            Crawl
                          </Typography>
                          <FormControl>
                            <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                              <NCheck
                                id="crawl"
                                name="crawl"
                                checked={formik.values.crawl === "N"}
                                onChange={formik.handleChange}
                                value="N"
                                sx={{ paddingY: "2px", paddingX: "5px" }}
                              />
                              <OCheck
                                id="crawl"
                                name="crawl"
                                checked={formik.values.crawl === "O"}
                                onChange={formik.handleChange}
                                value="O"
                                sx={{ paddingY: "2px", paddingX: "5px" }}
                              />
                              <FCheck
                                id="crawl"
                                name="crawl"
                                checked={formik.values.crawl === "F"}
                                onChange={formik.handleChange}
                                value="F"
                                sx={{ paddingY: "2px", paddingX: "5px" }}
                                />
                                </RadioGroup>
                                </FormControl>
                              </Stack> */}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          // value={notes[currentPage - 1] || ""}
                          value={notes || ""}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          // value={comment || ""}
                          // onChange={(e) => {
                          //   const newNotes = [...notes];
                          //   newNotes[currentPage - 1] = e.target.value;
                          //   const localStorageNotes = JSON.parse(
                          //     localStorage.getItem("comment")
                          //   ) || { DOT: "", ORS: "" };

                          //   // Update only the DOT notes in the existing object
                          //   localStorageNotes.ORS = comment;
                          //   localStorage.setItem("comment",JSON.stringify(localStorageNotes))
                          //   setNotes(newNotes);
                          //   setComment(e.target.value)
                          // }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {orsFilterPhysicalDemandData.map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting1"
                                id="choiceOfSitting1"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {orsFilterCognitiveDemandData.map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting5"
                                id="choiceOfSitting5"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {orsFilterEducationalDemandData.map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting5"
                                id="choiceOfSitting5"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {orsFilterEnviromentalDemandData.map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting5"
                                id="choiceOfSitting5"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <Box
                      sx={{
                        height: `${physicalTabHeight}px`,
                        overflow: "auto",
                      }}
                    >
                      <Grid
                        container
                        rowSpacing={"8px"}
                        columnSpacing={"15px"}
                        sx={{
                          "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                            borderRight: "1px solid #666672",
                            paddingRight: "15px",
                          },
                        }}
                      >
                        {[
                          ...orsFilterPhysicalDemandData,
                          ...orsFilterCognitiveDemandData,
                          ...orsFilterEducationalDemandData,
                          ...orsFilterEnviromentalDemandData,
                        ].map((data, index) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                {data.Defination}
                              </Typography>
                              <CustomSwitch
                                name="choiceOfSitting5"
                                id="choiceOfSitting5"
                                value={!!data.isChecked}
                                checked={!!data.isChecked}
                                onChange={(e) =>
                                  handleToggleChange(
                                    data.EstimateCode,
                                    !data.isChecked
                                  )
                                }
                              />
                            </Stack>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"flex-end"}
                    >
                      <Box flexGrow={1}>
                        <textarea
                          ref={textareaRef}
                          value={notes}
                          onChange={(e) => {
                            localStorage.setItem("notes", e.target.value);
                            setNotes(e.target.value);
                          }}
                          style={{
                            padding: "10px 12px 8px",
                            backgroundColor: "rgb(229, 231, 235)",
                            width: "100%",
                          }}
                          placeholder="Notes"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          onClick={() => onSearch({ saveData: true })}
                          startIcon={<SearchIcon />}
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      className="HypotheticalPagination"
                      mt={"10px"}
                      sx={{
                        borderTop: "1px solid #666672",
                        paddingTop: "10px",
                      }}
                    >
                      {PaginatedResults()}
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={onDelete}
                        >
                          {isDeleteloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "red" }}
                            />
                          ) : (
                            "Delete"
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<DuplicateIcon />}
                          onClick={onDuplicate}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25 }}
                            />
                          ) : (
                            "Duplicate"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={onAddnew}
                        >
                          {isAddNewloading ? (
                            <CircularProgress
                              style={{ width: 25, height: 25, color: "white" }}
                            />
                          ) : (
                            "Add New"
                          )}
                        </Button>
                      </Stack>
                    </Stack>
                  </TabPanel>
                </form>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <Box className="bg-white" padding={2} borderRadius={"10px"}>
                <Stack direction={"row"} spacing={2}>
                  <Box flexGrow={1}>
                    <Link to="/HypotheticalAnalyzer">
                      <Button variant="contained" fullWidth color="greyPrimary">
                        DOT
                      </Button>
                    </Link>
                  </Box>
                  <Box flexGrow={1}>
                    <Button variant="contained" fullWidth color="primary">
                      ORS
                    </Button>
                  </Box>
                  <AnalyzerFilters
                    {...{ filtersSorting, setFiltersSorting, OrsFilters: true }}
                  />
                  {/* <AnalyzerSorting
                    filtersSorting={filtersSorting}
                    setFiltersSorting={setFiltersSorting}
                  /> */}
                </Stack>
                <Box sx={{ my: "15px" }}>
                  <Typography variant="h6">Filtered Occupations</Typography>
                </Box>

                <div
                  style={{
                    height: `${occupationsHeight}px`,
                    overflow: "auto",
                    position: "relative",
                  }}
                >
                  {isLoading ? (
                    <Stack
                      sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 4,
                        backgroundColor: "#95959512",
                        borderRadius: "10px",
                      }}
                    >
                      <CircularProgress />
                    </Stack>
                  ) : (
                    !!filteredOccupations &&
                    (!filteredOccupations.length ? (
                      <div>No data</div>
                    ) : (
                      filteredOccupations.map((item, index) => {
                        return (
                          <Box
                            key={item.Id}
                            sx={{
                              padding: "12px",
                              backgroundColor: "#F5F5F5",
                              marginTop: "10px",
                              borderRadius: "10px",
                              width: "100%",
                            }}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                sx={{
                                  overflow: "hidden",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  disableRipple
                                  size="small"
                                  id={`CheckBoxOwner${item.Id}`}
                                  sx={{ padding: 0 }}
                                  checked={item.isChecked}
                                  name={item.Id}
                                  onClick={() => {
                                    handleResultToggle(item);
                                  }}
                                />
                                <InputLabel
                                  htmlFor={`CheckBoxOwner-${index}`}
                                  sx={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                    width: "100%",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    color={"text.primary"}
                                    sx={{
                                      width: "100%",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <b>{item.Occupation}</b>
                                  </Typography>
                                </InputLabel>
                              </Stack>
                              {/* <Typography
                        variant="span"
                        component={"span"}
                        color={"text.grey"}
                        marginLeft={"8px"}
                        fontWeight={"bold"}
                      >
                        {item.subtitle}
                      </Typography> */}
                            </Stack>

                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Typography
                                variant="span"
                                component={"span"}
                                color={"text.grey"}
                                marginTop={"10px"}
                              >
                                Est. Employment
                              </Typography>
                              <Typography
                                variant="span"
                                component={"span"}
                                marginTop={"10px"}
                              >
                                {/* Here Estimate some times has > or < symbols */}
                                {
                                  // have already divided the estimate by 100 in ORS search Hook
                                  Number(item.Estimate)?.toLocaleString(
                                    "en-US",
                                    {
                                      maximumFractionDigits: 0,
                                    }
                                  )
                                }
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Typography
                                variant="span"
                                component={"span"}
                                color={"text.grey"}
                                marginTop={"10px"}
                              >
                                Total Employment
                              </Typography>
                              <Typography
                                variant="span"
                                component={"span"}
                                marginTop={"10px"}
                              >
                                {item.totalEmp?.toLocaleString()}
                              </Typography>
                            </Stack>
                            <Box mt={"10px"}>
                              {/* <Link to="/SOCDOTGroupInfo"> */}
                              <Button
                                variant="outlined"
                                size="small"
                                color="DetailsBtn"
                                fullWidth
                                onClick={() => onViewDetailsClick(item)}
                                endIcon={<RightArrowIcon />}
                              >
                                View Details
                              </Button>
                              {/* </Link> */}
                            </Box>
                          </Box>
                        );
                      })
                    ))
                  )}
                </div>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={onSave}
                    disabled={disabledSaveButton}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <div>loading data</div>
        )}
      </Container>

      {/* Sort By Dialog */}
      <SortByDialog
        openSortByDialog={openSortByDialog}
        handleCloseSortByDialog={handleCloseSortByDialog}
      />
    </>
  );
};

export default HypotheticalToolSummaryORS;
