import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { Fragment } from "react";

const SortByDialog = ({
  openSortByDialog,
  handleCloseSortByDialog,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <>
      <Fragment>
        <Dialog
          open={openSortByDialog}
          onClose={() => handleCloseSortByDialog(selectedOption)}
          fullWidth
          maxWidth={"xs"}
          PaperProps={{
            sx: {
              position: "absolute",
              right: 30,
              top: 150,
              borderRadius: "25px",
              height: "28vh",
              width: "400px", // Adjust the width as needed
              margin: 0,
            },
          }}
        >
          <DialogTitle color={"#000000"} fontSize={"20px"}>
            Sort Results
          </DialogTitle>
          <DialogContent sx={{ padding: 3 }}>
            <Stack gap={1} alignItems={"center"}>
              {/* <TextField
                hiddenLabel
                variant="filled"
                size="small"
                fullWidth
                placeholder="Please enter"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="center">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              /> */}
              <RadioGroup
                // sx={{/}
                value={selectedOption}
                sx={{
                  "& .Mui-checked + .MuiFormControlLabel-label": {
                    color: "primary.main",
                  },
                  width: "100%",
                }}
              >
                <FormControlLabel
                  sx={{ justifyContent: "space-between" }}
                  value="1"
                  control={<Radio disableRipple />}
                  label="Sort by Ascending Order"
                  labelPlacement="start"
                  
                  onClick={() => setSelectedOption(1)}
                />
                <FormControlLabel
                  sx={{ justifyContent: "space-between" }}
                  value="2"
                  control={<Radio disableRipple />}
                  label="Sort by Descending Order"
                  labelPlacement="start"
                  onClick={() => setSelectedOption(2)}
                />
              </RadioGroup>

              <Stack
                direction={"row"}
                spacing={1}
                sx={{
                  width: "95%",
                  justifyContent: "flex-end",
                  marginLeft: "10px",
                }}
              ></Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Fragment>
    </>
  );
};

export default SortByDialog;
