import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M9.744 14.12C9.048 14.12 8.464 14.016 7.992 13.808C7.52 13.592 7.156 13.316 6.9 12.98C6.652 12.636 6.52 12.272 6.504 11.888C6.504 11.824 6.528 11.768 6.576 11.72C6.632 11.664 6.696 11.636 6.768 11.636H7.38C7.476 11.636 7.548 11.66 7.596 11.708C7.644 11.756 7.676 11.812 7.692 11.876C7.732 12.068 7.828 12.26 7.98 12.452C8.14 12.636 8.36 12.792 8.64 12.92C8.928 13.04 9.296 13.1 9.744 13.1C10.432 13.1 10.936 12.98 11.256 12.74C11.584 12.492 11.748 12.164 11.748 11.756C11.748 11.476 11.66 11.252 11.484 11.084C11.316 10.908 11.056 10.752 10.704 10.616C10.352 10.48 9.896 10.332 9.336 10.172C8.752 10.004 8.268 9.82 7.884 9.62C7.5 9.42 7.212 9.172 7.02 8.876C6.836 8.58 6.744 8.204 6.744 7.748C6.744 7.316 6.86 6.932 7.092 6.596C7.324 6.252 7.656 5.98 8.088 5.78C8.528 5.58 9.06 5.48 9.684 5.48C10.18 5.48 10.612 5.548 10.98 5.684C11.356 5.812 11.668 5.988 11.916 6.212C12.164 6.428 12.352 6.664 12.48 6.92C12.608 7.168 12.676 7.412 12.684 7.652C12.684 7.708 12.664 7.764 12.624 7.82C12.584 7.868 12.52 7.892 12.432 7.892H11.796C11.74 7.892 11.68 7.876 11.616 7.844C11.56 7.812 11.516 7.752 11.484 7.664C11.436 7.336 11.252 7.06 10.932 6.836C10.612 6.612 10.196 6.5 9.684 6.5C9.164 6.5 8.74 6.6 8.412 6.8C8.092 7 7.932 7.312 7.932 7.736C7.932 8.008 8.008 8.236 8.16 8.42C8.312 8.596 8.552 8.752 8.88 8.888C9.208 9.024 9.636 9.168 10.164 9.32C10.804 9.496 11.328 9.684 11.736 9.884C12.144 10.076 12.444 10.32 12.636 10.616C12.836 10.904 12.936 11.276 12.936 11.732C12.936 12.236 12.804 12.668 12.54 13.028C12.276 13.38 11.904 13.652 11.424 13.844C10.952 14.028 10.392 14.12 9.744 14.12Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M9.744 14.12C9.048 14.12 8.464 14.016 7.992 13.808C7.52 13.592 7.156 13.316 6.9 12.98C6.652 12.636 6.52 12.272 6.504 11.888C6.504 11.824 6.528 11.768 6.576 11.72C6.632 11.664 6.696 11.636 6.768 11.636H7.38C7.476 11.636 7.548 11.66 7.596 11.708C7.644 11.756 7.676 11.812 7.692 11.876C7.732 12.068 7.828 12.26 7.98 12.452C8.14 12.636 8.36 12.792 8.64 12.92C8.928 13.04 9.296 13.1 9.744 13.1C10.432 13.1 10.936 12.98 11.256 12.74C11.584 12.492 11.748 12.164 11.748 11.756C11.748 11.476 11.66 11.252 11.484 11.084C11.316 10.908 11.056 10.752 10.704 10.616C10.352 10.48 9.896 10.332 9.336 10.172C8.752 10.004 8.268 9.82 7.884 9.62C7.5 9.42 7.212 9.172 7.02 8.876C6.836 8.58 6.744 8.204 6.744 7.748C6.744 7.316 6.86 6.932 7.092 6.596C7.324 6.252 7.656 5.98 8.088 5.78C8.528 5.58 9.06 5.48 9.684 5.48C10.18 5.48 10.612 5.548 10.98 5.684C11.356 5.812 11.668 5.988 11.916 6.212C12.164 6.428 12.352 6.664 12.48 6.92C12.608 7.168 12.676 7.412 12.684 7.652C12.684 7.708 12.664 7.764 12.624 7.82C12.584 7.868 12.52 7.892 12.432 7.892H11.796C11.74 7.892 11.68 7.876 11.616 7.844C11.56 7.812 11.516 7.752 11.484 7.664C11.436 7.336 11.252 7.06 10.932 6.836C10.612 6.612 10.196 6.5 9.684 6.5C9.164 6.5 8.74 6.6 8.412 6.8C8.092 7 7.932 7.312 7.932 7.736C7.932 8.008 8.008 8.236 8.16 8.42C8.312 8.596 8.552 8.752 8.88 8.888C9.208 9.024 9.636 9.168 10.164 9.32C10.804 9.496 11.328 9.684 11.736 9.884C12.144 10.076 12.444 10.32 12.636 10.616C12.836 10.904 12.936 11.276 12.936 11.732C12.936 12.236 12.804 12.668 12.54 13.028C12.276 13.38 11.904 13.652 11.424 13.844C10.952 14.028 10.392 14.12 9.744 14.12Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M9.744 14.12C9.048 14.12 8.464 14.016 7.992 13.808C7.52 13.592 7.156 13.316 6.9 12.98C6.652 12.636 6.52 12.272 6.504 11.888C6.504 11.824 6.528 11.768 6.576 11.72C6.632 11.664 6.696 11.636 6.768 11.636H7.38C7.476 11.636 7.548 11.66 7.596 11.708C7.644 11.756 7.676 11.812 7.692 11.876C7.732 12.068 7.828 12.26 7.98 12.452C8.14 12.636 8.36 12.792 8.64 12.92C8.928 13.04 9.296 13.1 9.744 13.1C10.432 13.1 10.936 12.98 11.256 12.74C11.584 12.492 11.748 12.164 11.748 11.756C11.748 11.476 11.66 11.252 11.484 11.084C11.316 10.908 11.056 10.752 10.704 10.616C10.352 10.48 9.896 10.332 9.336 10.172C8.752 10.004 8.268 9.82 7.884 9.62C7.5 9.42 7.212 9.172 7.02 8.876C6.836 8.58 6.744 8.204 6.744 7.748C6.744 7.316 6.86 6.932 7.092 6.596C7.324 6.252 7.656 5.98 8.088 5.78C8.528 5.58 9.06 5.48 9.684 5.48C10.18 5.48 10.612 5.548 10.98 5.684C11.356 5.812 11.668 5.988 11.916 6.212C12.164 6.428 12.352 6.664 12.48 6.92C12.608 7.168 12.676 7.412 12.684 7.652C12.684 7.708 12.664 7.764 12.624 7.82C12.584 7.868 12.52 7.892 12.432 7.892H11.796C11.74 7.892 11.68 7.876 11.616 7.844C11.56 7.812 11.516 7.752 11.484 7.664C11.436 7.336 11.252 7.06 10.932 6.836C10.612 6.612 10.196 6.5 9.684 6.5C9.164 6.5 8.74 6.6 8.412 6.8C8.092 7 7.932 7.312 7.932 7.736C7.932 8.008 8.008 8.236 8.16 8.42C8.312 8.596 8.552 8.752 8.88 8.888C9.208 9.024 9.636 9.168 10.164 9.32C10.804 9.496 11.328 9.684 11.736 9.884C12.144 10.076 12.444 10.32 12.636 10.616C12.836 10.904 12.936 11.276 12.936 11.732C12.936 12.236 12.804 12.668 12.54 13.028C12.276 13.38 11.904 13.652 11.424 13.844C10.952 14.028 10.392 14.12 9.744 14.12Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const SCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default SCheck;
