import {
  Box,
  Container,
  Stack,
  TextField,
  Grid,
  Button,
  IconButton,
  Typography,
  SvgIcon,
  Tab,
  Tabs,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  ImageList,
  ImageListItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  ToggleButton,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as Dropdown } from "../../assets/images/icons/dropdown.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as EyeIconOff } from "../../assets/images/icons/eye-off-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/images/icons/description-icon.svg";
import { ReactComponent as RequirementsIcon } from "../../assets/images/icons/requirements-icon.svg";
import { ReactComponent as CodeIcon } from "../../assets/images/icons/code-lg-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross-icon.svg";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/right-arrow-icon-Large.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow-icon.svg";
import { Fragment, useEffect, useState } from "react";
import { NCheck, OCheck, FCheck } from "../../Common/CustomCheckbox";
import { Link, useNavigate } from "react-router-dom";
import CustomSwitch from "../../Common/Switch/CustomSwitch";
import TransferableSkillFilterModel from "./TransferableSkillFilterModel";
import { getAllDotfilters } from "../../services/getDOTFilters";
import { Requirements } from "../HypotheticalAnalyzer/helperComponents/requirements";
import { CodeTab } from "../HypotheticalAnalyzer/helperComponents/codeTab";
import { searchDOTfilters } from "../../services/postDOTSearchFilters";
import { handleGetRequest } from "../../services/GetTemplate";
import { formatString } from "../HypotheticalAnalyzer/utils";

function createData(Code, Title, SVP, Physical, MonEmp) {
  return { Code, Title, SVP, Physical, MonEmp };
}
const rows = [
  createData("313.261-026", "Cook Specialty", 5, "M", "11,000"),
  createData("313.261-027", "Cook Specialty", 5, "M", "11,000"),
  createData("313.261-028", "Cook Specialty", 5, "M", "11,000"),
  createData("313.261-029", "Cook Specialty", 5, "M", "11,000"),
  createData("313.261-030", "Cook Specialty", 5, "M", "11,000"),
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TransferableSkill = () => {
  let dbName = "veToolkitDB";
  let dbCurrentVersion;
  const workHistoryData = JSON.parse(localStorage.getItem("workHistoryData"));
  const [occupationsHeight, setOccupationsHeight] = useState();
  const [physicalTabHeight, setPhysicalTabHeight] = useState();

  const [open, setOpen] = useState(false);
  const [tableDatabyDotCode, setTableDatabyDotCode] = useState();
  const [DOTTitleViewHeight, setDOTTitleViewHeight] = useState();
  const [transferableSkillTable, setTransferableSkillTable] = useState();
  const [DOTTitleViewDisplay, setDOTTitleViewDisplay] = useState(false);
  const [search, setSearch] = useState(false);
  // TODO: have to remove this key
  const [allDotData, setAllDotData] = useState(false);
  const [dotFiltersData, setDotFiltersData] = useState(false);
  const [selectedDotData, setSelectedDotData] = useState(null);
  const [selectedWorkHistory, setSelectedWorkHistory] = useState([]);
  const [checkedState, setCheckedState] = useState({
    MPSMS: false,
    "2018SocCode": false,
    WorkFields: false,
    GoeCode: false,
  });
  const [filteredData, setFilteredData] = useState([]);
  // State to track selected state of each item
  const [selectedItems, setSelectedItems] = useState({});

  const [socCodeData, setSocCodeData] = useState();
  const [goeCodeData, setGoeCodeData] = useState();
  const [oapCodeData, setoapCodeData] = useState();
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState();
  const [partialMatchResultsState, setPartialMatchResults] = useState();

  const [physicalDemandData, setPhysicalDemandData] = useState([]);
  const [environmentalDemandData, setEnvironmentalDemandData] = useState([]);
  const [cognitiveDemandData, setCognitiveDemandData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [notes, setNotes] = useState("");
  const [cipDefinations, setCipDefinations] = useState([]);
  const [cnsusDefinations, setCnsusDefinations] = useState([]);
  const [goeDefinations, setGoeDefinations] = useState([]);
  const [oapDefinations, setOapDefinations] = useState([]);
  const [selectValue, setSelectValue] = useState(0);
  // const [selectValue, setSelectValue] = useState('');
  const [oewsData, setOewsData] = useState([]);
  const [workHistory, setWorkHistory] = useState([]);
  const [loader, setLoader] = useState(false);
  const [openComponent, setOpenComponent] = useState(true);
  const [click, setClick] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetch("OewsNationalM2022.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOewsData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      !workHistory ||
      !workHistory.length ||
      !allDotData ||
      !allDotData.length === 0
    )
      return;

    // matching work history to get the full Dot master table
    const selectedFilteredDot = matchDotCodes(workHistory, allDotData);
    const columns = [
      "Title",
      "DotCodeFormated",
      "WorkFields",
      "GoeCode",
      "MPSMS",
      "Ged",
      "SocCode",
    ];
    if (selectedFilteredDot?.length) {
      console.log("selectedFilteredDot =>", selectedFilteredDot);
      const selectedFilteredDotData = selectedFilteredDot.map(
        (selectedDot, index) => {
          const selectedDotData = columns.reduce((acc, key) => {
            if (
              index > 0 &&
              selectedFilteredDot[index - 1][key] === selectedDot[key]
            ) {
              acc[key] = "";
            } else {
              acc[key] = selectedDot[key];
            }
            return acc;
          }, {});
          return selectedDotData;
        }
      );
      // setSelectedDotData(selectedFilteredDotData);
      console.log("selectedFilteredDotData", selectedFilteredDotData);
      setSelectedWorkHistory(
        selectedFilteredDotData ? selectedFilteredDotData : []
      );
    }
  }, [workHistory, allDotData]);

  useEffect(() => {
    const getWorkHistoryData = async () => {
      const profileId = Number(localStorage.getItem("profileId"));
      const response = await handleGetRequest(
        `work-history?&profileId=${profileId}`
      );
      const apiResp = response?.data;
      if (!apiResp || !apiResp.length) setWorkHistory([]);
      setWorkHistory(response?.data);
    };
    getWorkHistoryData();
  }, []);
  useEffect(() => {
    fetch("CipDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCipDefinations(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("CnsusDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCnsusDefinations(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("GoeDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setGoeDefinations(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("OapDefinations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOapDefinations(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    const PhysicalDemandsData = JSON.parse(
      localStorage.getItem("PhysicalDemands")
    );
    if (!PhysicalDemandsData) {
      fetch("DotPhysicalDemands.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPhysicalDemandData(data);
        return;
      });
    } else {
      setPhysicalDemandData(PhysicalDemandsData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const EnvironmentalDemandsData = JSON.parse(
      localStorage.getItem("EnvironmentalDemands")
    );
    if (!EnvironmentalDemandsData) {
      fetch("EnvironmentalDemands.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEnvironmentalDemandData(data);
        return;
      });
    } else {
      setEnvironmentalDemandData(EnvironmentalDemandsData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));
    if (!AptitudesData)
      fetch("aptitudes.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCognitiveDemandData(data);
        return;
      });
    else {
      setCognitiveDemandData(AptitudesData);
    }
  }, []); // eslint-disable-line

  const onSearchNaice = (item) => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        let result = getData.result;
        if (item) {
          result = getData.result.find(
            (row) => row.OccCode === item["2018SocCode"]
          );
        }
        setOewsNationalM2022Data(result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedState({
      ...checkedState,
      [name]: checked,
    });
  };

  const redirectTo = (link) => {
    localStorage.setItem("row", JSON.stringify(selectedDotData));
    navigate(link, { state: selectedDotData });
  };

  const getFilteredItems = (data, filtersValues) => {
    const selectedOptions = Object.keys(checkedState).filter(
      (key) => checkedState[key]
    );

    if (selectedOptions.length === 0) return data;

    return data.filter((item) => {
      return selectedOptions.every((option) => item[option]);
    });
  };

  const handleSearchDotCode = (dotcodeFind) => {
    // Open the database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");
      // console.log("store: ", store.indexNames);

      const index = store.index("DotCode");
      // console.log("index", index.DotCode);

      let partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          var dotCodeMatch = String(cursor.value.DotCode);

          // Check if the dot_code contains the substring 'part_of_dot_code'
          if (dotCodeMatch.includes(dotcodeFind)) {
            partialMatchResults.push(cursor.value);
          }
          cursor.continue();
        } else {
          // console.log("No more entries!");
          // console.log("Matching records: on dotcode", partialMatchResults);
          // setResultRows([]);
          setTableDatabyDotCode(partialMatchResults[0]);
          onSearchNaice(partialMatchResults[0]);
          setPartialMatchResults(partialMatchResults);
          matchedRecordsCodes(partialMatchResults);
          partialMatchResults = [];
        }
      };
    };
  };

  const matchedRecordsCodes = (partialMatchResults) => {
    if (!partialMatchResults || !partialMatchResults.length) return;

    const [socCode, goeCode, oapCode] = [
      partialMatchResults[0].SocCode,
      partialMatchResults[0].GoeCode,
      partialMatchResults[0].OapCode,
    ];

    let socCodeResult = [];
    let goeCodeResult = [];
    let oapCodeResult = [];

    const handleRequestSuccess =
      (storeName, matchCode, resultArray, setResultData) => (event) => {
        const db = event.target.result;
        const transaction = db.transaction([storeName], "readonly");
        const store = transaction.objectStore(storeName);

        store.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const codeMatch = String(cursor.value.Code);
            if (codeMatch == matchCode) {
              const output = {
                type: `${storeName.split("Code")[0].toUpperCase()}-${
                  cursor.value.Type
                }`,
                code: codeMatch,
                description: cursor.value.Text,
              };
              resultArray.push(output);
            }
            cursor.continue();
          } else {
            // console.log(`No more entries in ${storeName}!`);
            // console.log(`${storeName} records:`, resultArray);
            setResultData(resultArray);
          }
        };
      };

    const openDatabase = (storeName, matchCode, resultArray, setResultData) => {
      const request = window.indexedDB.open(dbName, dbCurrentVersion);
      request.onerror = (event) => {
        console.error("Database error: " + event.target.errorCode);
      };
      request.onsuccess = handleRequestSuccess(
        storeName,
        matchCode,
        resultArray,
        setResultData
      );
    };

    openDatabase("OesCodeDefination", socCode, socCodeResult, setSocCodeData);
    openDatabase("OesCodeDefination", goeCode, goeCodeResult, setGoeCodeData);
    openDatabase("OapCodeDefinations", oapCode, oapCodeResult, setoapCodeData);
  };

  const getDotFiltersCounts = () => {
    if (!dotFiltersData || !dotFiltersData.count) return [];

    return dotFiltersData.dotFilters.map((_, index) => index + 1);
  };

  const getFilters = () => {
    if (!dotFiltersData || !dotFiltersData.count || !selectValue) return {};

    const data = dotFiltersData.dotFilters
      ? dotFiltersData.dotFilters[selectValue - 1]
      : {};
    return data;
  };

  useEffect(() => {
    const filters = getFilters();
    if (filters?.filters) {
      setAppliedFilters(filters.filters);
      setNotes(filters.notes);
    } else {
      setAppliedFilters({});
      setNotes("");
    }
  }, [selectValue]);

  useEffect(() => {
    const asyncFunc = async () => {
      const profileId = Number(localStorage.getItem("profileId"));
      const response = await getAllDotfilters(profileId);
      if (response?.data) {
        const { count } = response?.data;
        const filters = response?.data.orsFilters.map((value) => {
          return {
            filters: value.filters.DotFilters,
            results: value.filters.DotResults,
            id: value.id,
            notes: value.filters.notes,
          };
        });
        setDotFiltersData({ count, dotFilters: filters });
      }
    };
    asyncFunc();
  }, []);

  const transformSubmissionData = (
    data,
    physicalData,
    aptitudesData,
    environmentalData
  ) => {
    const newData = {};
    if (data?.Strength) {
      newData.Strength = data.Strength;
    }
    physicalData?.forEach((item, index) => {
      newData[`Phys${index + 1}`] = data[item.Name] || "";
    });

    aptitudesData?.forEach((item) => {
      newData[`Apt${item.ShortCharacter.charAt(0).toUpperCase()}`] =
        data[item.Description] || "";
    });

    environmentalData?.forEach((item, index) => {
      newData[`Env${index + 1}`] = data[item.Description] || "";
    });

    function isNotEmpty(value) {
      if (typeof value === "string") {
        return value.trim().length > 0; // Check for non-empty string after trimming spaces
      } else if (Array.isArray(value)) {
        return value.length > 0; // Check for non-empty array
      }
      return true; // For other types, you can decide the default behavior
    }

    const updatedData = {};
    Object.entries(newData).forEach(([key, value]) => {
      if (isNotEmpty(value)) {
        updatedData[key] = value;
      }
    });

    return updatedData;
  };

  const matchDotCodes = (array1, array2) => {
    return array2.filter((item2) => {
      return array1.some(
        (item1) =>
          item1.dotcode === item2.DotCodeFormated?.toString() ||
          item1.dotcode === item2.DotCode?.toString()
      );
    });
  };

  const handleSearchLocally = ({ data, locallyAppliedFilters }) => {
    let transformedData;
    if (
      !locallyAppliedFilters ||
      !Object.keys(locallyAppliedFilters).length === 0
    ) {
      locallyAppliedFilters = appliedFilters;
    }

    transformedData = transformSubmissionData(
      locallyAppliedFilters,
      physicalDemandData,
      cognitiveDemandData,
      environmentalDemandData
    );

    const output = data.filter((item) => {
      return Object.keys(transformedData).every((key) => {
        return transformedData[key].includes(item[key]);
      });
    });
    return output;
  };

  const getFiltersValuesToCompare = (selectedFilteredDot) => {
    const selectedOptions = Object.keys(checkedState).filter(
      (key) => checkedState[key]
    );
    if (selectedOptions.length === 0) return {};
    const filteredValue = {};
    selectedFilteredDot?.forEach((work) => {
      selectedOptions.forEach((option) => {
        if (filteredValue[option]) {
          filteredValue[option].push(work[option]);
        } else {
          filteredValue[option] = [work[option]];
        }
      });
    });
    return filteredValue;
  };

  const handleSearch = async ({ locallyAppliedFilters }) => {
    setOpen(false);
    setLoader(true);
    // Create a lookup map from oewsData for quick access
    let oewsDataMap = oewsData.reduce((map, item) => {
      map[item.OccCode] = item.TotalEmp;
      return map;
    }, {});
    const isEmpty = (obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }
      return true;
    };

    setTimeout(() => {
      if (!workHistory || !workHistory.length) {
        setLoader(false);
        setFilteredData([]);
        setSearch(true);
        return;
      }

      // matching work history to get the full Dot master table
      const selectedFilteredDot = matchDotCodes(workHistory, allDotData);
      // getting the values of selected filters from the work history
      const filtersValues = getFiltersValuesToCompare(selectedFilteredDot);
      const firstTenRecords = allDotData.slice(0, 100);
      if (!locallyAppliedFilters && isEmpty(filtersValues)) {
        // Merge totalEmp into updatedData
        let updatedfirstTenRecords = firstTenRecords.map((item) => {
          let totalEmp = oewsDataMap[item["2018SocCode"]];
          return {
            ...item,
            totalEmp: totalEmp !== undefined ? totalEmp : null,
          };
        });
        setLoader(false);
        console.log("updatedfirstTenRecords =>", updatedfirstTenRecords);
        setFilteredData(updatedfirstTenRecords);
        setSearch(true);
        return;
      }

      // applying the filters to get the dot values
      const result = allDotData.filter((item) =>
        Object.keys(filtersValues).every((key) =>
          filtersValues[key].includes(item[key])
        )
      );
      let updatedData = handleSearchLocally({
        data: result,
        locallyAppliedFilters,
      });
      // Create a lookup map from oewsData for quick access
      // let oewsDataMap = oewsData.reduce((map, item) => {
      //   map[item.OccCode] = item.TotalEmp;
      //   return map;
      // }, {});

      // Merge totalEmp into updatedData
      updatedData = updatedData.map((item) => {
        let totalEmp = oewsDataMap[item["2018SocCode"]];
        return { ...item, totalEmp: totalEmp !== undefined ? totalEmp : null };
      });
      setLoader(false);
      setFilteredData(updatedData);
      setSearch(true);
    }, [100]);
  };

  // Define what happens when an item is selected
  const handleSelect = (itemId) => {
    setDOTTitleViewDisplay(true);
    const selectedData = filteredData.find((data) => data.id === itemId);
    setSelectedDotData(selectedData);
    //    setWorkHistoryHeight(window.innerHeight-500)
  };

  const handleDeselect = (itemId) => {
    // Additional deselect logic here
    //    setWorkHistoryHeight(window.innerHeight - 225);
    setDOTTitleViewDisplay(false);
    setSelectedDotData(null);
  };

  // Function to toggle select/deselect and call respective functions
  const handleToggleSelect = (itemId) => {
    setTimeout(() => {
      handleSearchDotCode(itemId);
      setSelectedItems((prevSelectedItems) => {
        const isSelected = !prevSelectedItems[itemId];
        // Call handleSelect or handleDeselect based on the new state
        if (isSelected) {
          handleSelect(itemId);
          // Deselect all other items
          return { [itemId]: true };
        } else {
          handleDeselect(itemId);
          return { [itemId]: false };
        }
      });
    }, [100]);
  };
  const isAnyItemSelected = Object.values(selectedItems).some((value) => value);
  const [value, setValue] = useState(0);

  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;
      // console.log("Current IndexedDB version:", currentVersion);

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setOccupationsHeight(innerHeight - 173);
      setPhysicalTabHeight(innerHeight - 390);
      setTransferableSkillTable(innerHeight - 98);
      setDOTTitleViewHeight(innerHeight - 500);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");
      const objectStore = transaction.objectStore("DOTMasterTable");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setAllDotData(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("DOTMasterTable", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getGoeTitle = (goeCode) => {
    const formattedGoeCode = "0" + goeCode;
    const filteredGoe = goeDefinations?.find((goe) => {
      return goe.SocCode === formattedGoeCode;
    });
    return filteredGoe ? filteredGoe.GoeTitle : "-";
  };

  useEffect(() => {
    const savedData = localStorage.getItem("transferableSkillData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFilteredData(parsedData.filteredData || []);
      setSelectedItems(parsedData.selectedItems || {});
      setSelectedDotData(parsedData.selectedDotData || null);
      setDOTTitleViewDisplay(parsedData.DOTTitleViewDisplay || false);
      setValue(parsedData.value || 0);
      setCheckedState(parsedData.checkedState || {});
      setSelectValue(
        parsedData.selectValue !== undefined ? parsedData.selectValue : "0"
      );
      setSearch(true);
      // Clear the saved data after loading

      localStorage.removeItem("transferableSkillData");
    }
  }, []);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={"10px"}>
          <Grid item xs={6} overflow={"auto"} maxHeight={"87vh"}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Box>
                <Typography variant="h6" component={"h6"}>
                  Transferable Skill Matching Criteria
                </Typography>
              </Box>
              <Box>
                <FormGroup row>
                  <FormControlLabel
                    sx={{ flexGrow: 1, marginLeft: 0 }}
                    control={
                      <Checkbox
                        disableRipple
                        checked={checkedState.WorkFields}
                        name="WorkFields"
                        sx={{ padding: 0, paddingRight: "5px" }}
                        onClick={handleCheckboxChange}
                      />
                    }
                    label="Work Fields"
                  />
                  <FormControlLabel
                    sx={{ flexGrow: 1, marginLeft: 0 }}
                    control={
                      <Checkbox
                        disableRipple
                        checked={checkedState.MPSMS}
                        name="MPSMS"
                        sx={{ padding: 0, paddingRight: "5px" }}
                        onClick={handleCheckboxChange}
                      />
                    }
                    label="MPSMS"
                  />
                  <FormControlLabel
                    sx={{ flexGrow: 1, marginLeft: 0 }}
                    control={
                      <Checkbox
                        checked={checkedState["2018SocCode"]}
                        disableRipple
                        name="2018SocCode"
                        sx={{ padding: 0, paddingRight: "5px" }}
                        onClick={handleCheckboxChange}
                      />
                    }
                    label="SOC"
                  />
                  <FormControlLabel
                    sx={{ flexGrow: 1, marginLeft: 0 }}
                    control={
                      <Checkbox
                        disableRipple
                        checked={checkedState.GoeCode}
                        name="GoeCode"
                        sx={{ padding: 0, paddingRight: "5px" }}
                        onClick={handleCheckboxChange}
                      />
                    }
                    label="GOE"
                  />
                </FormGroup>
              </Box>
              <Box mt={2}>
                <Typography
                  variant="p"
                  component="p"
                  color={"text.grey"}
                  paddingLeft={"4px"}
                  paddingBottom={"5px"}
                >
                  Apply filter from Hypothetical
                </Typography>
                <Select
                  size="small"
                  variant="filled"
                  hiddenLabel
                  fullWidth
                  value={selectValue}
                  onChange={(e) => {
                    setSelectValue(e.target.value);
                    setClick(false);
                  }}
                >
                  <MenuItem value={0}>Select DOT Filters</MenuItem>
                  <MenuItem value="none">None</MenuItem>
                  {getDotFiltersCounts().map((id, index) => {
                    if (id !== undefined && id !== null) {
                      return (
                        <MenuItem key={id} value={id}>
                          #{index + 1} (DOT)
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
                </Select>
              </Box>

              <Stack direction={"row"} spacing={2} mt={2}>
                <Box flexGrow={1}>
                  <Button
                    disabled={
                      click && !Object.values(checkedState).includes(true)
                    }
                    variant="outlined"
                    color="DetailsBtn"
                    fullWidth
                    endIcon={<RightArrowIcon />}
                    onClick={handleClickOpen}
                  >
                    Apply Custom DOT Filter
                  </Button>
                </Box>
                <Box flexGrow={1}>
                  <Button
                    disabled={
                      click && !Object.values(checkedState).includes(true)
                    }
                    variant="contained"
                    fullWidth
                    color="primary"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Box>
              </Stack>
            </Stack>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
              mt={"10px"}
            >
              <Stack padding={1}>
                <Box
                  sx={{
                    borderBottom: "1px solid #666672",
                    paddingBottom: "3px",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenComponent(!openComponent)}
                >
                  <Typography
                    variant="h6"
                    component={"h6"}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Transferable Components{" "}
                    <Dropdown
                      width={"18px"}
                      height={"18px"}
                      style={{
                        transform: openComponent
                          ? "rotate(0)"
                          : "rotate(-90deg)",
                      }}
                    />
                  </Typography>
                </Box>

                {openComponent && (
                  <ImageList variant="masonry" cols={2} gap={8} sx={{ mt: 1 }}>
                    {selectedWorkHistory.map((item, index) => {
                      return item?.WorkFields ===
                        selectedDotData?.WorkFields ? (
                        <ImageListItem key={index}>
                          <Stack
                            padding={"10px"}
                            borderRadius={"10px"}
                            backgroundColor={"#F5F5F5"}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{
                                borderBottom: "1px solid #666672",
                                paddingBottom: "3px",
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                Work Field
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Title
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.Title}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.DotCodeFormated}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                Workfield Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.WorkFields}
                              </Typography>
                            </Stack>
                          </Stack>
                        </ImageListItem>
                      ) : (
                        <></>
                      );
                    })}
                    {selectedWorkHistory.map((item, index) => {
                      return item?.GoeCode === selectedDotData?.GoeCode ? (
                        <ImageListItem key={index}>
                          <Stack
                            padding={"10px"}
                            borderRadius={"10px"}
                            backgroundColor={"#F5F5F5"}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{
                                borderBottom: "1px solid #666672",
                                paddingBottom: "3px",
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                GOE
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Title
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.Title}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.DotCodeFormated}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                GOE Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.GoeCode}
                              </Typography>
                            </Stack>
                          </Stack>
                        </ImageListItem>
                      ) : (
                        <></>
                      );
                    })}
                    {selectedWorkHistory.map((item, index) => {
                      return item?.MPSMS === selectedDotData?.MPSMS ? (
                        <ImageListItem key={index}>
                          <Stack
                            padding={"10px"}
                            borderRadius={"10px"}
                            backgroundColor={"#F5F5F5"}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{
                                borderBottom: "1px solid #666672",
                                paddingBottom: "3px",
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                MPSMS
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Title
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.Title}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.DotCodeFormated}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                MPSMS Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.MPSMS}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                GED
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.Ged}
                              </Typography>
                            </Stack>
                          </Stack>
                        </ImageListItem>
                      ) : (
                        <></>
                      );
                    })}
                    {selectedWorkHistory.map((item, index) => {
                      return item["2018SocCode"] ? (
                        <ImageListItem key={index}>
                          <Stack
                            padding={"10px"}
                            borderRadius={"10px"}
                            backgroundColor={"#F5F5F5"}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{
                                borderBottom: "1px solid #666672",
                                paddingBottom: "3px",
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                SOC
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Title
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.Title}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.DotCodeFormated}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                SOC Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {oewsNationalM2022Data.OccCode}
                              </Typography>
                            </Stack>
                          </Stack>
                        </ImageListItem>
                      ) : (
                        <></>
                      );
                    })}
                    {selectedWorkHistory.map((item, index) => {
                      return item?.CipCode === selectedDotData?.CipCode &&
                        selectedDotData?.CipCode !== undefined ? (
                        <ImageListItem key={index}>
                          <Stack
                            padding={"10px"}
                            borderRadius={"10px"}
                            backgroundColor={"#F5F5F5"}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{
                                borderBottom: "1px solid #666672",
                                paddingBottom: "3px",
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                CIP
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Title
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.Title}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.DotCodeFormated}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                CIP Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.CipCode}
                              </Typography>
                            </Stack>
                          </Stack>
                        </ImageListItem>
                      ) : (
                        <></>
                      );
                    })}
                    {selectedWorkHistory.map((item, index) => {
                      return item?.SocCode === selectedDotData?.SocCode ? (
                        <ImageListItem key={index}>
                          <Stack
                            padding={"10px"}
                            borderRadius={"10px"}
                            backgroundColor={"#F5F5F5"}
                          >
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{
                                borderBottom: "1px solid #666672",
                                paddingBottom: "3px",
                              }}
                            >
                              <Typography variant="p" component={"p"}>
                                SOC Code
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Title
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.Title}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                DOT Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {item?.DotCodeFormated}
                              </Typography>
                            </Stack>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              sx={{ paddingTop: "10px" }}
                            >
                              <Typography variant="p" component={"p"}>
                                SOC Code
                              </Typography>
                              <Typography variant="p" component={"p"}>
                                {oewsNationalM2022Data?.OccCode}
                              </Typography>
                            </Stack>
                          </Stack>
                        </ImageListItem>
                      ) : (
                        <></>
                      );
                    })}
                  </ImageList>
                )}
              </Stack>
            </Stack>

            {DOTTitleViewDisplay && selectedDotData && (
              <Box
                className="bg-white"
                padding={1}
                borderRadius={"10px"}
                marginTop={"10px"}
                position={"relative"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6">DOT Title View</Typography>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="none"
                      sx={{
                        "& .MuiTabs-flexContainer": {
                          justifyContent: "flex-center",
                          alignItems: "center",
                          gap: 2,
                        },
                      }}
                    >
                      <Tab
                        label="Description"
                        icon={<DescriptionIcon />}
                        iconPosition="start"
                        {...a11yProps(0)}
                        sx={{
                          color:
                            value === 0
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 0 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <Tab
                        label="Requirements"
                        {...a11yProps(1)}
                        icon={<RequirementsIcon />}
                        iconPosition="start"
                        sx={{
                          color:
                            value === 1
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 1 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <Tab
                        label="Codes"
                        icon={<CodeIcon />}
                        iconPosition="start"
                        {...a11yProps(2)}
                        sx={{
                          color:
                            value === 2
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 2 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <a
                        href={`/dotDetail?dotCode=${selectedDotData?.DotCodeFormated}`}
                        target="_blank"
                      >
                        <button
                          style={{
                            backgroundColor: "#F5F5F5",
                            minHeight: "20px",
                            padding: "10px 8px",
                            display: "flex",
                            alignItems: "end",
                            borderRadius: 10,
                            color: "#666672",
                            cursor: "alias",
                          }}
                        >
                          Link
                        </button>
                      </a>
                    </Tabs>
                  </Box>
                </Stack>

                <TabPanel value={value} index={0}>
                  <Box
                    sx={{
                      position: "relative",
                      maxHeight: `auto`,
                      overflowY: "scroll",
                      paddingBottom: "35px",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      my={1}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                        >
                          {selectedDotData.Title}
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"text.grey"}
                          sx={{ display: "flex" }}
                        >
                          <CodeIcon />
                          Code
                        </Typography>
                        <Typography
                          variant="h6"
                          component={"h6"}
                          fontSize={"0.875rem"}
                        >
                          {selectedDotData.DotCodeFormated}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Typography
                      variant="p"
                      component={"p"}
                      color={"#484957"}
                      paddingBottom={"10px"}
                    >
                      {formatString(
                        selectedDotData.DescriptionCombined ||
                          selectedDotData.description
                      )}
                    </Typography>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Stack direction={"row"} my={1} gap={1} alignItems={"center"}>
                    <Box flexGrow={1}>
                      <Typography variant="p" component={"p"} color={"primary"}>
                        {selectedDotData.Title}
                      </Typography>
                    </Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography
                        variant="p"
                        component={"p"}
                        color={"text.grey"}
                        sx={{ display: "flex" }}
                      >
                        <CodeIcon />
                        Code
                      </Typography>
                      <Typography
                        variant="h6"
                        component={"h6"}
                        fontSize={"0.875rem"}
                      >
                        {selectedDotData.DotCodeFormated}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Requirements
                    {...{
                      tableDatabyDotCode,
                      partialMatchResultsState,
                    }}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Stack direction={"row"} my={1} gap={1} alignItems={"center"}>
                    <Box flexGrow={1}>
                      <Typography variant="p" component={"p"} color={"primary"}>
                        {selectedDotData.Title}
                      </Typography>
                    </Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography
                        variant="p"
                        component={"p"}
                        color={"text.grey"}
                        sx={{ display: "flex" }}
                      >
                        <CodeIcon />
                        Code
                      </Typography>
                      <Typography
                        variant="h6"
                        component={"h6"}
                        fontSize={"0.875rem"}
                      >
                        {selectedDotData.DotCodeFormated}
                      </Typography>
                    </Stack>
                  </Stack>
                  <CodeTab
                    {...{
                      tableDatabyDotCode,
                      oapCodeData,
                      goeCodeData,
                      socCodeData,
                      rowData: selectedDotData,
                      oewsNationalM2022Data,
                    }}
                  />
                </TabPanel>
                <Box bottom={"10px"} right={"10px"} textAlign={"right"}>
                  <Button
                    variant="contained"
                    endIcon={<ArrowIcon />}
                    onClick={() => {
                      // Save relevant data to localStorage
                      localStorage.setItem(
                        "transferableSkillData",
                        JSON.stringify({
                          filteredData,
                          selectedItems,
                          selectedDotData,
                          DOTTitleViewDisplay,
                          value,
                          checkedState,
                          selectValue: selectValue.toString(),
                        })
                      );
                      redirectTo("/SOCGroupORSFilterTransferableSkill");
                    }}
                  >
                    ORS Evaluation
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <TableContainer
              component={Paper}
              sx={{
                paddingBottom: 0,
                paddingTop: 0,
                overflow: "auto",
                height: `${transferableSkillTable}px`,
                boxShadow: "none",
                paddingX: "10px",
                borderRadius: "10px",
              }}
            >
              {loader ? (
                <div
                  style={{
                    position: "relative",
                    height: "100vh",
                    width: "100%",
                  }}
                >
                  <Stack
                    sx={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 4,
                      backgroundColor: "#95959512",
                      borderRadius: "10px",
                    }}
                  >
                    <CircularProgress />
                  </Stack>
                </div>
              ) : (
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Code</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">SVP</TableCell>
                      <TableCell align="left">Physical</TableCell>
                      <TableCell align="left">Mean OEWS Emp</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {search &&
                      filteredData &&
                      filteredData.map((row, index) => {
                        const isMatched = workHistoryData.some(
                          (item) => item.dotcode === row.DotCodeFormated
                        );
                        if (isMatched) return null;
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              "& td, & th": { paddingY: "4px !important" },
                              "&:hover": { backgroundColor: "#E6F3FB66" },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <span className="whitespace-nowrap">
                                {row.DotCodeFormated}
                              </span>
                            </TableCell>
                            <TableCell align="left">{row.Title}</TableCell>
                            <TableCell align="left">{row.SVP}</TableCell>
                            <TableCell align="left">{row.Strength}</TableCell>
                            <TableCell align="left">
                              {row.totalEmp?.toLocaleString("en-US")}
                            </TableCell>
                            <TableCell align="right">
                              <Stack
                                direction={"row"}
                                spacing={1}
                                sx={{ justifyContent: "end" }}
                              >
                                <ToggleButton
                                  size="small"
                                  value="check"
                                  className={`${
                                    selectedItems[row["id"]]
                                      ? "EyeIconOffButton"
                                      : "EyeIconButton"
                                  }`}
                                  sx={{ border: 0 }}
                                  selected={!!selectedItems[row["id"]]} // Use double negation to ensure boolean
                                  onChange={() => handleToggleSelect(row["id"])}
                                  // disabled={
                                  //   isAnyItemSelected &&
                                  //   !selectedItems[row["id"]]
                                  // }
                                >
                                  {selectedItems[row["id"]] ? (
                                    <SvgIcon
                                      component={EyeIconOff}
                                      inheritViewBox
                                    />
                                  ) : (
                                    <SvgIcon
                                      component={EyeIcon}
                                      inheritViewBox
                                    />
                                  )}
                                </ToggleButton>
                                {/* <IconButton
                              size="small"
                              className="DeleteIconButton"
                              sx={{ padding: "7px" }}
                            >
                              <SvgIcon component={DeleteIcon} inheritViewBox />
                            </IconButton> */}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      {open && (
        <TransferableSkillFilterModel
          open={open}
          getFilters={getFilters}
          handleClose={handleClose}
          handleSearch={handleSearch}
          appliedFilters={appliedFilters}
          notes={notes}
        />
      )}
    </>
  );
};

export default TransferableSkill;
