import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M9.504 14.12C8.872 14.12 8.336 14.012 7.896 13.796C7.464 13.572 7.132 13.284 6.9 12.932C6.676 12.58 6.556 12.208 6.54 11.816C6.532 11.744 6.552 11.688 6.6 11.648C6.648 11.608 6.708 11.588 6.78 11.588H7.428C7.5 11.588 7.564 11.604 7.62 11.636C7.676 11.668 7.72 11.736 7.752 11.84C7.832 12.136 7.96 12.38 8.136 12.572C8.32 12.756 8.528 12.892 8.76 12.98C9 13.06 9.248 13.1 9.504 13.1C9.848 13.1 10.16 13.032 10.44 12.896C10.728 12.752 10.956 12.544 11.124 12.272C11.292 11.992 11.376 11.656 11.376 11.264C11.376 10.904 11.292 10.596 11.124 10.34C10.964 10.076 10.74 9.876 10.452 9.74C10.172 9.596 9.856 9.524 9.504 9.524C9.24 9.524 9.024 9.556 8.856 9.62C8.688 9.684 8.544 9.76 8.424 9.848C8.312 9.936 8.208 10.012 8.112 10.076C8.024 10.14 7.924 10.172 7.812 10.172H7.188C7.116 10.172 7.052 10.148 6.996 10.1C6.94 10.044 6.916 9.98 6.924 9.908L7.308 5.9C7.316 5.804 7.344 5.732 7.392 5.684C7.448 5.628 7.52 5.6 7.608 5.6H11.796C11.876 5.6 11.94 5.628 11.988 5.684C12.044 5.732 12.072 5.796 12.072 5.876V6.344C12.072 6.432 12.044 6.5 11.988 6.548C11.94 6.596 11.876 6.62 11.796 6.62H8.34L8.112 8.984C8.224 8.912 8.404 8.828 8.652 8.732C8.9 8.628 9.24 8.576 9.672 8.576C10.064 8.576 10.432 8.636 10.776 8.756C11.128 8.876 11.44 9.052 11.712 9.284C11.984 9.516 12.196 9.8 12.348 10.136C12.5 10.464 12.576 10.844 12.576 11.276C12.576 11.884 12.44 12.4 12.168 12.824C11.904 13.248 11.54 13.572 11.076 13.796C10.62 14.012 10.096 14.12 9.504 14.12Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M9.504 14.12C8.872 14.12 8.336 14.012 7.896 13.796C7.464 13.572 7.132 13.284 6.9 12.932C6.676 12.58 6.556 12.208 6.54 11.816C6.532 11.744 6.552 11.688 6.6 11.648C6.648 11.608 6.708 11.588 6.78 11.588H7.428C7.5 11.588 7.564 11.604 7.62 11.636C7.676 11.668 7.72 11.736 7.752 11.84C7.832 12.136 7.96 12.38 8.136 12.572C8.32 12.756 8.528 12.892 8.76 12.98C9 13.06 9.248 13.1 9.504 13.1C9.848 13.1 10.16 13.032 10.44 12.896C10.728 12.752 10.956 12.544 11.124 12.272C11.292 11.992 11.376 11.656 11.376 11.264C11.376 10.904 11.292 10.596 11.124 10.34C10.964 10.076 10.74 9.876 10.452 9.74C10.172 9.596 9.856 9.524 9.504 9.524C9.24 9.524 9.024 9.556 8.856 9.62C8.688 9.684 8.544 9.76 8.424 9.848C8.312 9.936 8.208 10.012 8.112 10.076C8.024 10.14 7.924 10.172 7.812 10.172H7.188C7.116 10.172 7.052 10.148 6.996 10.1C6.94 10.044 6.916 9.98 6.924 9.908L7.308 5.9C7.316 5.804 7.344 5.732 7.392 5.684C7.448 5.628 7.52 5.6 7.608 5.6H11.796C11.876 5.6 11.94 5.628 11.988 5.684C12.044 5.732 12.072 5.796 12.072 5.876V6.344C12.072 6.432 12.044 6.5 11.988 6.548C11.94 6.596 11.876 6.62 11.796 6.62H8.34L8.112 8.984C8.224 8.912 8.404 8.828 8.652 8.732C8.9 8.628 9.24 8.576 9.672 8.576C10.064 8.576 10.432 8.636 10.776 8.756C11.128 8.876 11.44 9.052 11.712 9.284C11.984 9.516 12.196 9.8 12.348 10.136C12.5 10.464 12.576 10.844 12.576 11.276C12.576 11.884 12.44 12.4 12.168 12.824C11.904 13.248 11.54 13.572 11.076 13.796C10.62 14.012 10.096 14.12 9.504 14.12Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M9.504 14.12C8.872 14.12 8.336 14.012 7.896 13.796C7.464 13.572 7.132 13.284 6.9 12.932C6.676 12.58 6.556 12.208 6.54 11.816C6.532 11.744 6.552 11.688 6.6 11.648C6.648 11.608 6.708 11.588 6.78 11.588H7.428C7.5 11.588 7.564 11.604 7.62 11.636C7.676 11.668 7.72 11.736 7.752 11.84C7.832 12.136 7.96 12.38 8.136 12.572C8.32 12.756 8.528 12.892 8.76 12.98C9 13.06 9.248 13.1 9.504 13.1C9.848 13.1 10.16 13.032 10.44 12.896C10.728 12.752 10.956 12.544 11.124 12.272C11.292 11.992 11.376 11.656 11.376 11.264C11.376 10.904 11.292 10.596 11.124 10.34C10.964 10.076 10.74 9.876 10.452 9.74C10.172 9.596 9.856 9.524 9.504 9.524C9.24 9.524 9.024 9.556 8.856 9.62C8.688 9.684 8.544 9.76 8.424 9.848C8.312 9.936 8.208 10.012 8.112 10.076C8.024 10.14 7.924 10.172 7.812 10.172H7.188C7.116 10.172 7.052 10.148 6.996 10.1C6.94 10.044 6.916 9.98 6.924 9.908L7.308 5.9C7.316 5.804 7.344 5.732 7.392 5.684C7.448 5.628 7.52 5.6 7.608 5.6H11.796C11.876 5.6 11.94 5.628 11.988 5.684C12.044 5.732 12.072 5.796 12.072 5.876V6.344C12.072 6.432 12.044 6.5 11.988 6.548C11.94 6.596 11.876 6.62 11.796 6.62H8.34L8.112 8.984C8.224 8.912 8.404 8.828 8.652 8.732C8.9 8.628 9.24 8.576 9.672 8.576C10.064 8.576 10.432 8.636 10.776 8.756C11.128 8.876 11.44 9.052 11.712 9.284C11.984 9.516 12.196 9.8 12.348 10.136C12.5 10.464 12.576 10.844 12.576 11.276C12.576 11.884 12.44 12.4 12.168 12.824C11.904 13.248 11.54 13.572 11.076 13.796C10.62 14.012 10.096 14.12 9.504 14.12Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const FiveCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default FiveCheck;
