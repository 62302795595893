import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  InputLabel,
  Stack,
  Checkbox,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import VisibilityOff from "../../assets/images/icons/visibilityOff.svg";
import Visibility from "../../assets/images/icons/visibility.svg";
import "./Login.scss";
import logo from "../../assets/images/logos/ve-toolkit-logo.svg";
import LoginImage from "../../assets/images/SideImage/LoginSideImage.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/actions/authAction";
import { handlePutRequest } from "../../services/PutTemplate";
import { handlePostRequest } from "../../services/PostTemplate";
import { useState } from "react";

const Login = () => {
  const [loading, setloading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState("");
  const [isForgotPasswor, setIsForgotPasswor] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [savedPassword, setSavedPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedPasswordData = localStorage.getItem("savedPassword");
  const initialValues = savedPasswordData
    ? JSON.parse(savedPasswordData)
    : {
        email: "",
        password: "",
      };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (data) => {
      setloading(true);
      setloadingIcon("pi pi-spin pi-spinner");

      let response;
      if (savedPassword) {
        localStorage.setItem("savedPassword", JSON.stringify(data));
      }

      if (!isForgotPasswor) {
        response = await dispatch(
          handlePostRequest(data, `auth/signin`, true, true)
        );
      } else {
        const { email } = data;
        response = await dispatch(
          handlePostRequest({ email }, `auth/forget-password`, true, true)
        );
      }
      if (response === undefined) {
        setloading(false);
        return;
      }

      const { token } = response.data;

      localStorage.setItem("password", data.password);
      localStorage.setItem("token", token);

      const password = localStorage.getItem("password");

      if (password) {
        navigate("/");
        window.location.reload();
      }

      setloading(false);
      setloadingIcon("");
     
    },
  });

  const handleEmailChange = (e) => {
    formik.setFieldValue("email", e.target.value.toLowerCase());
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <>
      <form className="login-bg" onSubmit={formik.handleSubmit}>
        <div className="">
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12} md={7}>
              <Box p={3} pb={2} sx={{ padding: { xs: 2, sm: 3 } }}>
                <img src={logo} alt="" />
                <Typography
                  variant="h5"
                  mt={6}
                  sx={{
                    fontSize: "28px",
                  }}
                >
                  <b>
                    Welcome to
                    <Typography
                      variant="span"
                      color={"primary.main"}
                      mb={2}
                      sx={{
                        fontSize: "28px",
                      }}
                    >
                      &nbsp;VE-Toolkit
                    </Typography>
                  </b>
                </Typography>
              </Box>
              <Box
                sx={{
                  paddingX: { xs: 2, md: 5, lg: 15 },
                  paddingY: { xs: 3, lg: 5 },
                }}
              >
                <Box mt={3}>
                  <InputLabel>Email</InputLabel>
                  <TextField
                    name="email"
                    value={formik.values.email}
                    onChange={handleEmailChange}
                    placeholder="Enter your email address"
                    variant="outlined"
                    autoComplete="off"
                    color="primary"
                    sx={{
                      width: "100%",
                    }}
                  />
                  {/* {getFormErrorMessage("email")} */}
                </Box>
                {!isForgotPasswor && (
                  <Box mt={4}>
                    <Box mt={3}>
                      <InputLabel>Password</InputLabel>
                      <TextField
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        placeholder="Enter your password"
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                        type={showPassword ? "text" : "password"} // Add type attribute for password field
                        sx={{
                          width: "100%",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)} // Add onClick event to toggle show/hide password
                                edge="end"
                              >
                                {showPassword ? (
                                  <img src={VisibilityOff} alt="" />
                                ) : (
                                  <img src={Visibility} alt="" />
                                )}{" "}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {/* {getFormErrorMessage("password")} */}
                    </Box>
                  </Box>
                )}
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  alignItems={"center"}
                >
                  {/* {!isForgotPasswor && (
                    <FormControlLabel
                      sx={{ color: "text.secondary" }}
                      control={
                        <Checkbox
                          onClick={() => setSavedPassword(!savedPassword)}
                        />
                      }
                      label="Remember Password "
                    />
                  )} */}
                  <Typography
                    variant="subtitle1"
                    color={"primary.main"}
                    my={2}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsForgotPasswor(!isForgotPasswor);
                    }}
                  >
                    {!isForgotPasswor ? (
                      "Forgot Password?"
                    ) : (
                      <Button variant="contained">Sign in</Button>
                    )}
                  </Typography>
                </Stack>
                <Box my={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: "100%",
                      paddingBottom: "15px",
                      paddingTop: "15px",
                    }}
                    disabled={loading} 
                  >
                    {!isForgotPasswor ? (
                      loading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Sign In"
                      )
                    ) : loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Get Password"
                    )}
                  </Button>
                </Box>
                <Box textAlign={"center"}>
                  <Typography variant="subtitle1" color="initial">
                    Don't have an account?{" "}
                    <Link to="/signup">
                      <Typography variant="span" color={"primary.main"} my={2}>
                        Signup here
                      </Typography>
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={5} sx={{ display: { xs: "none", md: "block" } }}>
              <div className="h-screen">
                <img
                  src={LoginImage}
                  alt="SideImageLogin"
                  className="ms-auto w-auto h-screen object-contain"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};

export default Login;
