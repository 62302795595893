import React, { useState } from "react";
import { Box, IconButton, SvgIcon } from "@mui/material";
import { ReactComponent as WorkButton } from "../../../assets/images/icons/Work-Button.svg";
import SortByDialog from "../SortByDialog";

export default function AnalyzerSorting({ filtersSorting, setFiltersSorting }) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(filtersSorting.sorting);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (option) => {
    setOpen(false);
    setSelectedOption(option);
    setFiltersSorting({ ...filtersSorting, sorting: option });
  };

  return (
    <>
      <Box>
        <IconButton
          size="small"
          className="ArrowIconButton"
          color="grey"
          onClick={handleClickOpen}
          sx={{ border: "none" }}
        >
          <SvgIcon
            sx={{ border: "none" }}
            component={WorkButton}
            inheritViewBox
          />
        </IconButton>
      </Box>

      {/* Sort By Dialog */}
      <SortByDialog
        openSortByDialog={open}
        handleCloseSortByDialog={handleClose}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
    </>
  );
}
