import {
  Box,
  Container,
  Stack,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/icons/filter-icon.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow-icon.svg";
import {
  NCheck,
  OCheck,
  FCheck,
  CCheck,
  SCheck,
} from "../../Common/CustomCheckbox";
import CustomSwitch from "../../Common/Switch/CustomSwitch";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { formatString } from "../HypotheticalAnalyzer/utils";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const SOCGroupORSFilterTransferableSkill = () => {
  const rowData = JSON.parse(localStorage.getItem("row"));
  const localStorageOrsFilters = JSON.parse(
    sessionStorage.getItem("ORSFiltersData")
  );


  const [value, setValue] = useState(0);
  const [physicalTabHeight, setPhysicalTabHeight] = useState();
  const [soc2918Definations, setSoc2918Definations] = useState({});
  const [oewsNationalM2022Data, setOewsNationalM2022Data] = useState([]);
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
  const [orsFilterAllSummaryDemandData, setOrsFilterAllSummaryDemandData] =
    useState([]);
  const [orsFilterSummaryDemandData, setOrsFilterSummaryDemandData] = useState(
    []
  );
  const [orsPhysicalDemandData, setOrsPhysicalDemandData] = useState([]);
  const [orsFilterPhysicalDemandData, setOrsFilterPhysicalDemandData] =
    useState([]);
  const [orsFilterCognitiveDemandData, setOrsFilterCognitiveDemandData] =
    useState([]);
  const [orsFilterEducationalDemandData, setOrsFilterEducationalDemandData] =
    useState([]);
  const [orsFilterEnviromentalDemandData, setOrsFilterEnviromentalDemandData] =
    useState([]);
  const [orsCognitiveDemandData, setOrsCognitiveDemandData] = useState([]);
  const [orsEducationalDemandData, setOrsEducationalDemandData] = useState([]);
  const [orsEnviromentalDemandData, setOrsEnviromentalDemandData] = useState(
    []
  );
  const [orsData, setOrsData] = useState(null);
  const [orsFiltersData, setOrsFiltersData] = useState([]);
  const [selectedEstimateCode, setSelectedEstimateCode] = useState([]);

  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  var dbName = "veToolkitDB";

  // Updating filters data
  useEffect(() => {
    setOrsFilterPhysicalDemandData(orsPhysicalDemandData);
  }, [orsPhysicalDemandData]);
  useEffect(() => {
    setOrsFilterCognitiveDemandData(orsCognitiveDemandData);
  }, [orsCognitiveDemandData]);
  useEffect(() => {
    setOrsFilterEducationalDemandData(orsEducationalDemandData);
  }, [orsEducationalDemandData]);
  useEffect(() => {
    setOrsFilterEnviromentalDemandData(orsEnviromentalDemandData);
  }, [orsEnviromentalDemandData]);

  useEffect(() => {
    if (!orsFilterAllSummaryDemandData || !orsFilterAllSummaryDemandData.length)
      return;

    const filteredData = orsFilterAllSummaryDemandData
      .filter((d) => {
        return d.subtitles.some((subtitle) => {
          return localStorageOrsFilters?.filters?.includes(
            subtitle.EstimateCode
          );
        });
      })
      .map((d) => {
        return {
          ...d,
          subtitles: d.subtitles.filter((subtitle) => {
            return localStorageOrsFilters?.filters?.includes(
              subtitle.EstimateCode
            );
          }),
        };
      });
    setOrsFilterSummaryDemandData(filteredData);
  }, [orsFilterAllSummaryDemandData]);

  useEffect(() => {
    fetch("Soc2918Definations.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const foundObject = data.find(
        (obj) => obj.SocCode === rowData["2018SocCode"]
      );
      setSoc2918Definations(foundObject);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("OrsEducationalTrainingDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsEducationalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("OrsEnvironmentalDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsEnviromentalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("OrsPhysicalDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsPhysicalDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("CofnitiveOrsDemands.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setOrsCognitiveDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      isFirstTimeLoading &&
      (!orsCognitiveDemandData ||
        !orsCognitiveDemandData.length ||
        !orsPhysicalDemandData ||
        !orsPhysicalDemandData.length ||
        !orsEducationalDemandData ||
        !orsEducationalDemandData.length ||
        !orsEnviromentalDemandData ||
        !orsEnviromentalDemandData.length ||
        !orsFilterSummaryDemandData ||
        !orsFilterSummaryDemandData.length)
    )
      return;
    setIsFirstTimeLoading(false);
  }, [
    orsCognitiveDemandData,
    orsPhysicalDemandData,
    orsEducationalDemandData,
    orsEnviromentalDemandData,
    orsFilterSummaryDemandData,
  ]);

  useEffect(() => {
    if (isFirstTimeLoading) return;

    const ORSFiltersData = JSON.parse(sessionStorage.getItem("ORSFiltersData"));
    if (ORSFiltersData && Object.keys(ORSFiltersData).length) {
      // setOrsFiltersData(ORSFiltersData);
      setSelectedEstimateCode(
        ORSFiltersData?.filters ? ORSFiltersData?.filters : []
      );
      renderedAppliedFilters({ localFilters: ORSFiltersData });
    }
  }, [isFirstTimeLoading]);

  const renderedAppliedFilters = ({ value, localFilters }) => {
    const filteredData = localFilters || orsFiltersData.orsFilters[value - 1];
    if (!filteredData) return;

    // setShowingFiltersId(filteredData.id);

    const updateState = (state) => {
      return state.map((button) => {
        if (filteredData.filters?.includes(button.EstimateCode)) {
          return { ...button, isChecked: true };
        } else return { ...button, isChecked: false };
      });
    };

    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          if (filteredData.filters?.includes(subtitle.EstimateCode)) {
            return { ...subtitle, isChecked: true };
          }
          return { ...subtitle, isChecked: false };
        });
        return { ...button, subtitles };
      });
      if (filteredData.filters?.includes("00064")) {
        newData[0].subtitles[1].isChecked = false;
      }
      return newData;
    };

    setOrsFilterAllSummaryDemandData((prevState) =>
      updateSummary(prevState, orsFilterAllSummaryDemandData)
    );

    setOrsFilterPhysicalDemandData((prevState) =>
      updateState(prevState, orsFilterPhysicalDemandData)
    );
    setOrsFilterCognitiveDemandData((prevState) =>
      updateState(prevState, orsFilterCognitiveDemandData)
    );
    setOrsFilterEducationalDemandData((prevState) =>
      updateState(prevState, orsFilterEducationalDemandData)
    );
    setOrsFilterEnviromentalDemandData((prevState) =>
      updateState(prevState, orsFilterEnviromentalDemandData)
    );

    // if (
    //   typeof filteredData.results[filteredData.results.length - 1] === "string"
    // ) {
    //   const note = filteredData.results?.pop();
    //   setNotes(note);
    // }
    // setFilteredOccupations(filteredData.results);
    // onSaveResultInLocalStorage(localFilters.filters, filteredData.results);
  };

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, 2);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        const idToMatch = rowData["2018SocCode"];
        const filteredOews = getData.result?.find(
          (value) => value.OccCode === idToMatch
        );
        setOewsNationalM2022Data(filteredOews);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", {
        autoIncrement: true,
      });
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    fetch("GeneratedOrsDataWithDash.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const socCode = rowData["2018SocCode"]?.replace("-", "");
      let formattedData = data.filter((d) => {
        return d.Soc2018Code === socCode;
      });
      setOrsData(formattedData);
      return;
    });
  }, []);

  useEffect(() => {
    fetch("HypotheticalSummeryCodes.json").then(async (response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      // data = data.filter((d) =>
      //   localStorageOrsFilters?.filters?.includes(d.EstimateCode)
      // );
      data = formateSummaryData(data);
      setOrsFilterAllSummaryDemandData(data);
      return;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setPhysicalTabHeight(innerHeight - 287);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  const redirectTo = (link) => {
    const state = { showOrsFilters: true };
    navigate(link, { state });
  };

  function toggleValueInArray(array, value) {
    const index = array.indexOf(value);
    if (index === -1) {
      // Value is not present, add it
      array.push(value);
    } else {
      // Value is present, remove it
      array.splice(index, 1);
    }
    return array;
  }

  const handleRadioButtonChange = (estimateCode) => {
    const updatedEsCode = toggleValueInArray(
      selectedEstimateCode,
      estimateCode
    );
    setSelectedEstimateCode([...updatedEsCode]);
  };

  // Function to render radio button based on value
  const getCheckBox = (value, name, subtitle) => {
    switch (value) {
      case "N":
        return (
          <NCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "S":
        return (
          <SCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "O":
        return (
          <OCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "F":
        return (
          <FCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      case "C":
        return (
          <CCheck
            sx={{ paddingY: "2px", paddingX: "5px" }}
            name={name}
            value={value || ""}
            checked={selectedEstimateCode?.includes(subtitle.EstimateCode)}
            onChange={() => handleRadioButtonChange(subtitle.EstimateCode)}
          />
        );
      default:
        return null;
    }
  };

  const handleToggleChange = (EstimateCode, isChecked) => {
    const updateState = (state) => {
      return state.map((button) =>
        button.EstimateCode === EstimateCode ? { ...button, isChecked } : button
      );
    };

    const updateSummary = (state) => {
      const newData = state.map((button) => {
        const subtitles = button.subtitles.map((subtitle) => {
          return subtitle.EstimateCode === EstimateCode
            ? { ...subtitle, isChecked }
            : subtitle;
        });
        return { ...button, subtitles };
      });
      if (EstimateCode === "00064") {
        newData[0].subtitles[1].isChecked = !isChecked;
      }
      return newData;
    };

    if (value === 0)
      setOrsFilterAllSummaryDemandData((prevState) =>
        updateSummary(prevState, orsFilterAllSummaryDemandData)
      );
    // if (value === 1 || value === 5)
    //   setOrsFilterPhysicalDemandData((prevState) =>
    //     updateState(prevState, orsFilterPhysicalDemandData)
    //   );
    // if (value === 2 || value === 5)
    //   setOrsFilterCognitiveDemandData((prevState) =>
    //     updateState(prevState, orsFilterCognitiveDemandData)
    //   );
    // if (value === 3 || value === 5)
    //   setOrsFilterEducationalDemandData((prevState) =>
    //     updateState(prevState, orsFilterEducationalDemandData)
    //   );
    // if (value === 4 || value === 5)
    //   setOrsFilterEnviromentalDemandData((prevState) =>
    //     updateState(prevState, orsFilterEnviromentalDemandData)
    //   );

    // handleValueToggle(EstimateCode, isChecked);
  };

  const formateSummaryData = (data) => {
    const uniqueTitles = [
      ...new Set(data.map((item) => item.HypotheticalOrsSummerySection)),
    ];

    // Group RenameTitleTo by HypotheticalOrsSummerySection
    const groupedData = uniqueTitles.map((title) => ({
      HypotheticalOrsSummerySection: title,
      subtitles: data
        .filter((item) => item.HypotheticalOrsSummerySection === title)
        .map(({ RenameTitleTo, EstimateCode }) => {
          return {
            RenameTitleTo,
            EstimateCode,
          };
        }),
    }));
    return groupedData;
  };

  function getFirstCharOfLastWord(str) {
    let words = str.split(" ");
    let lastWord = words[words.length - 1];
    return lastWord.charAt(0);
  }

  const getEstimate = (estimateCode) => {
    if (!orsData) return;
    const filteredData = orsData.find(
      (ors) =>
        ors.EstimateCode === estimateCode &&
        ors.Soc2018Code.replace("-", "") ===
          rowData["2018SocCode"].replace("-", "")
    );
    if (filteredData) return `${filteredData.Estimate}%`;
    return "-";
  };
  const getErordedJobs = (estimateCode) => {
    if (!orsData) return;
    const filteredData = orsData.find(
      (ors) =>
        ors.EstimateCode === estimateCode &&
        ors.Soc2018Code.replace("-", "") ===
          rowData["2018SocCode"].replace("-", "")
    );
    if (filteredData) {
      const estimate = filteredData.Estimate.replace("<", "").replace(">", "");
      return (
        ((100 - Number(estimate)) * oewsNationalM2022Data?.TotalEmp) /
        100
      )?.toLocaleString("en-US");
    } else {
      return "-";
    }
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={"10px"}>
          <Grid item xs={5}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={0}
              >
                <Box>
                  <Typography variant="h6" component={"h6"}>
                    {soc2918Definations
                      ? soc2918Definations.SocTitle
                      : "SOC Title"}
                  </Typography>
                </Box>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() =>
                        redirectTo("/SOCGroupORSFilterTransferableSkill")
                      }
                    >
                      ORS Filter
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      color="greyPrimary"
                      onClick={() => redirectTo("/SOCGroupInfo")}
                    >
                      Info
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      color="greyPrimary"
                      onClick={() => redirectTo("/SOCGroupRawData")}
                    >
                      ORS Data
                    </Button>
                  </Box>
                </Stack>
              </Stack>
              <Box>
                <Stack
                  padding={1}
                  backgroundColor={"#F5F5F5"}
                  borderRadius={"10px"}
                >
                  <Box
                    sx={{
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Description
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      {formatString(rowData?.DescriptionCombined || "-")}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              {/* <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
                <Stack direction={"row"} padding={1}>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.TotalEmp?.toLocaleString(
                        "en-US"
                      ) || "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total Group Employment
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.H_Mean?.toLocaleString("en-US") ||
                        "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Hourly Mean Wage
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      {oewsNationalM2022Data?.A_Mean?.toLocaleString("en-US") ||
                        "-"}
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Annual Mean Wage
                    </Typography>
                  </Box>
                </Stack>
              </Box> */}

              {/* <Box>
                <Stack padding={1} sx={{ borderBottom: "1px solid #666672" }}>
                  <Box
                    sx={{
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Applied Filter
                    </Typography>
                  </Box>
                  <TableContainer
                    component={Paper}
                    sx={{
                      backgroundColor: "transparent",
                      paddingBottom: 0,
                      paddingTop: 0,
                      boxShadow: "none",
                      marginTop: "10px",
                    }}
                  >
                    <Table sx={{ minWidth: 550 }}>
                      <TableBody>
                        {orsFilterSummaryDemandData?.map((filter) =>
                          filter.subtitles?.map((subtitle) => {
                            return (
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    border: 0,
                                    padding: "4px !important",
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {subtitle.RenameTitleTo !== "NULL"
                                    ? subtitle.RenameTitleTo
                                    : filter.HypotheticalOrsSummerySection}
                                </TableCell>
                                <TableCell align="right">
                                  {getEstimate(subtitle.EstimateCode)}
                                </TableCell>
                                <TableCell align="right">
                                  {getErordedJobs(subtitle.EstimateCode)}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Box>

              <Box backgroundColor={"#F5F5F5"} borderRadius={"10px"}>
                <Stack padding={1}>
                  <Box
                    sx={{
                      borderBottom: "1px solid #666672",
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Hypothetical Employment
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      40,000
                    </Typography>
                  </Box>
                </Stack>
              </Box> */}
            </Stack>
          </Grid>

          <Grid item xs={7}>
            <Box className="bg-white" padding={2} borderRadius={"10px"}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Typography variant="h6">Filter</Typography>
                <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="none"
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        justifyContent: "flex-end",
                        gap: 1,
                      },
                    }}
                  >
                    <Tab
                      label="Summary"
                      {...a11yProps(0)}
                      sx={{
                        color:
                          value === 0
                            ? "#ffffff !important"
                            : "#666672 !important",
                        backgroundColor: value === 0 ? "#0089D7" : "#F5F5F5",
                        minHeight: "20px",
                        minWidth: "16px",
                        paddingX: "15px",
                        paddingY: "10px",
                        alignItems: "end",
                      }}
                    />
                    <Tab
                      label="Physical"
                      {...a11yProps(1)}
                      sx={{
                        color:
                          value === 1
                            ? "#ffffff !important"
                            : "#666672 !important",
                        backgroundColor: value === 1 ? "#0089D7" : "#F5F5F5",
                        minHeight: "20px",
                        minWidth: "16px",
                        paddingX: "15px",
                        paddingY: "10px",
                        alignItems: "end",
                      }}
                    />
                    <Tab
                      label="Cognitive"
                      {...a11yProps(2)}
                      sx={{
                        color:
                          value === 2
                            ? "#ffffff !important"
                            : "#666672 !important",
                        backgroundColor: value === 2 ? "#0089D7" : "#F5F5F5",
                        minHeight: "20px",
                        minWidth: "16px",
                        paddingX: "15px",
                        paddingY: "10px",
                        alignItems: "end",
                      }}
                    />
                    <Tab
                      label="Educational"
                      {...a11yProps(3)}
                      sx={{
                        color:
                          value === 3
                            ? "#ffffff !important"
                            : "#666672 !important",
                        backgroundColor: value === 3 ? "#0089D7" : "#F5F5F5",
                        minHeight: "20px",
                        minWidth: "16px",
                        paddingX: "15px",
                        paddingY: "10px",
                        alignItems: "end",
                      }}
                    />
                    <Tab
                      label="Environmental"
                      {...a11yProps(4)}
                      sx={{
                        color:
                          value === 4
                            ? "#ffffff !important"
                            : "#666672 !important",
                        backgroundColor: value === 4 ? "#0089D7" : "#F5F5F5",
                        minHeight: "20px",
                        minWidth: "16px",
                        paddingX: "15px",
                        paddingY: "10px",
                        alignItems: "end",
                      }}
                    />
                  </Tabs>
                </Stack>
              </Stack>

              <TabPanel value={value} index={0}>
                <Box
                  sx={{ height: `${physicalTabHeight}px`, overflow: "auto" }}
                >
                  <Grid
                    container
                    rowSpacing={"8px"}
                    columnSpacing={"15px"}
                    sx={{
                      "& > .MuiGrid-item:not(:nth-child(3n)) > .MuiStack-root":
                        {
                          borderRight: "1px solid #666672",
                          paddingRight: "15px",
                        },
                    }}
                  >
                    {orsFilterAllSummaryDemandData.map((data, index) => {
                      return (
                        <Grid item xs={4} key={index}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              "&:hover": { backgroundColor: "#E6F3FB66" },
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              {data.subtitles.length === 1 &&
                              data.subtitles[0].RenameTitleTo !== "NULL"
                                ? data.subtitles[0].RenameTitleTo
                                : data.HypotheticalOrsSummerySection}
                            </Typography>
                            <div>
                              {data?.HypotheticalOrsSummerySection !==
                                "SVP 1 & 2" && data?.subtitles?.length > 1 ? (
                                data?.subtitles?.map((subtitle) => {
                                  return getCheckBox(
                                    getFirstCharOfLastWord(
                                      subtitle?.RenameTitleTo
                                    ),
                                    data?.HypotheticalOrsSummerySection,
                                    subtitle
                                  );
                                })
                              ) : (
                                <CustomSwitch
                                  name="choiceOfSitting1"
                                  id="choiceOfSitting1"
                                  value={!!data.subtitles[0].isChecked}
                                  checked={!!data.subtitles[0].isChecked}
                                  onChange={(e) =>
                                    handleToggleChange(
                                      data.subtitles[0].EstimateCode,
                                      !data.subtitles[0].isChecked
                                    )
                                  }
                                />
                              )}
                            </div>
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box
                  sx={{ height: `${physicalTabHeight}px`, overflow: "auto" }}
                >
                  <Grid
                    container
                    rowSpacing={"8px"}
                    columnSpacing={"15px"}
                    sx={{
                      "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                        borderRight: "1px solid #666672",
                        paddingRight: "15px",
                      },
                    }}
                  >
                    {orsFilterPhysicalDemandData.map((data, index) => (
                      <Grid item xs={6} key={index}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": { backgroundColor: "#E6F3FB66" },
                          }}
                        >
                          <Typography variant="p" component={"p"}>
                            {data.Defination}
                          </Typography>
                          <CustomSwitch
                            name="choiceOfSitting1"
                            id="choiceOfSitting1"
                            value={!!data.isChecked}
                            checked={!!data.isChecked}
                            onChange={(e) =>
                              handleToggleChange(
                                data.EstimateCode,
                                !data.isChecked
                              )
                            }
                          />
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box
                  sx={{ height: `${physicalTabHeight}px`, overflow: "auto" }}
                >
                  <Grid
                    container
                    rowSpacing={"8px"}
                    columnSpacing={"15px"}
                    sx={{
                      "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                        borderRight: "1px solid #666672",
                        paddingRight: "15px",
                      },
                    }}
                  >
                    {orsFilterCognitiveDemandData.map((data, index) => (
                      <Grid item xs={6} key={index}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": { backgroundColor: "#E6F3FB66" },
                          }}
                        >
                          <Typography variant="p" component={"p"}>
                            {data.Defination}
                          </Typography>
                          <CustomSwitch
                            name="choiceOfSitting5"
                            id="choiceOfSitting5"
                            value={!!data.isChecked}
                            checked={!!data.isChecked}
                            onChange={(e) =>
                              handleToggleChange(
                                data.EstimateCode,
                                !data.isChecked
                              )
                            }
                          />
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Box
                  sx={{ height: `${physicalTabHeight}px`, overflow: "auto" }}
                >
                  <Grid
                    container
                    rowSpacing={"8px"}
                    columnSpacing={"15px"}
                    sx={{
                      "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                        borderRight: "1px solid #666672",
                        paddingRight: "15px",
                      },
                    }}
                  >
                    {orsFilterEducationalDemandData.map((data, index) => (
                      <Grid item xs={6} key={index}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": { backgroundColor: "#E6F3FB66" },
                          }}
                        >
                          <Typography variant="p" component={"p"}>
                            {data.Defination}
                          </Typography>
                          <CustomSwitch
                            name="choiceOfSitting5"
                            id="choiceOfSitting5"
                            value={!!data.isChecked}
                            checked={!!data.isChecked}
                            onChange={(e) =>
                              handleToggleChange(
                                data.EstimateCode,
                                !data.isChecked
                              )
                            }
                          />
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Box
                  sx={{ height: `${physicalTabHeight}px`, overflow: "auto" }}
                >
                  <Grid
                    container
                    rowSpacing={"8px"}
                    columnSpacing={"15px"}
                    sx={{
                      "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                        borderRight: "1px solid #666672",
                        paddingRight: "15px",
                      },
                    }}
                  >
                    {orsFilterEnviromentalDemandData.map((data, index) => (
                      <Grid item xs={6} key={index}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:hover": { backgroundColor: "#E6F3FB66" },
                          }}
                        >
                          <Typography variant="p" component={"p"}>
                            {data.Defination}
                          </Typography>
                          <CustomSwitch
                            name="choiceOfSitting5"
                            id="choiceOfSitting5"
                            value={!!data.isChecked}
                            checked={!!data.isChecked}
                            onChange={(e) =>
                              handleToggleChange(
                                data.EstimateCode,
                                !data.isChecked
                              )
                            }
                          />
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SOCGroupORSFilterTransferableSkill;
