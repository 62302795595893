// import {
//   Box,
//   Stack,
//   TextField,
//   Grid,
//   Button,
//   Typography,
//   Pagination,
//   RadioGroup,
//   FormControl,
// } from "@mui/material";
// import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
// import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
// import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
// import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";
// import {
//   OneCheck,
//   TwOCheck,
//   ThreeCheck,
//   FourCheck,
//   FiveCheck,
//   SixCheck,
// } from "../../Common/CustomCheckbox";
// import { useFormik } from "formik";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box>{children}</Box>}
//     </div>
//   );
// }

// const CognitiveTabs = ({ cognitiveTabHeight, value }) => {
//   const cognitiveFormik = useFormik({
//     initialValues: {
//       reasoning: "",
//       formPerceptions: "",
//       generalLearningAbility: "",
//       fingerDexterity: "",
//       verbalAptitude: "",
//       notes: "",
//     },
//     onSubmit: async (data) => {
//       console.log("data: " + JSON.stringify(data));
//     },
//   });
//   return (
//     <>
//       <form onSubmit={cognitiveFormik.handleSubmit}>
//         <TabPanel value={value} index={1}>
//           <Box sx={{ height: `${cognitiveTabHeight}px`, overflow: "scroll" }}>
//             <Grid
//               container
//               spacing={"15px"}
//               marginTop={"10px"}
//               // sx={{}}
//               sx={{
//                 "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
//                   borderRight: "1px solid #666672",
//                 },
//               }}
//             >
//               <Grid item xs={6}>
//                 <Stack
//                   direction={"row"}
//                   justifyContent={"space-between"}
//                   paddingRight={"15px"}
//                 >
//                   <Typography variant="p" component={"p"}>
//                     Reasoning
//                   </Typography>
//                   <Stack
//                     direction={"row"}
//                     justifyContent={"space-between"}
//                     alignItems={"center"}
//                   >
//                     <FormControl>
//                       <RadioGroup row sx={{ flexWrap: "nowrap" }}>
//                         <OneCheck
//                           name="reasoning"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={cognitiveFormik.values.reasoning === "1"}
//                           value="1"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <TwOCheck
//                           name="reasoning"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={cognitiveFormik.values.reasoning === "2"}
//                           value="2"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <ThreeCheck
//                           name="reasoning"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={cognitiveFormik.values.reasoning === "3"}
//                           value="3"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FourCheck
//                           name="reasoning"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={cognitiveFormik.values.reasoning === "4"}
//                           value="4"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FiveCheck
//                           name="reasoning"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={cognitiveFormik.values.reasoning === "5"}
//                           value="5"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <SixCheck
//                           name="reasoning"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={cognitiveFormik.values.reasoning === "6"}
//                           value="6"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   </Stack>
//                 </Stack>
//               </Grid>

//               <Grid item xs={6}>
//                 <Stack
//                   direction={"row"}
//                   justifyContent={"space-between"}
//                   paddingRight={"15px"}
//                 >
//                   <Typography variant="p" component={"p"}>
//                     Form Perceptions
//                   </Typography>
//                   <Stack
//                     direction={"row"}
//                     justifyContent={"space-between"}
//                     alignItems={"center"}
//                   >
//                     <FormControl>
//                       <RadioGroup row sx={{ flexWrap: "nowrap" }}>
//                         <OneCheck
//                           name="formPerceptions"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.formPerceptions === "1"
//                           }
//                           value="1"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <TwOCheck
//                           name="formPerceptions"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.formPerceptions === "2"
//                           }
//                           value="2"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <ThreeCheck
//                           name="formPerceptions"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.formPerceptions === "3"
//                           }
//                           value="3"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FourCheck
//                           name="formPerceptions"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.formPerceptions === "4"
//                           }
//                           value="4"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FiveCheck
//                           name="formPerceptions"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.formPerceptions === "5"
//                           }
//                           value="5"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <SixCheck
//                           name="formPerceptions"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.formPerceptions === "6"
//                           }
//                           value="6"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   </Stack>
//                 </Stack>
//               </Grid>

//               <Grid item xs={6}>
//                 <Stack
//                   direction={"row"}
//                   justifyContent={"space-between"}
//                   paddingRight={"15px"}
//                 >
//                   <Typography variant="p" component={"p"}>
//                     General Learning Ability
//                   </Typography>
//                   <Stack
//                     direction={"row"}
//                     justifyContent={"space-between"}
//                     alignItems={"center"}
//                   >
//                     <FormControl>
//                       <RadioGroup row sx={{ flexWrap: "nowrap" }}>
//                         <OneCheck
//                           name="generalLearningAbility"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.generalLearningAbility ===
//                             "1"
//                           }
//                           value="1"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <TwOCheck
//                           name="generalLearningAbility"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.generalLearningAbility ===
//                             "2"
//                           }
//                           value="2"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <ThreeCheck
//                           name="generalLearningAbility"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.generalLearningAbility ===
//                             "3"
//                           }
//                           value="3"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FourCheck
//                           name="generalLearningAbility"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.generalLearningAbility ===
//                             "4"
//                           }
//                           value="4"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FiveCheck
//                           name="generalLearningAbility"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.generalLearningAbility ===
//                             "5"
//                           }
//                           value="5"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <SixCheck
//                           name="generalLearningAbility"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.generalLearningAbility ===
//                             "6"
//                           }
//                           value="6"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   </Stack>
//                 </Stack>
//               </Grid>

//               <Grid item xs={6}>
//                 <Stack
//                   direction={"row"}
//                   justifyContent={"space-between"}
//                   paddingRight={"15px"}
//                 >
//                   <Typography variant="p" component={"p"}>
//                     Finger Dexterity
//                   </Typography>
//                   <Stack
//                     direction={"row"}
//                     justifyContent={"space-between"}
//                     alignItems={"center"}
//                   >
//                     <FormControl>
//                       <RadioGroup row sx={{ flexWrap: "nowrap" }}>
//                         <OneCheck
//                           name="fingerDexterity"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.fingerDexterity === "1"
//                           }
//                           value="1"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <TwOCheck
//                           name="fingerDexterity"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.fingerDexterity === "2"
//                           }
//                           value="2"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <ThreeCheck
//                           name="fingerDexterity"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.fingerDexterity === "3"
//                           }
//                           value="3"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FourCheck
//                           name="fingerDexterity"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.fingerDexterity === "4"
//                           }
//                           value="4"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FiveCheck
//                           name="fingerDexterity"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.fingerDexterity === "5"
//                           }
//                           value="5"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <SixCheck
//                           name="fingerDexterity"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.fingerDexterity === "6"
//                           }
//                           value="6"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   </Stack>
//                 </Stack>
//               </Grid>

//               <Grid item xs={6}>
//                 <Stack
//                   direction={"row"}
//                   justifyContent={"space-between"}
//                   paddingRight={"15px"}
//                 >
//                   <Typography variant="p" component={"p"}>
//                     Finger Dexterity
//                   </Typography>
//                   <Stack
//                     direction={"row"}
//                     justifyContent={"space-between"}
//                     alignItems={"center"}
//                   >
//                     <FormControl>
//                       <RadioGroup row sx={{ flexWrap: "nowrap" }}>
//                         <OneCheck
//                           name="verbalAptitude"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.verbalAptitude === "1"
//                           }
//                           value="1"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <TwOCheck
//                           name="verbalAptitude"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.verbalAptitude === "2"
//                           }
//                           value="2"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <ThreeCheck
//                           name="verbalAptitude"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.verbalAptitude === "3"
//                           }
//                           value="3"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FourCheck
//                           name="verbalAptitude"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.verbalAptitude === "4"
//                           }
//                           value="4"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <FiveCheck
//                           name="verbalAptitude"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.verbalAptitude === "5"
//                           }
//                           value="5"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                         <SixCheck
//                           name="verbalAptitude"
//                           onChange={cognitiveFormik.handleChange}
//                           checked={
//                             cognitiveFormik.values.verbalAptitude === "6"
//                           }
//                           value="6"
//                           sx={{ paddingY: "2px", paddingX: "5px" }}
//                         />
//                       </RadioGroup>
//                     </FormControl>
//                   </Stack>
//                 </Stack>
//               </Grid>
//             </Grid>
//           </Box>
//           <Stack
//             direction={"row"}
//             spacing={"10px"}
//             marginTop={"20px"}
//             alignItems={"flex-end"}
//           >
//             <Box flexGrow={1}>
//               <TextField
//                 variant="filled"
//                 multiline
//                 rows={4}
//                 fullWidth
//                 placeholder="Notes"
//                 name="notes"
//                 value={cognitiveFormik.values.notes}
//                 onChange={cognitiveFormik.handleChange}
//                 sx={{
//                   "& .MuiInputBase-multiline": {
//                     padding: "10px 12px 8px",
//                   },
//                 }}
//               />
//             </Box>
//             <Box>
//               <Button
//                 type="submit"
//                 variant="contained"
//                 size="large"
//                 startIcon={<SearchIcon />}
//               >
//                 Search
//               </Button>
//             </Box>
//           </Stack>
//           <Stack
//             direction={"row"}
//             spacing={1}
//             justifyContent={"space-between"}
//             className="HypotheticalPagination"
//             mt={"10px"}
//             sx={{ borderTop: "1px solid #666672", paddingTop: "10px" }}
//           >
//             <Pagination count={4} color="primary" />
//             <Stack direction={"row"} spacing={2}>
//               <Button
//                 variant="outlined"
//                 color="error"
//                 startIcon={<DeleteIcon />}
//               >
//                 Delete
//               </Button>
//               <Button variant="outlined" startIcon={<DuplicateIcon />}>
//                 Duplicate
//               </Button>
//               <Button variant="contained" startIcon={<AddIcon />}>
//                 Add New
//               </Button>
//             </Stack>
//           </Stack>
//         </TabPanel>
//       </form>
//     </>
//   );
// };

// export default CognitiveTabs;

import React from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  FormControl,
  Pagination,
  RadioGroup,
  TextField,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import {
  OneCheck,
  TwOCheck,
  ThreeCheck,
  FourCheck,
  FiveCheck,
  SixCheck,
} from "../../Common/CustomCheckbox";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CognitiveTabs = ({
  cognitiveTabHeight,
  value,
  getFilterOccupationData,
}) => {
  const environmentFormik = useFormik({
    initialValues: {
      reasoning: "",
      formPerceptions: "",
      generalLearningAbility: "",
      fingerDexterity: "",
      verbalAptitude: "",
      notes: "",
    },
    onSubmit: async (data) => {
      getFilterOccupationData([
        {
          name: "Clerk, General",
          id: "456,382-1024",
          detailsLink: "/HypotheticalToolDOTExpandedView",
          detailsButtonText: "View Details",
          detailsButtonColor: "DetailsBtn",
          physical: "Medium",
          svp: "03",
          avgOES: "12,000",
          custom: "13,000",
        },
        {
          name: "Clerk, General",
          id: "456,382-1024",
          detailsLink: "/HypotheticalToolDOTExpandedView",
          detailsButtonText: "View Details",
          detailsButtonColor: "DetailsBtn",
          physical: "Medium",
          svp: "04",
          avgOES: "12,000",
          custom: "13,000",
        },
        {
          name: "Clerk, General",
          id: "456,382-1024",
          detailsLink: "/HypotheticalToolDOTExpandedView",
          detailsButtonText: "View Details",
          detailsButtonColor: "DetailsBtn",
          physical: "Medium",
          svp: "05",
          avgOES: "12,000",
          custom: "13,000",
        },
      ]);
    },
  });

  const radioOptions = [
    {
      label: "Reasoning",
      name: "reasoning",
    },
    {
      label: "Form Perceptions",
      name: "formPerceptions",
    },
    {
      label: "General Learning Ability",
      name: "generalLearningAbility",
    },
    {
      label: "Finger Dexterity",
      name: "fingerDexterity",
    },
    {
      label: "Verbal Aptitude",
      name: "verbalAptitude",
    },
  ];

  return (
    <form onSubmit={environmentFormik.handleSubmit}>
      <TabPanel value={value} index={1}>
        <Box sx={{ height: `${cognitiveTabHeight}px`, overflow: "scroll" }}>
          <Grid
            container
            spacing={"15px"}
            marginTop={"10px"}
            // sx={{}}
            sx={{
              "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                borderRight: "1px solid #666672",
              },
            }}
          >
            {radioOptions.map((option, index) => (
              <Grid item xs={6} key={index}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  paddingRight={"15px"}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "#E6F3FB66" },
                  }}
                >
                  <Typography variant="p">{option.label}</Typography>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormControl>
                      <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                        {["1", "2", "3", "4", "5", "6"].map(
                          (radioValue, index) => (
                            <React.Fragment key={index}>
                              {getRadioButton(
                                radioValue,
                                option.name,
                                environmentFormik
                              )}
                            </React.Fragment>
                          )
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Stack
          direction={"row"}
          spacing={"10px"}
          marginTop={"20px"}
          alignItems={"flex-end"}
        >
          <Box flexGrow={1}>
            <TextField
              variant="filled"
              multiline
              rows={1}
              maxRows={4}
              fullWidth
              placeholder="Notes"
              name="notes"
              value={environmentFormik.values.notes}
              onChange={environmentFormik.handleChange}
              sx={{
                "& .MuiInputBase-multiline": {
                  padding: "10px 12px 8px",
                },
              }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              size="large"
              startIcon={<SearchIcon />}
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"space-between"}
          className="HypotheticalPagination"
          mt={"10px"}
          sx={{ borderTop: "1px solid #666672", paddingTop: "10px" }}
        >
          <Pagination count={4} color="primary" />
          <Stack direction={"row"} spacing={2}>
            <Button variant="outlined" color="error" startIcon={<DeleteIcon />}>
              Delete
            </Button>
            <Button variant="outlined" startIcon={<DuplicateIcon />}>
            Duplicate
            </Button>
            <Button variant="contained" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Stack>
        </Stack>
      </TabPanel>
    </form>
  );
};

// Function to render radio button based on value
const getRadioButton = (value, name, formik) => {
  switch (value) {
    case "1":
      return (
        <OneCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "2":
      return (
        <TwOCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "3":
      return (
        <ThreeCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "4":
      return (
        <FourCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "5":
      return (
        <FiveCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "6":
      return (
        <SixCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    default:
      return null;
  }
};

export default CognitiveTabs;
