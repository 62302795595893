import React from "react";
import CardsSection from "./Components/CardsSection";
import CardInfoSection from "./Components/CardInfoSection";
import "./Subscription.css";
function Subscription() {
  return (
    <>
      <div className="Subscription-bg">
        <CardsSection />
        {/* <CardInfoSection/> */}
      </div>
    </>
  );
}

export default Subscription;
