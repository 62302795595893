import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M10.068 14.12C9.332 14.12 8.72 13.984 8.232 13.712C7.752 13.44 7.388 13.06 7.14 12.572C6.892 12.084 6.756 11.516 6.732 10.868C6.724 10.54 6.72 10.188 6.72 9.812C6.72 9.436 6.724 9.076 6.732 8.732C6.756 8.084 6.892 7.516 7.14 7.028C7.388 6.54 7.752 6.16 8.232 5.888C8.72 5.616 9.332 5.48 10.068 5.48C10.62 5.48 11.104 5.556 11.52 5.708C11.936 5.86 12.28 6.064 12.552 6.32C12.832 6.576 13.044 6.864 13.188 7.184C13.332 7.496 13.412 7.82 13.428 8.156C13.436 8.228 13.412 8.288 13.356 8.336C13.308 8.384 13.248 8.408 13.176 8.408H12.516C12.444 8.408 12.38 8.388 12.324 8.348C12.276 8.308 12.24 8.236 12.216 8.132C12.08 7.508 11.824 7.08 11.448 6.848C11.08 6.616 10.616 6.5 10.056 6.5C9.416 6.5 8.908 6.684 8.532 7.052C8.156 7.412 7.952 7.992 7.92 8.792C7.896 9.448 7.896 10.12 7.92 10.808C7.952 11.608 8.156 12.192 8.532 12.56C8.908 12.92 9.416 13.1 10.056 13.1C10.616 13.1 11.08 12.984 11.448 12.752C11.824 12.52 12.08 12.092 12.216 11.468C12.24 11.364 12.276 11.292 12.324 11.252C12.38 11.212 12.444 11.192 12.516 11.192H13.176C13.248 11.192 13.308 11.216 13.356 11.264C13.412 11.312 13.436 11.372 13.428 11.444C13.412 11.78 13.332 12.108 13.188 12.428C13.044 12.74 12.832 13.024 12.552 13.28C12.28 13.536 11.936 13.74 11.52 13.892C11.104 14.044 10.62 14.12 10.068 14.12Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M10.068 14.12C9.332 14.12 8.72 13.984 8.232 13.712C7.752 13.44 7.388 13.06 7.14 12.572C6.892 12.084 6.756 11.516 6.732 10.868C6.724 10.54 6.72 10.188 6.72 9.812C6.72 9.436 6.724 9.076 6.732 8.732C6.756 8.084 6.892 7.516 7.14 7.028C7.388 6.54 7.752 6.16 8.232 5.888C8.72 5.616 9.332 5.48 10.068 5.48C10.62 5.48 11.104 5.556 11.52 5.708C11.936 5.86 12.28 6.064 12.552 6.32C12.832 6.576 13.044 6.864 13.188 7.184C13.332 7.496 13.412 7.82 13.428 8.156C13.436 8.228 13.412 8.288 13.356 8.336C13.308 8.384 13.248 8.408 13.176 8.408H12.516C12.444 8.408 12.38 8.388 12.324 8.348C12.276 8.308 12.24 8.236 12.216 8.132C12.08 7.508 11.824 7.08 11.448 6.848C11.08 6.616 10.616 6.5 10.056 6.5C9.416 6.5 8.908 6.684 8.532 7.052C8.156 7.412 7.952 7.992 7.92 8.792C7.896 9.448 7.896 10.12 7.92 10.808C7.952 11.608 8.156 12.192 8.532 12.56C8.908 12.92 9.416 13.1 10.056 13.1C10.616 13.1 11.08 12.984 11.448 12.752C11.824 12.52 12.08 12.092 12.216 11.468C12.24 11.364 12.276 11.292 12.324 11.252C12.38 11.212 12.444 11.192 12.516 11.192H13.176C13.248 11.192 13.308 11.216 13.356 11.264C13.412 11.312 13.436 11.372 13.428 11.444C13.412 11.78 13.332 12.108 13.188 12.428C13.044 12.74 12.832 13.024 12.552 13.28C12.28 13.536 11.936 13.74 11.52 13.892C11.104 14.044 10.62 14.12 10.068 14.12Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M10.068 14.12C9.332 14.12 8.72 13.984 8.232 13.712C7.752 13.44 7.388 13.06 7.14 12.572C6.892 12.084 6.756 11.516 6.732 10.868C6.724 10.54 6.72 10.188 6.72 9.812C6.72 9.436 6.724 9.076 6.732 8.732C6.756 8.084 6.892 7.516 7.14 7.028C7.388 6.54 7.752 6.16 8.232 5.888C8.72 5.616 9.332 5.48 10.068 5.48C10.62 5.48 11.104 5.556 11.52 5.708C11.936 5.86 12.28 6.064 12.552 6.32C12.832 6.576 13.044 6.864 13.188 7.184C13.332 7.496 13.412 7.82 13.428 8.156C13.436 8.228 13.412 8.288 13.356 8.336C13.308 8.384 13.248 8.408 13.176 8.408H12.516C12.444 8.408 12.38 8.388 12.324 8.348C12.276 8.308 12.24 8.236 12.216 8.132C12.08 7.508 11.824 7.08 11.448 6.848C11.08 6.616 10.616 6.5 10.056 6.5C9.416 6.5 8.908 6.684 8.532 7.052C8.156 7.412 7.952 7.992 7.92 8.792C7.896 9.448 7.896 10.12 7.92 10.808C7.952 11.608 8.156 12.192 8.532 12.56C8.908 12.92 9.416 13.1 10.056 13.1C10.616 13.1 11.08 12.984 11.448 12.752C11.824 12.52 12.08 12.092 12.216 11.468C12.24 11.364 12.276 11.292 12.324 11.252C12.38 11.212 12.444 11.192 12.516 11.192H13.176C13.248 11.192 13.308 11.216 13.356 11.264C13.412 11.312 13.436 11.372 13.428 11.444C13.412 11.78 13.332 12.108 13.188 12.428C13.044 12.74 12.832 13.024 12.552 13.28C12.28 13.536 11.936 13.74 11.52 13.892C11.104 14.044 10.62 14.12 10.068 14.12Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const CCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default CCheck;
