import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as ReSubscribeIcon } from "../../assets/images/icons/ReSubscribeIcon.svg";
import { Link } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
// import CachedIcon from '@mui/icons-material/Cached';

const tableData = [
  {
    id: "01 March 2024",
    plan: "Premium",
    startDate: "10 March 2024",
    endDate: "10 March 2025",
    totalDays: "1234",
    price: "123",
  },
  {
    id: "01 March 2024",
    plan: "Basic",
    startDate: "10 March 2024",
    endDate: "10 March 2025",
    totalDays: "1234",
    price: "123",
  },
  {
    id: "01 March 2024",
    plan: "Standard",
    startDate: "10 March 2024",
    endDate: "10 March 2025",
    totalDays: "1234",
    price: "123",
  },
  {
    id: "01 March 2024",
    plan: "Premium",
    startDate: "10 March 2024",
    endDate: "10 March 2025",
    totalDays: "1234",
    price: "123",
  },
  {
    id: "01 March 2024",
    plan: "Premium",
    startDate: "10 March 2024",
    endDate: "10 March 2025",
    totalDays: "1234",
    price: "123",
  },
];

function SubsriptionHistory() {
  const [resultHeight, setResultHeight] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    handleGetRequest("auth/subscription-history").then((response) => {
      if (response?.statusCode === 200) {
        setData(response.data);
      }
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setResultHeight(innerHeight - 225);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line
  return (
    <Container
      maxWidth
      sx={{ paddingTop: "80px", paddingBottom: "10px", px: { xs: "10px" } }}
    >
      <Box className="bg-white" padding={2} borderRadius={"10px"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          paddingBottom={1}
        >
          <Box>
            <Typography variant="h6" color="#666672">
              Subscription History
            </Typography>
          </Box>
          {/* <Box>
            <Link to="/subscriptionplan">
              <Button variant="contained" color="primary">
                By Subscription Packages
              </Button>
            </Link>
          </Box> */}
        </Stack>

        <TableContainer
          component={Paper}
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            overflow: "scroll",
            height: `${resultHeight}px`,
            boxShadow: "none",
          }}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">#</TableCell>
                <TableCell align="left">Subscription ID</TableCell>
                <TableCell align="left">Subscription Plan</TableCell>
                <TableCell align="left">Start Date</TableCell>
                <TableCell align="left">End Date</TableCell>
                <TableCell align="left">Total Days</TableCell>
                <TableCell align="left">Price</TableCell>
                {/* <TableCell align="right">Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length ? (
                data.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      // "&:last-child td, &:last-child th": { border: 0 },
                      "& td, & th": { paddingY: "8px !important" },
                      "&:hover": { backgroundColor: "#E6F3FB66" },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {"0" + ++index}
                    </TableCell>
                    <TableCell align="left">{data.id}</TableCell>
                    <TableCell align="left">
                      {/* {data.plan} */}
                      <Chip
                        label={data.plan_name}
                        size="small"
                        color={
                          data.plan_name === "premium plus" ||
                          data.plan_name === "basic plus"
                            ? "primaryChip"
                            : data.plan_name === "Premium" ||
                              data.plan_name === "Standard" ||
                              data.plan_name === "Basic" ||
                              data.plan_name === "Free trial"
                            ? "successChip"
                            : "errorChip"
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      {new Date(data.startDate)?.toLocaleString("en-US", {
                        dateStyle: "medium",
                      })}
                    </TableCell>
                    <TableCell align="left">
                      {new Date(data.endDate)?.toLocaleString("en-US", {
                        dateStyle: "medium",
                      })}
                    </TableCell>
                    <TableCell align="left">{data.durationInDays}</TableCell>
                    <TableCell align="left">{data.discountPrice}</TableCell>
                    {/* <TableCell align="right">
                      <Stack
                        direction={"row"}
                        spacing={1}
                        sx={{ justifyContent: "end" }}
                      >
                        <IconButton size="small" className="EyeIconButton">
                          <SvgIcon component={ReSubscribeIcon} inheritViewBox />
                        </IconButton>
                      </Stack>
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <div>No Data Found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <Box sx={{ paddingX: 3, borderTop: "1px solid #D4D4D4" }}>
          <TablePagination
            component="div"
            count={100}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box> */}
      </Box>
    </Container>
  );
}

export default SubsriptionHistory;
