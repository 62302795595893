import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import Logo from "../../../assets/images/logos/ve-toolkit-logo.svg";
import { ReactComponent as CheckCircle } from "../../../assets/images/icons/check-circle.svg";
import { useNavigate } from "react-router";
import { ReactComponent as CrossIcon } from "../../../assets/images/icons/cross-icon.svg";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePostRequest } from "../../../services/PostTemplate";
import { useDispatch } from "react-redux";

function CardsSection() {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const getDuration = (numberOfDays) => {
    switch (numberOfDays) {
      case 7:
        return "Week";
      case 30:
        return "Month";
      case 180:
        return "06 months";
      case 365:
        return "Year";
    }
  };

  useEffect(() => {
    const asyncFunc = async () => {
      const resp = await handleGetRequest("subscription/");
      if (resp.statusCode === 200) {
        setSubscriptions(resp.data);
      }
      setIsLoading(false);
    };
    asyncFunc();
  }, []);
  const cardsData = [
    {
      cardHeading: "Premium",
      time: "year",
      cost: "150",
      data: [
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
      ],
    },
    {
      cardHeading: "Basic",
      time: "year",
      cost: "150",
      data: [
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
      ],
    },
    {
      cardHeading: "Standard",
      time: "year",
      cost: "150",
      data: [
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
      ],
    },
    {
      cardHeading: "Free Trail",
      time: "year",
      cost: "150",
      data: [
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
        "Lorem Ipsum dummy text",
      ],
    },
  ];
  return (
    <>
      <Container maxWidth sx={{ px: { xs: "40px" } }}>
        <Box paddingTop={"40px"} paddingBottom={"50px"}>
          <img src={Logo} alt="" />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            mb={"28px"}
            fontSize={"28px"}
            variant="h6"
            component={"h6"}
            color={"primary.main"}
          >
            Select Subscription&nbsp;
            <Typography
              variant="h6"
              component={"span"}
              color={"text.primary"}
              fontSize={"28px"}
            >
              Package to Continue
            </Typography>
          </Typography>
          <IconButton
            size="small"
            className="EyeIconButton"
            onClick={() => navigate("/signup")}
            sx={{
              padding: "2px",
              borderRadius: "4px !important",
            }}
          >
            <SvgIcon component={CrossIcon} inheritViewBox />
          </IconButton>
        </Box>

        {loading ? (
          <Stack
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 4,
              backgroundColor: "#95959512",
              borderRadius: "10px",
            }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container spacing={2}>
            {subscriptions?.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <Card
                  borderRadius={"20px"}
                  sx={{ boxShadow: "0px 0px 20px 0px #00000014" }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      textAlign={"center"}
                      fontWeight={"500"}
                      color={"primary.main"}
                      mb={2}
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      gutterBottom
                      textAlign={"center"}
                      fontWeight={"600"}
                      fontSize={"22px"}
                    >
                      ${Number(item?.subscriptionprice)}
                      <Typography
                        component={"span"}
                        variant="span"
                        sx={{
                          color: "gray",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        /{item?.durationtext}
                      </Typography>
                    </Typography>
                    <Divider />

                    <FormGroup>
                      <FormControlLabel
                        sx={{
                          display: "flex",
                          flexDirection: "Row",
                          justifyContent: "start",
                          marginTop: "20px",
                          gap: "10px",
                          marginLeft: "0px",
                        }}
                        key={index}
                        // control={<Checkbox disableRipple />}
                        control={<CheckCircle key={index} disableRipple />}
                        label={item.description}
                      />
                    </FormGroup>

                    <Box
                      mt={2}
                      display="flex"
                      justifyContent="center"
                      onClick={async () => {
                        sessionStorage.setItem("subscriptionId", item.id);
                        const resp = await dispatch(handlePostRequest(
                          { subscriptionId: item.id },
                          `subscription/stripe-checkout-session`
                        ));
                        if (resp?.data?.url) {
                          window.location.href = (resp?.data?.url);
                        }
                      }}
                    >
                      <Button variant="outlined" color="primary">
                        Get Started
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
}

export default CardsSection;
