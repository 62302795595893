import {
  Box,
  Container,
  Stack,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
  ToggleButton,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as EyeIconOff } from "../../assets/images/icons/eye-off-icon.svg";
import { ReactComponent as DescriptionIcon } from "../../assets/images/icons/description-icon.svg";
import { ReactComponent as RequirementsIcon } from "../../assets/images/icons/requirements-icon.svg";
import { ReactComponent as CodeIcon } from "../../assets/images/icons/code-lg-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function createData(DOT, Title, SVP, Strength, MeanOESEmp) {
  return { DOT, Title, SVP, Strength, MeanOESEmp };
}
const rows = [
  createData("313.261-026", "Cook Specialty", 5, "M", "11,000"),
  createData("313.261-027", "Cook Specialty", 5, "M", "11,000"),
  createData("313.261-028", "Cook Specialty", 5, "M", "11,000"),
  createData("313.261-029", "Cook Specialty", 5, "M", "11,000"),
  createData("313.261-030", "Cook Specialty", 5, "M", "11,000"),
];
const SOCGroupInfoTransferableSkill = () => {
  const [DOTTitleViewDisplay, setDOTTitleViewDisplay] = useState(false);
  // State to track selected state of each item
  const [selectedItems, setSelectedItems] = useState({});
  // Define what happens when an item is selected
  const handleSelect = (itemId) => {
    setDOTTitleViewDisplay(true);
    //    setWorkHistoryHeight(window.innerHeight-500)
  };

  const navigate = useNavigate();

  const handleDeselect = (itemId) => {
    // Additional deselect logic here
    //    setWorkHistoryHeight(window.innerHeight - 225);
    setDOTTitleViewDisplay(false);
  };

  // Function to toggle select/deselect and call respective functions
  const handleToggleSelect = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = !prevSelectedItems[itemId];
      // Call handleSelect or handleDeselect based on the new state
      if (isSelected) {
        handleSelect(itemId);
        // Deselect all other items
        return { [itemId]: true };
      } else {
        handleDeselect(itemId);
        return { [itemId]: false };
      }
    });
  };
  const isAnyItemSelected = Object.values(selectedItems).some((value) => value);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const redirectTo = (link) => {
    navigate(link);
  }

  return (
    <>
      <Container
        maxWidth
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={"10px"}>
          <Grid item xs={6}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={0}
              >
                <Box>
                  <Typography variant="h6" component={"h6"}>
                    SOC Title
                  </Typography>
                </Box>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Box>
                    <Link to="/SOCGroupORSFilterTransferableSkill">
                      <Button
                        variant="contained"
                        size="small"
                        color="greyPrimary"
                      >
                        ORS Filter
                      </Button>
                    </Link>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => redirectTo('/SOCGroupInfoTransferableSkill')}
                    >
                      Info
                    </Button>
                  </Box>
                  <Box>
                    <Link to="/SOCGroupRawDataTransferableSkill">
                      <Button
                        variant="contained"
                        size="small"
                        color="greyPrimary"
                      >
                        ORS Data
                      </Button>
                    </Link>
                  </Box>
                </Stack>
              </Stack>
              <Box sx={{ backgroundColor: "#F5F5F5" }}>
                <Stack padding={1}>
                  <Box
                    sx={{
                      borderBottom: "1px solid #666672",
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Description
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      Prepare and cook food in a fast food restaurant with a
                      limited menu. Duties of these cooks are limited to
                      preparation of a few basic items and normally involve
                      operating large-volume single purpose cooking equipment.
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
                <Stack direction={"row"} padding={1}>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      10,000
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total Group Employment
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      10,000
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total Group Employment
                    </Typography>
                  </Box>
                  <Box textAlign={"center"} flexGrow={1}>
                    <Typography variant="p" component={"p"} fontWeight={"bold"}>
                      10,000
                    </Typography>
                    <Typography variant="p" component={"p"} color={"#666672"}>
                      Total Group Employment
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <Box sx={{ backgroundColor: "#F5F5F5" }}>
                <Stack padding={1}>
                  <Box
                    sx={{
                      borderBottom: "1px solid #666672",
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Applied Filter
                    </Typography>
                  </Box>
                  <TableContainer
                    component={Paper}
                    sx={{
                      backgroundColor: "transparent",
                      paddingBottom: 0,
                      paddingTop: 0,
                      boxShadow: "none",
                      marginTop: "10px",
                    }}
                  >
                    <Table sx={{ minWidth: 550 }}>
                      <TableBody>
                        <TableRow
                          sx={{
                            "& td, & th": {
                              border: 0,
                              padding: "4px !important",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Light Work
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="right">20%</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "& td, & th": {
                              border: 0,
                              padding: "4px !important",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Stoop
                          </TableCell>
                          <TableCell align="left">Occasional</TableCell>
                          <TableCell align="right">30%</TableCell>
                        </TableRow>
                        <TableRow
                          sx={{
                            "& td, & th": {
                              border: 0,
                              padding: "4px !important",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Light Work
                          </TableCell>
                          <TableCell align="left">Occasional</TableCell>
                          <TableCell align="right">20%</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Box>

              <Box sx={{ backgroundColor: "#F5F5F5" }}>
                <Stack padding={1}>
                  <Box
                    sx={{
                      borderBottom: "1px solid #666672",
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Hypothetical Employment
                    </Typography>
                  </Box>
                  <Box sx={{ paddingTop: "5px" }}>
                    <Typography variant="p" component={"p"}>
                      40,000
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            {DOTTitleViewDisplay && (
              <Box
                className="bg-white"
                padding={1}
                borderRadius={"10px"}
                marginTop={"10px"}
                position={"relative"}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6">DOT Title View</Typography>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="none"
                      sx={{
                        "& .MuiTabs-flexContainer": {
                          justifyContent: "flex-end",
                          gap: 2,
                        },
                      }}
                    >
                      <Tab
                        label="Description"
                        icon={<DescriptionIcon />}
                        iconPosition="start"
                        {...a11yProps(0)}
                        sx={{
                          color:
                            value === 0
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 0 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <Tab
                        label="Requirements"
                        {...a11yProps(1)}
                        icon={<RequirementsIcon />}
                        iconPosition="start"
                        sx={{
                          color:
                            value === 1
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 1 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                      <Tab
                        label="Codes"
                        icon={<CodeIcon />}
                        iconPosition="start"
                        {...a11yProps(2)}
                        sx={{
                          color:
                            value === 2
                              ? "#ffffff !important"
                              : "#666672 !important",
                          backgroundColor: value === 2 ? "#0089D7" : "#F5F5F5",
                          minHeight: "20px",
                          paddingX: "10px",
                          paddingY: "8px",
                          alignItems: "end",
                        }}
                      />
                    </Tabs>
                  </Box>
                </Stack>

                <TabPanel value={value} index={0}>
                  <Box>
                    <Stack
                      direction={"row"}
                      my={1}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                        >
                          Administrative Clerk
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"text.grey"}
                          sx={{ display: "flex" }}
                        >
                          <CodeIcon />
                          Code
                        </Typography>
                        <Typography
                          variant="h6"
                          component={"h6"}
                          fontSize={"0.875rem"}
                        >
                          219,362,010
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      marginBottom={1}
                    >
                      <Typography
                        variant="p"
                        component={"p"}
                        color={"text.grey"}
                        sx={{ display: "flex" }}
                      >
                        Specific Vocational Preparation (SVP):
                      </Typography>
                      <Typography
                        variant="h6"
                        component={"h6"}
                        fontSize={"0.875rem"}
                      >
                        219,362,010
                      </Typography>
                    </Stack>

                    <Stack direction={"row"} spacing={1}>
                      <Stack flexGrow={1} spacing={1}>
                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Physical Demands
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Strength
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Sedentary
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Reaching
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Frequently
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Handling
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Fingering
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Talking
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Hearing
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Near Acuity
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Constant
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              GED:
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Reasoning
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 3
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Mathematics
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 6
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Language
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 5
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Environmental
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Noise
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Quiet
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Situations
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              T, J
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 3
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>

                      <Stack flexGrow={1}>
                        <TableContainer
                          component={Paper}
                          sx={{
                            padding: 0,
                            // paddingRight: "0px !important",
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: "1px solid #666672",
                          }}
                        >
                          <Table stickyHeader>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "& td, & th": {
                                    padding: "11px !important",
                                    borderColor: "#666672",
                                  },
                                }}
                              >
                                <TableCell align="left">Aptitudes:</TableCell>
                                <TableCell align="left">Aptitudes:</TableCell>
                                <TableCell align="right">Level</TableCell>
                              </TableRow>

                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box>
                    <Stack
                      direction={"row"}
                      my={1}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                        >
                          Administrative Clerk
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"text.grey"}
                          sx={{ display: "flex" }}
                        >
                          <CodeIcon />
                          Code
                        </Typography>
                        <Typography
                          variant="h6"
                          component={"h6"}
                          fontSize={"0.875rem"}
                        >
                          219,362,010
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      marginBottom={1}
                    >
                      <Typography
                        variant="p"
                        component={"p"}
                        color={"text.grey"}
                        sx={{ display: "flex" }}
                      >
                        Specific Vocational Preparation (SVP):
                      </Typography>
                      <Typography
                        variant="h6"
                        component={"h6"}
                        fontSize={"0.875rem"}
                      >
                        219,362,010
                      </Typography>
                    </Stack>

                    <Stack direction={"row"} spacing={1}>
                      <Stack flexGrow={1} spacing={1}>
                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Physical Demands
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Strength
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Sedentary
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Reaching
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Frequently
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Handling
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Fingering
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Talking
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Hearing
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Near Acuity
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Constant
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              GED:
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Reasoning
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 3
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Mathematics
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 6
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Language
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 5
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Environmental
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Noise
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Quiet
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Situations
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              T, J
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 3
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>

                      <Stack flexGrow={1}>
                        <TableContainer
                          component={Paper}
                          sx={{
                            padding: 0,
                            // paddingRight: "0px !important",
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: "1px solid #666672",
                          }}
                        >
                          <Table stickyHeader>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "& td, & th": {
                                    padding: "11px !important",
                                    borderColor: "#666672",
                                  },
                                }}
                              >
                                <TableCell align="left">Aptitudes:</TableCell>
                                <TableCell align="left">Aptitudes:</TableCell>
                                <TableCell align="right">Level</TableCell>
                              </TableRow>

                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box>
                    <Stack
                      direction={"row"}
                      my={1}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Box flexGrow={1}>
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"primary"}
                        >
                          Administrative Clerk
                        </Typography>
                      </Box>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          component={"p"}
                          color={"text.grey"}
                          sx={{ display: "flex" }}
                        >
                          <CodeIcon />
                          Code
                        </Typography>
                        <Typography
                          variant="h6"
                          component={"h6"}
                          fontSize={"0.875rem"}
                        >
                          219,362,010
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      marginBottom={1}
                    >
                      <Typography
                        variant="p"
                        component={"p"}
                        color={"text.grey"}
                        sx={{ display: "flex" }}
                      >
                        Specific Vocational Preparation (SVP):
                      </Typography>
                      <Typography
                        variant="h6"
                        component={"h6"}
                        fontSize={"0.875rem"}
                      >
                        219,362,010
                      </Typography>
                    </Stack>

                    <Stack direction={"row"} spacing={1}>
                      <Stack flexGrow={1} spacing={1}>
                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Physical Demands
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Strength
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Sedentary
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Reaching
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Frequently
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Handling
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Fingering
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Talking
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Hearing
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Occasionally
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Near Acuity
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Constant
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              GED:
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Reasoning
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 3
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Mathematics
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 6
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Language
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 5
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Environmental
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              Noise
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Quiet
                            </Typography>
                          </Stack>
                        </Box>

                        <Box borderRadius={"10px"} border={"1px solid #666672"}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{
                              borderBottom: "1px solid #666672",
                              padding: "10px",
                            }}
                          >
                            <Typography variant="p" component={"p"}>
                              Situations
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ paddingTop: "10px", paddingX: "10px" }}
                          >
                            <Typography variant="p" component={"p"}>
                              T, J
                            </Typography>
                            <Typography variant="p" component={"p"}>
                              Level 3
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>

                      <Stack flexGrow={1}>
                        <TableContainer
                          component={Paper}
                          sx={{
                            padding: 0,
                            // paddingRight: "0px !important",
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: "1px solid #666672",
                          }}
                        >
                          <Table stickyHeader>
                            <TableBody>
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "& td, & th": {
                                    padding: "11px !important",
                                    borderColor: "#666672",
                                  },
                                }}
                              >
                                <TableCell align="left">Aptitudes:</TableCell>
                                <TableCell align="left">Aptitudes:</TableCell>
                                <TableCell align="right">Level</TableCell>
                              </TableRow>

                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  "& td, & th": {
                                    padding: "11px !important",
                                    paddingBottom: "0px !important",
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  General Learning Ability
                                </TableCell>
                                <TableCell align="left">2</TableCell>
                                <TableCell align="right">3+</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Stack>
                  </Box>
                </TabPanel>
              </Box>
            )}
          </Grid>

          <Grid item xs={6}>
            <Stack
              spacing={1}
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
            >
              <Box>
                <Typography variant="h6" component={"h6"}>
                  DOT Titles in SOC 35-2011 Cooks, Fast Food
                </Typography>
              </Box>
              <TableContainer
                component={Paper}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  boxShadow: "none",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">DOT</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">SVP</TableCell>
                      <TableCell align="left">Strength</TableCell>
                      <TableCell align="left">Mean OEWS Emp</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.DOT}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "& td, & th": { paddingY: "4px !important" },
                          "&:hover": { backgroundColor: "#E6F3FB66" },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.DOT}
                        </TableCell>
                        <TableCell align="left">{row.Title}</TableCell>
                        <TableCell align="left">{row.SVP}</TableCell>
                        <TableCell align="left">{row.Strength}</TableCell>
                        <TableCell align="left">{row.MeanOESEmp}</TableCell>
                        <TableCell align="right">
                          <ToggleButton
                            size="small"
                            value="check"
                            className={`${
                              selectedItems[row.DOT]
                                ? "EyeIconOffButton"
                                : "EyeIconButton"
                            }`}
                            sx={{ border: 0 }}
                            selected={!!selectedItems[row.DOT]} // Use double negation to ensure boolean
                            onChange={() => handleToggleSelect(row.DOT)}
                            // disabled={
                            //   isAnyItemSelected && !selectedItems[row.DOT]
                            // }
                          >
                            {selectedItems[row.DOT] ? (
                              <SvgIcon component={EyeIconOff} inheritViewBox />
                            ) : (
                              <SvgIcon component={EyeIcon} inheritViewBox />
                            )}
                          </ToggleButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SOCGroupInfoTransferableSkill;
