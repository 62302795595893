import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import AppContainer from "./containers/AppContainer/AppContainer";

import Signup from "./Authentication/SignUp/Signup";
import Login from "./Authentication/Login/Login";

import HypotheticalAnalyzer from "./Pages/HypotheticalAnalyzer/HypotheticalAnalyzer";
import HypotheticalToolDOTExpandedView from "./Pages/HypotheticalAnalyzer/HypotheticalToolDOTExpandedView";
import SOCGroupInfo from "./Pages/SOCGroup/SOCGroupInfo";
import SOCGroupRawData from "./Pages/SOCGroup/SOCGroupRawData";
import Dashboard from "./Pages/Dashboard/Dashboard";
import WorkHistory from "./Pages/WorkHistory/WorkHistory";
import HypotheticalToolSummaryORS from "./Pages/HypotheticalAnalysisORSTools/HypotheticalToolSummaryORS";
import SearchAppBar from "./Test";
import TransferableSkill from "./Pages/TransferableSkill/TransferableSkill";
import SOCGroupInfoTransferableSkill from "./Pages/SOCGroupTransferableSkill/SOCGroupInfoTransferableSkill";
import SOCGroupRawDataTransferableSkill from "./Pages/SOCGroupTransferableSkill/SOCGroupRawDataTransferableSkill";
import SOCGroupORSFilterTransferableSkill from "./Pages/SOCGroupTransferableSkill/SOCGroupORSFilterTransferableSkill";
import HypotheticalAnalyzerSetting from "./Pages/HypotheticalAnalyzerSetting/HypotheticalAnalyzerSetting";
import Account from "./Pages/Seetings/Account";
import Subscription from "./Pages/Subscription/Subscription";
import SubsriptionHistory from "./Pages/Subscription/SubsriptionHistory";
import SOCDOTGroupInfo from "./Pages/SOCGroup/SOCDOTGroupInfo";
import SubscriptionPackage from "./Pages/Subscription/SubscriptionPackage";
import DotDetails from "./Pages/dot-details";

const Router = () => {
  // const navigate = useNavigate();
  const token = localStorage.getItem("token");
  return (
    <>
      <BrowserRouter>
        <Routes>
          {!token ? (
            <>
              <Route path="" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="subscriptionplan" element={<Subscription />} />
              <Route
                path="subscriptionpackage"
                element={<SubscriptionPackage />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route exact path="/*" element={<AppContainer />}>
                <Route index element={<Dashboard />} />
                <Route path="subscriptionplan" element={<Subscription />} />
                <Route path="WorkHistory" element={<WorkHistory />} />
                <Route path="dotDetail" element={<DotDetails />} />
                <Route
                  path="HypotheticalAnalyzer"
                  element={<HypotheticalAnalyzer />}
                />
                <Route
                  path="HypotheticalToolDOTExpandedView"
                  element={<HypotheticalToolDOTExpandedView />}
                />
                <Route path="SOCGroupInfo" element={<SOCGroupInfo />} />
                <Route path="SOCDOTGroupInfo" element={<SOCDOTGroupInfo />} />
                <Route path="SOCGroupRawData" element={<SOCGroupRawData />} />
                <Route
                  path="HypotheticalToolSummaryORS"
                  element={<HypotheticalToolSummaryORS />}
                />
                <Route
                  path="TransferableSkill"
                  element={<TransferableSkill />}
                />
                <Route
                  path="SOCGroupORSFilterTransferableSkill"
                  element={<SOCGroupORSFilterTransferableSkill />}
                />
                {/* <Route
                  path="SOCGroupInfoTransferableSkill"
                  element={<SOCDOTGroupInfo />}
                /> */}
                <Route
                  path="SOCGroupRawDataTransferableSkill"
                  element={<SOCGroupRawDataTransferableSkill />}
                />

                <Route
                  path="HypotheticalAnalyzerSetting"
                  element={<HypotheticalAnalyzerSetting />}
                />
                <Route path="Account" element={<Account />} />

                <Route
                  path="subsciptionhistory"
                  element={<SubsriptionHistory />}
                />
                <Route path="test" element={<SearchAppBar />} />
              </Route>
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
