import React from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  FormControl,
  Pagination,
  RadioGroup,
  TextField,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { NCheck, OCheck, FCheck, CCheck } from "../../Common/CustomCheckbox";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const EnvironmentalTabs = ({ cognitiveTabHeight, value }) => {
  const environmentFormik = useFormik({
    initialValues: {
      exposureToWeather: "",
      movingMechParts: "",
      extremeCold: "",
      exposureToElectricalShocks: "",
      wetAndOrHumid: "",
      workingInHighExposedPlaced: "",
      notes: "",
    },
    onSubmit: async (data) => {
    },
  });

  const radioOptions = [
    {
      label: "Exposure to Weather",
      name: "exposureToWeather",
    },
    {
      label: "Proximity to Moving Mech Parts",
      name: "movingMechParts",
    },
    {
      label: "Extreme Cold",
      name: "extremeCold",
    },
    {
      label: "Exposure to Electrical Shocks",
      name: "exposureToElectricalShocks",
    },
    {
      label: "Wet and/or Humid",
      name: "wetAndOrHumid",
    },
    {
      label: "Working In High, Exposed Placed",
      name: "workingInHighExposedPlaced",
    },
  ];

  return (
    <form onSubmit={environmentFormik.handleSubmit}>
      <TabPanel value={value} index={2}>
        <Box sx={{ height: `${cognitiveTabHeight}px`, overflow: "scroll" }}>
          <Grid
            container
            spacing={"15px"}
            marginTop={"10px"}
            // sx={{}}
            sx={{
              "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                borderRight: "1px solid #666672",
              },
            }}
          >
            {radioOptions.map((option, index) => (
              <Grid item xs={6} key={index}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  paddingRight={"15px"}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "#E6F3FB66" },
                  }}
                >
                  <Typography variant="p">{option.label}</Typography>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormControl>
                      <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                        {["N", "O", "F", "C"].map((radioValue, index) => (
                          <React.Fragment key={index}>
                            {getRadioButton(
                              radioValue,
                              option.name,
                              environmentFormik
                            )}
                          </React.Fragment>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Stack
          direction={"row"}
          spacing={"10px"}
          marginTop={"20px"}
          alignItems={"flex-end"}
        >
          <Box flexGrow={1}>
            <TextField
              variant="filled"
              multiline
              rows={1}
              maxRows={4}
              fullWidth
              placeholder="Notes"
              name="notes"
              value={environmentFormik.values.notes}
              onChange={environmentFormik.handleChange}
              sx={{
                "& .MuiInputBase-multiline": {
                  padding: "10px 12px 8px",
                },
              }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              size="large"
              startIcon={<SearchIcon />}
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"space-between"}
          className="HypotheticalPagination"
          mt={"10px"}
          sx={{ borderTop: "1px solid #666672", paddingTop: "10px" }}
        >
          <Pagination count={4} color="primary" />
          <Stack direction={"row"} spacing={2}>
            <Button variant="outlined" color="error" startIcon={<DeleteIcon />}>
              Delete
            </Button>
            <Button variant="outlined" startIcon={<DuplicateIcon />}>
            Duplicate
            </Button>
            <Button variant="contained" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Stack>
        </Stack>
      </TabPanel>
    </form>
  );
};

// Function to render radio button based on value
const getRadioButton = (value, name, formik) => {
  switch (value) {
    case "N":
      return (
        <NCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "O":
      return (
        <OCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "F":
      return (
        <FCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "C":
      return (
        <CCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    default:
      return null;
  }
};

export default EnvironmentalTabs;
