import {
  Box,
  Container,
  Stack,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  IconButton,
  SvgIcon,
  TextField,
  InputAdornment,
} from "@mui/material";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { Link } from "react-router-dom";

const SOCGroupRawDataTransferableSkill = () => {
  return (
    <>
      <Container
        maxWidth
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Stack
          spacing={1}
          className="bg-white"
          padding={1}
          borderRadius={"10px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={0}
          >
            <Box>
              <Typography variant="h6" component={"h6"}>
                SOC Information
              </Typography>
            </Box>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
            >
               <Box>
               <Link to="/SOCGroupORSFilterTransferableSkill">
                    <Button variant="contained" size="small" color="greyPrimary">
                    ORS Filter
                    </Button>
                    </Link>
                  </Box>
              <Box>
                <Link to="/SOCGroupInfoTransferableSkill">
                  <Button variant="contained" size="small" color="greyPrimary">
                    Info
                  </Button>
                </Link>
              </Box>
              <Box>
              <Link to="/SOCGroupRawDataTransferableSkill">
                <Button variant="contained" size="small" color="primary">
                  ORS Data
                </Button>
                </Link>
              </Box>
            </Stack>
          </Stack>
          <Box sx={{ backgroundColor: "#F5F5F5" }} borderRadius={"10px"}>
            <Stack padding={1}>
              <Box
                sx={{
                  borderBottom: "1px solid #666672",
                  paddingBottom: "3px",
                }}
              >
                <Typography variant="h6" component={"h6"}>
                  SOC 35-2011 Cooks, Fast Food
                </Typography>
              </Box>
              <Box sx={{ paddingTop: "5px" }}>
                <Typography variant="p" component={"p"}>
                  Prepare and cook food in a fast food restaurant with a limited
                  menu. Duties of these cooks are limited to preparation of a
                  few basic items and normally involve operating large-volume
                  single purpose cooking equipment.
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack
          spacing={1}
          className="bg-white"
          padding={1}
          borderRadius={"10px"}
          marginTop={"10px"}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box flexGrow={1}>
            <Typography variant="h6" component={"h6"}>
              DOT Titles in SOC 35-2011 Cooks, Fast Food
            </Typography>
            </Box>
            <Box flexGrow={1} textAlign={'end'} >
            <TextField
              hiddenLabel
              fullWidth
              size="small"
              variant="filled"
              placeholder="Please enter"
              sx={{maxWidth: '400px'}}
              InputProps={{
                endAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
              }}
            />
            </Box>
          </Stack>
          <TableContainer
            component={Paper}
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
              boxShadow: "none",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Cognitive and mental requirements</TableCell>
                  <TableCell align="left">Strength</TableCell>
                  <TableCell align="left">Mean OEWS Emp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& td, & th": { paddingY: '4px !important' },
                    "&:hover": { backgroundColor: "#E6F3FB66" },
                  }}
                >
                  <TableCell component="th" scope="row">
                  Interaction with general public is required
                  </TableCell>
                  <TableCell align="left">77.3</TableCell>
                  <TableCell align="left">0,6</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& td, & th": { paddingY: '4px !important' },
                    "&:hover": { backgroundColor: "#E6F3FB66" },
                  }}
                >
                  <TableCell component="th" scope="row">
                  Interaction with general public is required
                  </TableCell>
                  <TableCell align="left">77.3</TableCell>
                  <TableCell align="left">0,6</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& td, & th": { paddingY: '4px !important' },
                    "&:hover": { backgroundColor: "#E6F3FB66" },
                  }}
                >
                  <TableCell component="th" scope="row">
                  Interaction with general public is required
                  </TableCell>
                  <TableCell align="left">77.3</TableCell>
                  <TableCell align="left">0,6</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </>
  );
};

export default SOCGroupRawDataTransferableSkill;
