import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    text: {
      primary: "#1A1B2D", //d
      secondary: "#666672", //d
      grey: "#666672",
      white: '#ffffff'
    },
    secondary:{
      main: "#666672",
      light: "#666672",
      dark: "#666672",
      contrastText: "#666672",
    },
    primary: {
      main: "#0089D7", // d
      light: "#33a1df",
      dark: "#007dc4",
      contrastText: "#ffffff",
    }, 
   
    DetailsBtn:{
      main: "#0089D7", // d
      light: "#33a1df",
      dark: "#007dc4",
      contrastText: "#ffffff",
    },
    error:{
      main: "#e7222d",
      light: "#ec4e57",
      dark: "#a41820",
      contrastText: "#ffffff",
    },
    info: {
      main: "#01D1D1",
      light: "#E6FAFA",
      dark: "#019494",
      contrastText: "#ffffff",
    },
    lightMain: {
      main: "#f3f3f3",
    },
    grey: {
      main: "#E8E8EA",
      light: "#E8E8EA",
      dark: "#b8b8be",
      contrastText: "#666672",
    },
    greyPrimary: {
      main: "#E8E8EA",
      light: "#E8E8EA",
      dark: "#b8b8be",
      contrastText: "#666672",
    },
    primaryChip: {
      main: "#E6F3FB", 
      contrastText: "#0089D7",
    }, 
    successChip: {
      main: "#E6FAEE",
      contrastText: "#00CD53",
    }, 
   errorChip: {
      main: "#FDE9EA",
      contrastText: "#E7222D",
    }, 
    
  },
  typography: {
    fontFamily: ["Rubik-Regular"],
    h6:{
      fontSize: '1rem',
      fontFamily: 'Rubik-Medium',
    },
    p:{
      fontFamily: 'Rubik-Regular',
      fontSize: '14px'
    },
   span:{
      fontFamily: 'Rubik-Regular',
      fontSize: '14px'
    },
    subtitle2:{
      fontFamily: 'Rubik-Medium',
    },
    subtitle1:{
      fontFamily: 'Rubik-Regular',
    },  
    body1:{
      fontFamily: 'Rubik-Regular',
    },
  }, 
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-colorPrimaryChip': {
            borderRadius: '5px', 
          },
          '&.MuiChip-colorErrorChip': {
            borderRadius: '5px', 
          },
          '&.MuiChip-colorSuccessChip': {
            borderRadius: '5px', 
          },
          
        },
      },
    },

  
    
    MuiTableCell: {
      styleOverrides:{
      head: {
        fontFamily: 'Rubik-Medium',
        color: "#666672",
      },
      body: {
        fontFamily: 'Rubik-Regular',
      },
    },
    },
    MuiButton: {
      styleOverrides: {
        outlinedError: {
          color: "#E7222D",
          borderColor: "#FDE9EA",
          backgroundColor: "#FDE9EA",
          '&:hover': {
            color: "#ffffff",
            borderColor: "#E7222D",
            backgroundColor: "#E7222D",
          },
        },
        outlinedPrimary:{
          color: "#0089D7",
          borderColor: "#E6F3FB",
          backgroundColor: "#E6F3FB",
          '&:hover': {
            color: "#ffffff",
            backgroundColor: "#0089D7",
            borderColor: "#0089D7",
          },
        },
        greyPrimary:{
          color: "#0089D7",
          borderColor: "#000000",
          backgroundColor: "#E6F3FB",
          '&:hover': {
            color: "#ffffff",
            backgroundColor: "#0089D7",
            borderColor: "#0089D7",
          },
        },
        root: {
          textTransform: 'none', 
          borderRadius: '8px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          },
          '&:active': {
            boxShadow: 'none'
          }
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-underline:after': {
            borderBottom: 'none', 
          },
          '& .MuiFilledInput-underline:before': {
            borderBottom: 'none', 
          },
          '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
          '& .MuiOutlinedInput-root.MuiInputBase-colorPrimary': {
            backgroundColor: '#ffffff',
          },
          
          
        },
      },
    },
    MuiSelect:{
      styleOverrides: {
        root: {
          '&.MuiFilledInput-underline:after': {
            borderBottom: 'none', 
          },
          '&.MuiFilledInput-underline:before': {
            borderBottom: 'none', 
          },
          '&.MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
        },
      },
    },
  },
  
 
});

export default theme;
