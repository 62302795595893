import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M8.288 14C8.216 14 8.156 13.976 8.108 13.928C8.068 13.872 8.048 13.808 8.048 13.736C8.048 13.712 8.056 13.676 8.072 13.628L11.06 6.644H7.124C7.044 6.644 6.976 6.62 6.92 6.572C6.872 6.516 6.848 6.448 6.848 6.368V5.888C6.848 5.8 6.872 5.732 6.92 5.684C6.976 5.628 7.044 5.6 7.124 5.6H12.044C12.132 5.6 12.2 5.628 12.248 5.684C12.304 5.732 12.332 5.8 12.332 5.888V6.332C12.332 6.436 12.324 6.52 12.308 6.584C12.292 6.648 12.268 6.72 12.236 6.8L9.272 13.7C9.248 13.764 9.208 13.832 9.152 13.904C9.104 13.968 9.032 14 8.936 14H8.288Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M8.288 14C8.216 14 8.156 13.976 8.108 13.928C8.068 13.872 8.048 13.808 8.048 13.736C8.048 13.712 8.056 13.676 8.072 13.628L11.06 6.644H7.124C7.044 6.644 6.976 6.62 6.92 6.572C6.872 6.516 6.848 6.448 6.848 6.368V5.888C6.848 5.8 6.872 5.732 6.92 5.684C6.976 5.628 7.044 5.6 7.124 5.6H12.044C12.132 5.6 12.2 5.628 12.248 5.684C12.304 5.732 12.332 5.8 12.332 5.888V6.332C12.332 6.436 12.324 6.52 12.308 6.584C12.292 6.648 12.268 6.72 12.236 6.8L9.272 13.7C9.248 13.764 9.208 13.832 9.152 13.904C9.104 13.968 9.032 14 8.936 14H8.288Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M8.288 14C8.216 14 8.156 13.976 8.108 13.928C8.068 13.872 8.048 13.808 8.048 13.736C8.048 13.712 8.056 13.676 8.072 13.628L11.06 6.644H7.124C7.044 6.644 6.976 6.62 6.92 6.572C6.872 6.516 6.848 6.448 6.848 6.368V5.888C6.848 5.8 6.872 5.732 6.92 5.684C6.976 5.628 7.044 5.6 7.124 5.6H12.044C12.132 5.6 12.2 5.628 12.248 5.684C12.304 5.732 12.332 5.8 12.332 5.888V6.332C12.332 6.436 12.324 6.52 12.308 6.584C12.292 6.648 12.268 6.72 12.236 6.8L9.272 13.7C9.248 13.764 9.208 13.832 9.152 13.904C9.104 13.968 9.032 14 8.936 14H8.288Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const SevenCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default SevenCheck;
