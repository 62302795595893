import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";

export const handleDeleteRequest = async (url, isShowToast = false) => {
  try {
    const response = await axios.delete(`${baseURL + url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE1LCJpYXQiOjE3MTU3OTU4MTksImV4cCI6MTcxODM4NzgxOX0.XIADMeQYE3KwKe0-l300PDuU_Gg3J2EMccCT4T43Ya8`,
      },
    });
    if (isShowToast) toast.success(response?.data?.message);

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("password");
    }
    const id = toast.loading("Please wait...");
    if (error?.response?.status === 500) {
      setTimeout(() => {
        toast.update(id, {
          render:
            error?.response?.data?.messages ||
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Something went wrong !!",
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }, 100);
    } else {
      setTimeout(() => {
        toast.update(id, {
          render:
            error?.response?.data?.messages ||
            error?.response?.data?.message ||
            error?.response?.data?.error ||
            "Something went wrong !!",
          type: "warn",
          isLoading: false,
          autoClose: 2000,
        });
      }, 100);
    }
  }
};
