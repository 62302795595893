// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-bg {
  background-color: rgba(230, 243, 251, 0.5019607843) !important;
}`, "",{"version":3,"sources":["webpack://./src/Authentication/Login/Login.scss"],"names":[],"mappings":"AAAA;EACE,8DAAA;AACF","sourcesContent":[".login-bg {\n  background-color: #E6F3FB80 !important ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
