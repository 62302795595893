import { login } from "../../services/Auth";
import { LOGIN_SUCCESS, LOGIN_ERROR } from "../slices/authenticationSlice";

export const loginAction = (authData) => async (dispatch) => {
  const res = await login(authData);
  if (res?.statusCode === 200) {
    localStorage.setItem("token", res?.data?.token);
    sessionStorage.setItem("menues", JSON.stringify(res?.data?.menues));
    dispatch(LOGIN_SUCCESS(res?.data));
    return res;
  } else {
    dispatch(LOGIN_ERROR("Error"));
    return res;
  }
};
