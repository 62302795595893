import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M9.576 14.12C9.072 14.12 8.632 14.06 8.256 13.94C7.88 13.812 7.564 13.644 7.308 13.436C7.052 13.228 6.856 13 6.72 12.752C6.592 12.504 6.524 12.248 6.516 11.984C6.516 11.912 6.54 11.856 6.588 11.816C6.636 11.776 6.696 11.756 6.768 11.756H7.356C7.428 11.756 7.492 11.772 7.548 11.804C7.604 11.836 7.652 11.904 7.692 12.008C7.772 12.296 7.908 12.52 8.1 12.68C8.3 12.84 8.528 12.952 8.784 13.016C9.048 13.072 9.312 13.1 9.576 13.1C10.128 13.1 10.576 12.968 10.92 12.704C11.264 12.44 11.436 12.052 11.436 11.54C11.436 11.02 11.28 10.648 10.968 10.424C10.656 10.2 10.224 10.088 9.672 10.088H8.532C8.452 10.088 8.384 10.064 8.328 10.016C8.28 9.968 8.256 9.9 8.256 9.812V9.488C8.256 9.416 8.268 9.356 8.292 9.308C8.324 9.252 8.356 9.204 8.388 9.164L10.716 6.62H7.176C7.096 6.62 7.028 6.596 6.972 6.548C6.924 6.5 6.9 6.432 6.9 6.344V5.888C6.9 5.8 6.924 5.732 6.972 5.684C7.028 5.628 7.096 5.6 7.176 5.6H11.964C12.052 5.6 12.12 5.628 12.168 5.684C12.224 5.732 12.252 5.8 12.252 5.888V6.32C12.252 6.376 12.236 6.428 12.204 6.476C12.18 6.516 12.152 6.556 12.12 6.596L9.804 9.176L9.996 9.188C10.524 9.204 10.984 9.3 11.376 9.476C11.776 9.652 12.084 9.916 12.3 10.268C12.524 10.612 12.636 11.048 12.636 11.576C12.636 12.112 12.504 12.572 12.24 12.956C11.976 13.332 11.612 13.62 11.148 13.82C10.684 14.02 10.16 14.12 9.576 14.12Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M9.576 14.12C9.072 14.12 8.632 14.06 8.256 13.94C7.88 13.812 7.564 13.644 7.308 13.436C7.052 13.228 6.856 13 6.72 12.752C6.592 12.504 6.524 12.248 6.516 11.984C6.516 11.912 6.54 11.856 6.588 11.816C6.636 11.776 6.696 11.756 6.768 11.756H7.356C7.428 11.756 7.492 11.772 7.548 11.804C7.604 11.836 7.652 11.904 7.692 12.008C7.772 12.296 7.908 12.52 8.1 12.68C8.3 12.84 8.528 12.952 8.784 13.016C9.048 13.072 9.312 13.1 9.576 13.1C10.128 13.1 10.576 12.968 10.92 12.704C11.264 12.44 11.436 12.052 11.436 11.54C11.436 11.02 11.28 10.648 10.968 10.424C10.656 10.2 10.224 10.088 9.672 10.088H8.532C8.452 10.088 8.384 10.064 8.328 10.016C8.28 9.968 8.256 9.9 8.256 9.812V9.488C8.256 9.416 8.268 9.356 8.292 9.308C8.324 9.252 8.356 9.204 8.388 9.164L10.716 6.62H7.176C7.096 6.62 7.028 6.596 6.972 6.548C6.924 6.5 6.9 6.432 6.9 6.344V5.888C6.9 5.8 6.924 5.732 6.972 5.684C7.028 5.628 7.096 5.6 7.176 5.6H11.964C12.052 5.6 12.12 5.628 12.168 5.684C12.224 5.732 12.252 5.8 12.252 5.888V6.32C12.252 6.376 12.236 6.428 12.204 6.476C12.18 6.516 12.152 6.556 12.12 6.596L9.804 9.176L9.996 9.188C10.524 9.204 10.984 9.3 11.376 9.476C11.776 9.652 12.084 9.916 12.3 10.268C12.524 10.612 12.636 11.048 12.636 11.576C12.636 12.112 12.504 12.572 12.24 12.956C11.976 13.332 11.612 13.62 11.148 13.82C10.684 14.02 10.16 14.12 9.576 14.12Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M9.576 14.12C9.072 14.12 8.632 14.06 8.256 13.94C7.88 13.812 7.564 13.644 7.308 13.436C7.052 13.228 6.856 13 6.72 12.752C6.592 12.504 6.524 12.248 6.516 11.984C6.516 11.912 6.54 11.856 6.588 11.816C6.636 11.776 6.696 11.756 6.768 11.756H7.356C7.428 11.756 7.492 11.772 7.548 11.804C7.604 11.836 7.652 11.904 7.692 12.008C7.772 12.296 7.908 12.52 8.1 12.68C8.3 12.84 8.528 12.952 8.784 13.016C9.048 13.072 9.312 13.1 9.576 13.1C10.128 13.1 10.576 12.968 10.92 12.704C11.264 12.44 11.436 12.052 11.436 11.54C11.436 11.02 11.28 10.648 10.968 10.424C10.656 10.2 10.224 10.088 9.672 10.088H8.532C8.452 10.088 8.384 10.064 8.328 10.016C8.28 9.968 8.256 9.9 8.256 9.812V9.488C8.256 9.416 8.268 9.356 8.292 9.308C8.324 9.252 8.356 9.204 8.388 9.164L10.716 6.62H7.176C7.096 6.62 7.028 6.596 6.972 6.548C6.924 6.5 6.9 6.432 6.9 6.344V5.888C6.9 5.8 6.924 5.732 6.972 5.684C7.028 5.628 7.096 5.6 7.176 5.6H11.964C12.052 5.6 12.12 5.628 12.168 5.684C12.224 5.732 12.252 5.8 12.252 5.888V6.32C12.252 6.376 12.236 6.428 12.204 6.476C12.18 6.516 12.152 6.556 12.12 6.596L9.804 9.176L9.996 9.188C10.524 9.204 10.984 9.3 11.376 9.476C11.776 9.652 12.084 9.916 12.3 10.268C12.524 10.612 12.636 11.048 12.636 11.576C12.636 12.112 12.504 12.572 12.24 12.956C11.976 13.332 11.612 13.62 11.148 13.82C10.684 14.02 10.16 14.12 9.576 14.12Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const ThreeCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default ThreeCheck;
