import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M6.864 14C6.784 14 6.716 13.976 6.66 13.928C6.612 13.872 6.588 13.804 6.588 13.724V13.34C6.588 13.268 6.608 13.188 6.648 13.1C6.688 13.012 6.768 12.916 6.888 12.812L9.432 10.268C9.84 9.924 10.16 9.632 10.392 9.392C10.632 9.144 10.8 8.908 10.896 8.684C11 8.46 11.052 8.228 11.052 7.988C11.052 7.532 10.924 7.172 10.668 6.908C10.412 6.636 10.032 6.5 9.528 6.5C9.208 6.5 8.932 6.568 8.7 6.704C8.468 6.832 8.284 7.012 8.148 7.244C8.02 7.468 7.936 7.724 7.896 8.012C7.88 8.116 7.836 8.188 7.764 8.228C7.7 8.268 7.64 8.288 7.584 8.288H6.96C6.888 8.288 6.828 8.268 6.78 8.228C6.732 8.18 6.708 8.124 6.708 8.06C6.716 7.756 6.78 7.452 6.9 7.148C7.02 6.836 7.196 6.556 7.428 6.308C7.668 6.06 7.96 5.86 8.304 5.708C8.656 5.548 9.064 5.468 9.528 5.468C10.16 5.468 10.676 5.584 11.076 5.816C11.484 6.04 11.784 6.34 11.976 6.716C12.168 7.092 12.264 7.504 12.264 7.952C12.264 8.304 12.204 8.628 12.084 8.924C11.964 9.212 11.788 9.496 11.556 9.776C11.332 10.048 11.052 10.328 10.716 10.616L8.412 12.956H12.156C12.244 12.956 12.312 12.98 12.36 13.028C12.416 13.076 12.444 13.144 12.444 13.232V13.724C12.444 13.804 12.416 13.872 12.36 13.928C12.312 13.976 12.244 14 12.156 14H6.864Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M6.864 14C6.784 14 6.716 13.976 6.66 13.928C6.612 13.872 6.588 13.804 6.588 13.724V13.34C6.588 13.268 6.608 13.188 6.648 13.1C6.688 13.012 6.768 12.916 6.888 12.812L9.432 10.268C9.84 9.924 10.16 9.632 10.392 9.392C10.632 9.144 10.8 8.908 10.896 8.684C11 8.46 11.052 8.228 11.052 7.988C11.052 7.532 10.924 7.172 10.668 6.908C10.412 6.636 10.032 6.5 9.528 6.5C9.208 6.5 8.932 6.568 8.7 6.704C8.468 6.832 8.284 7.012 8.148 7.244C8.02 7.468 7.936 7.724 7.896 8.012C7.88 8.116 7.836 8.188 7.764 8.228C7.7 8.268 7.64 8.288 7.584 8.288H6.96C6.888 8.288 6.828 8.268 6.78 8.228C6.732 8.18 6.708 8.124 6.708 8.06C6.716 7.756 6.78 7.452 6.9 7.148C7.02 6.836 7.196 6.556 7.428 6.308C7.668 6.06 7.96 5.86 8.304 5.708C8.656 5.548 9.064 5.468 9.528 5.468C10.16 5.468 10.676 5.584 11.076 5.816C11.484 6.04 11.784 6.34 11.976 6.716C12.168 7.092 12.264 7.504 12.264 7.952C12.264 8.304 12.204 8.628 12.084 8.924C11.964 9.212 11.788 9.496 11.556 9.776C11.332 10.048 11.052 10.328 10.716 10.616L8.412 12.956H12.156C12.244 12.956 12.312 12.98 12.36 13.028C12.416 13.076 12.444 13.144 12.444 13.232V13.724C12.444 13.804 12.416 13.872 12.36 13.928C12.312 13.976 12.244 14 12.156 14H6.864Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M6.864 14C6.784 14 6.716 13.976 6.66 13.928C6.612 13.872 6.588 13.804 6.588 13.724V13.34C6.588 13.268 6.608 13.188 6.648 13.1C6.688 13.012 6.768 12.916 6.888 12.812L9.432 10.268C9.84 9.924 10.16 9.632 10.392 9.392C10.632 9.144 10.8 8.908 10.896 8.684C11 8.46 11.052 8.228 11.052 7.988C11.052 7.532 10.924 7.172 10.668 6.908C10.412 6.636 10.032 6.5 9.528 6.5C9.208 6.5 8.932 6.568 8.7 6.704C8.468 6.832 8.284 7.012 8.148 7.244C8.02 7.468 7.936 7.724 7.896 8.012C7.88 8.116 7.836 8.188 7.764 8.228C7.7 8.268 7.64 8.288 7.584 8.288H6.96C6.888 8.288 6.828 8.268 6.78 8.228C6.732 8.18 6.708 8.124 6.708 8.06C6.716 7.756 6.78 7.452 6.9 7.148C7.02 6.836 7.196 6.556 7.428 6.308C7.668 6.06 7.96 5.86 8.304 5.708C8.656 5.548 9.064 5.468 9.528 5.468C10.16 5.468 10.676 5.584 11.076 5.816C11.484 6.04 11.784 6.34 11.976 6.716C12.168 7.092 12.264 7.504 12.264 7.952C12.264 8.304 12.204 8.628 12.084 8.924C11.964 9.212 11.788 9.496 11.556 9.776C11.332 10.048 11.052 10.328 10.716 10.616L8.412 12.956H12.156C12.244 12.956 12.312 12.98 12.36 13.028C12.416 13.076 12.444 13.144 12.444 13.232V13.724C12.444 13.804 12.416 13.872 12.36 13.928C12.312 13.976 12.244 14 12.156 14H6.864Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const TwoCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default TwoCheck;
