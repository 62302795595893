import {
  Box,
  Container,
  Stack,
  TextField,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  IconButton,
  SvgIcon,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/icons/Edit-Icon.svg";
import DeleteModelIcon from "../../assets/images/icons/delete-modal-icon.svg";
import HypotheticalAnalyzerSettingModel from "./HypotheticalAnalyzerSettingModel";
import { useFormik } from "formik";
import { handleGetRequest } from "../../services/GetTemplate";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
import { toast } from "react-toastify";
// function createData(Code, Title, SVP, Physical, MonEmp) {
//   return { Code, Title, SVP, Physical, MonEmp };
// }
// const rows = [
//   createData("209,562,010", "Clerk, General", 3, "Light", 41),
//   createData("209,562,011", "Clerk, General", 4, "Light", 37),
//   createData("209,562,012", "Clerk, General", 2, "Light", 21),
//   createData("209,562,013", "Clerk, General", 3, "Sedentary", 54),
//   createData("209,562,014", "Clerk, General", 4, "Sedentary", 37),
// ];
const HypotheticalAnalyzerSetting = () => {
  var dbName = "veToolkitDB";
  const [workHistoryHeight, setWorkHistoryHeight] = useState();
  const [resultHeight, setResultHeight] = useState();
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [resultRows, setResultRows] = useState([]);
  const [resultRowData, setResultRowData] = useState();
  const [rows, setRows] = useState([]);
  const [dotTitleRowData, setDotTitleRowData] = useState("");

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingAddData, setLoadingAddData] = useState(false);
  const [loadingDeleteData, setLoadingDeleteData] = useState(false);
  const [alternativeTitles, setAlternativeTitles] = useState([]);

  var dbCurrentVersion;

  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  useEffect(() => {
    fetchDotTitleIndustryCodes();
  }, []);

  const fetchDotTitleIndustryCodes = () => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["DotTitleIndustrycodes"], "readonly");
      const objectStore = transaction.objectStore("DotTitleIndustrycodes");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setAlternativeTitles(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("2010To2018Soc", {
        autoIncrement: true,
      });
    };
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      title: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.code && !data.title) {
        const message = "At least one field is required.";
        errors.message = message;
        toast.error("At least one field is required.");
      }

      return errors;
    },
    onSubmit: async (data) => {
      setLoadingSearch(true);
      handleSearch(data);
    },
  });

  const handledReset = () => {
    formik.resetForm();
    setResultRows([]);
  };

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .replace(/,/g, "") // Remove commas
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .trim(); // Remove leading and trailing spaces
  };

  const getAlternativeTitlesDotCode = (searchTerms) => {
    const dotCodes = alternativeTitles
      .filter((row) => {
        const titleCheck = searchTerms.every((term) =>
          normalizeString(row.Title).includes(term)
        );
        return titleCheck;
      })
      ?.map((value) => String(value.DotCode));
    return dotCodes;
  };

  const handleSearch = (data) => {
    // Open the database
    const searchTerms = normalizeString(data?.title || "").split(" ");

    const alternativeDotCode = getAlternativeTitlesDotCode(searchTerms);

    const request = window.indexedDB.open(dbName, dbCurrentVersion);
    request.onerror = function (event) {
      console.error("Database error: " + event.target.errorCode);
    };
    request.onsuccess = function (event) {
      const db = event.target.result;

      // Start a transaction and get the object store
      const transaction = db.transaction(["DOTMasterTable"], "readonly");

      const store = transaction.objectStore("DOTMasterTable");
      // console.log("store: ", store.indexNames);

      const index = store.index("DotCode");
      // console.log("index", index.DotCode);

      var partialMatchResults = [];

      // Open a cursor on the index
      index.openCursor().onsuccess = function (event) {
        var cursor = event.target.result;
        if (cursor) {
          var dotCodeMatch = String(cursor.value.DotCode);
          var titleMatch = String(cursor.value.Title);
          var descriptionMatch = String(cursor.value.DescriptionCombined);

          // const titleCheck = data?.title
          //   ? titleMatch.toLowerCase().includes(data?.title.toLowerCase())
          //   : true;
          // Normalize function to handle spaces and commas

          const titleCheck = searchTerms.every((term) =>
            normalizeString(titleMatch).includes(term)
          );

          if (
            dotCodeMatch.includes(data?.code) &&
            titleCheck &&
            (titleMatch?.toLowerCase()?.includes(data?.svp?.toLowerCase()) ||
              descriptionMatch
                ?.toLowerCase()
                ?.includes(data?.svp?.toLowerCase())) &&
            descriptionMatch
              ?.toLowerCase()
              ?.includes(data?.physical?.toLowerCase())
          ) {
            partialMatchResults.push(cursor?.value);
            // console.log("the cursor value is",cursor?.value)
            // partialMatchResults.push({DotCode:cursor?.value?.DotCode,Strength:cursor?.value?.Strength,SVP:cursor?.value?.SVP,Title:cursor?.value?.Title,DotCodeFormated:cursor?.value?.DotCodeFormated,DescriptionCombined:cursor?.value?.DescriptionCombined});
          } else if (alternativeDotCode.includes(dotCodeMatch)) {
            partialMatchResults.push(cursor?.value);
          }
          cursor.continue();
        } else {
          // console.log("No more entries!");
          // console.log("Matching records:", partialMatchResults);
          setResultRows([]);
          setResultRows(partialMatchResults);
          partialMatchResults = [];
          setLoadingSearch(false);
        }
      };
    };
  };

  const getDOTTitleSettingData = async () => {
    const response = await handleGetRequest(`dot-titles/v2?limit=100`);
    setRows(response?.data?.dotTitles);
  };

  useEffect(() => {
    getDOTTitleSettingData();
  }, []); // eslint-disable-line

  const handleClickOpen = (rowData) => {
    setResultRowData(rowData);
    setOpen(true);
    setEditable(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenEdit = (rowData) => {
    setOpen(true);
    setEditable(true);
    setDotTitleRowData(rowData);
  };
  const handleClickOpenDeleteDialog = (rowData) => {
    setDotTitleRowData(rowData);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handledDeleteWorkHistory = async () => {
    const response = await handleDeleteRequest(
      `dot-titles/v2/?dotTitleId=${dotTitleRowData?.id}`,
      true
    );
    if (response?.statusCode === 200) {
      getDOTTitleSettingData();
      handleCloseDeleteDialog();
    }
  };
  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setWorkHistoryHeight(innerHeight - 154);
      setResultHeight(innerHeight - 259);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Box className="bg-white" padding={2} borderRadius={"10px"}>
              <Typography variant="h6">
                List of titles for DOT hypothetical analysis
              </Typography>
              <TableContainer
                component={Paper}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  overflow: "scroll",
                  height: `${workHistoryHeight}px`,
                  boxShadow: "none",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Code</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">SVP</TableCell>
                      <TableCell align="right">Physical</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row) => (
                      <TableRow
                        key={row.DotCode}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          // "& td, & th": { paddingY: "4px !important" },
                          "&:hover": { backgroundColor: "#E6F3FB66" },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.DotCode.replace(/^(\d{3})(\d{3})/, "$1.$2-")}
                        </TableCell>
                        <TableCell align="left">{row.Title}</TableCell>
                        <TableCell align="left">{row.SVP}</TableCell>
                        <TableCell align="right">{row.Strength}</TableCell>
                        <TableCell align="right">
                          <Stack
                            direction={"row"}
                            spacing={1}
                            sx={{ justifyContent: "end" }}
                          >
                            <IconButton
                              onClick={() => handleClickOpenEdit(row)}
                              size="small"
                              className="AddIconButton"
                            >
                              <SvgIcon component={EditIcon} inheritViewBox />
                            </IconButton>
                            <IconButton
                              onClick={() => handleClickOpenDeleteDialog(row)}
                              size="small"
                              className="AddIconButton"
                            >
                              <SvgIcon component={DeleteIcon} inheritViewBox />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <form onSubmit={formik.handleSubmit}>
              <Box className="bg-white" padding={1} borderRadius={"10px"}>
                <Stack padding={1}>
                  <Box
                    sx={{
                      // borderBottom: "1px solid #666672",
                      paddingBottom: "3px",
                    }}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Search Dot
                    </Typography>
                  </Box>

                  <Stack direction={"row"} spacing={"10px"} mt={"10px"}>
                    <TextField
                      hiddenLabel
                      fullWidth
                      size="small"
                      variant="filled"
                      placeholder="Code"
                      id="code"
                      name="code"
                      value={formik.values.code}
                      onChange={formik.handleChange}
                    />
                    <TextField
                      hiddenLabel
                      fullWidth
                      size="small"
                      variant="filled"
                      placeholder="Title"
                      id="title"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      spacing={"10px"}
                      mt={"10px"}
                    >
                      <Stack direction={"row"} spacing={"10px"}>
                        <Box>
                          <Button
                            variant="outlined"
                            color="error"
                            startIcon={<CrossIcon />}
                            onClick={handledReset}
                          >
                            Clear
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            type="submit"
                            variant="contained"
                            startIcon={<SearchIcon />}
                          >
                            Search
                          </Button>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </form>

            <Box
              className="bg-white"
              padding={1}
              borderRadius={"10px"}
              mt={"10px"}
              position={"relative"}
            >
              <Typography variant="h6">Results</Typography>
              {loadingSearch && (
                <Stack
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 4,
                    backgroundColor: "#95959512",
                    borderRadius: "10px",
                  }}
                >
                  <CircularProgress />
                </Stack>
              )}
              <TableContainer
                component={Paper}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  overflow: "scroll",
                  height: `${resultHeight}px`,
                  boxShadow: "none",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Code</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">SVP</TableCell>
                      <TableCell align="right">Physical</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultRows?.map((row) => {
                      return (
                        <TableRow
                          key={row.DotCode}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "& td, & th": { paddingY: "4px !important" },
                            "&:hover": { backgroundColor: "#E6F3FB66" },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row && row?.DotCode
                              ? String(row?.DotCode)?.replace(
                                  /^(\d{3})(\d{3})/,
                                  "$1.$2-"
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="left">{row.Title}</TableCell>
                          <TableCell align="left">{row.SVP}</TableCell>
                          <TableCell align="right">{row.Strength}</TableCell>
                          <TableCell align="right">
                            <Stack
                              direction={"row"}
                              spacing={1}
                              sx={{ justifyContent: "end" }}
                            >
                              <IconButton
                                onClick={() => handleClickOpen(row)}
                                size="small"
                                className="AddIconButton"
                              >
                                <SvgIcon component={AddIcon} inheritViewBox />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {open && (
        <HypotheticalAnalyzerSettingModel
          open={open}
          handleClose={handleClose}
          editable={editable}
          DotCode={editable ? dotTitleRowData?.DotCode : resultRowData?.DotCode}
          Title={editable ? dotTitleRowData?.Title : resultRowData?.Title}
          resultRowData={resultRowData}
          dotTitleRowData={dotTitleRowData}
          getDOTTitleSettingData={getDOTTitleSettingData}
        />
      )}

      {/* Delete Dialog */}
      <Fragment>
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          PaperProps={{ sx: { width: "25%", borderRadius: "25px" } }}
        >
          <DialogContent sx={{ padding: 3 }}>
            <Stack gap={1} alignItems={"center"}>
              <Box>
                <img src={DeleteModelIcon} alt="" />
              </Box>
              <Typography variant="p" paddingTop={1}>
                Are you sure to delete ?
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ padding: 3, paddingTop: 0, gap: 2 }}>
            <Box flexGrow={1}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                color="error"
                onClick={() => handledDeleteWorkHistory()}
              >
                Delete
              </Button>
            </Box>
            <Box flexGrow={1}>
              <Button
                color="grey"
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleCloseDeleteDialog}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Fragment>
    </>
  );
};

export default HypotheticalAnalyzerSetting;
