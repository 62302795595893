import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authenticationSlice",
  initialState: {
    loginData: "",
    token: localStorage.getItem("token"),
    menues: sessionStorage.getItem("menues"),
  },
  reducers: {
    LOGIN_SUCCESS: (state, action) => {
      return {
        ...state,
        loginData: action.payload,
        token: localStorage.getItem("token"),
        menues: sessionStorage.getItem("menues"),
      };
    },
    LOGIN_ERROR: (state) => {
      return {
        ...state,
        token: "",
        menues: "",
      };
    },
  },
});

export const { LOGIN_SUCCESS, LOGIN_ERROR } = slice.actions;
export default slice.reducer;
