import {
  Box,
  Container,
  Stack,
  TextField,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  IconButton,
  TableCell,
  Typography,
  TableBody,
  Paper,
  SvgIcon,
  TablePagination,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as EyeIcon } from "../../assets/images/icons/eye-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import DeleteModelIcon from "../../assets/images/icons/delete-modal-icon.svg";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { ReactComponent as ClearIcon } from "../../assets/images/icons/cross-icon.svg";
// import IndexDBDOTMasterTable from "../../components/IndexDBDOTMasterTable";
// import IndexDBEnvironmentalDemandLevel from "../../components/IndexDBEnvironmentalDemandLevel";
// import IndexDBEnvironmentalDemand from "../../components/IndexDBEnvironmentalDemand";
// import IndexDBAptitude from "../../components/IndexDBAptitude";
// import IndexDBPhysicalDemandLevels from "../../components/IndexDBPhysicalDemandLevels";
// import IndexDBDotPhysicalDemands from "../../components/IndexDBDotPhysicalDemands";
// import IndexDB2010To2018Soc from "../../components/IndexDB2010To2018Soc";
// import IndexDBOewsNationalM2022 from "../../components/IndexDBOewsNationalM2022";
// import IndexDBOnnetSocConversions from "../../components/IndexDBOnnetSocConversions";
// import IndexDBDotGeds from "../../components/IndexDBDotGeds";
// import IndexDBDotGedsLevels from "../../components/IndexDBDotGedsLevels";
// import IndexDBOesCodeDefination from "../../components/IndexDBOesCodeDefination";
// import IndexDBOapCodeDefinations from "../../components/IndexDBOapCodeDefinations";
import { handlePostRequest } from "../../services/PostTemplate";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../services/GetTemplate";
import { handleDeleteRequest } from "../../services/DeleteTemplate";
// import IndexDBDataManager from "../../components/IndexDBDataManager";
// import IndexDBDataManager1 from "../../components/IndexDBDataManager1";
// import IndexDBOrsPhysicalDemands from "../../components/IndexDBOrsPhysicalDemands";
// import IndexDBOrsEnvironmentalDemands from "../../components/IndexDBOrsEnvironmentalDemands";
// import OrsEducationalTrainingDemands from "../../components/IndexDBOrsEducationalDemands";
// import IndexDBOrsCognitiveMentalDemands from "../../components/IndexDBOrsCognitiveMentalDemands";
// import IndexDBOrsFootnoteCodes from "../../components/IndexDBOrsFootNotesDemands";
// import IndexDBOrsDataManager from "../../components/IndexDBOrsDataManager";

const Dashboard = () => {
  const [tableHeight, setTableHeight] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [profileData, setProfileData] = useState([]);
  const [originalProfileData, setOriginalProfileData] = useState([]);
  const [profileRowData, setProfileRowData] = useState();
  const [profileLoader, setProfileLoader] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  sessionStorage.removeItem("selectedFiltersId");

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setTableHeight(innerHeight - 190);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  // const generateUniqueId = () => {
  //   const timestamp = new Date().getTime();
  //   const randomNumber = Math.floor(Math.random() * 1000000);
  //   return `${timestamp}-${randomNumber}`;
  // };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      dateofhearing: "",
    },

    validate: (data) => {
      let errors = {};

      if (!data.firstname) {
        errors.firstname = "First name is required.";
      }
      if (!data.lastname) {
        errors.lastname = "Last name is required.";
      }
      if (!data.dateofhearing) {
        errors.dateofhearing = "Date of hearing is required.";
      }

      return errors;
    },

    onSubmit: async (data, { resetForm }) => {
      setloading(true);

      // data["id"] = generateUniqueId();

      // // Retrieve the existing profile data from local storage
      // const existingProfileData =
      //   JSON.parse(localStorage.getItem("profileData")) || [];
      // if (!Array.isArray(existingProfileData)) {
      //   setloading(false);
      //   return;
      // }

      // // Add the new data to the existing array
      // const updatedProfileData = [...existingProfileData, data];

      // // Update state
      // setProfileData(updatedProfileData);

      // // Store the updated profile data in local storage
      // localStorage.setItem("profileData", JSON.stringify(updatedProfileData));
      const response = await dispatch(
        handlePostRequest(data, `profile`, true, true)
      );
      if (response?.statusCode === 200) {
        getProfilesData();
        setloading(false);
        handledViewData(response?.data);
      } else {
        setloading(false);
      }
      resetForm();
    },
  });

  const getProfilesData = async () => {
    const response = await handleGetRequest(`profile`);
    const sortedData = response?.data?.sort((a, b) => {
      return new Date(b.dateofhearing) - new Date(a.dateofhearing);
    });
    setProfileData(sortedData);
    setOriginalProfileData(sortedData);
  };

  useEffect(() => {
    getProfilesData();
    localStorage.removeItem("PhysicalDemands");
  }, []); // eslint-disable-line

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (value) => {
    setOpen(true);
    setProfileRowData(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handledDeleteProfileData = async (data) => {
    // const getProfileData = JSON.parse(localStorage?.getItem("profileData"));
    // console.log(data)
    // const updatedRows = profileData?.filter((value) => value?.id !== data?.id);
    // setProfileData(updatedRows);

    // setOpen(false);

    const response = await handleDeleteRequest(
      `profile/?profileId=${data?.id}`,
      true
    );
    if (response?.statusCode === 200) {
      getProfilesData();
      setOpen(false);
    }
  };

  const handledViewData = async (value, index) => {
    setProfileLoader(index);
    localStorage.setItem("profileId", value.id);
    localStorage.setItem("selectedProfile", JSON.stringify(value));

    const response = await handleGetRequest(`filter?profileId=${value.id}`);
    // have to map this values to showing data
    if (response?.data) {
      localStorage.setItem(
        "hypotheticalFilters",
        JSON.stringify(response?.data)
      );
    }

    navigate(`/WorkHistory`);
    // ?firstName=${value?.firstname}&lastName=${value?.lastname}&dateOfHearing=${value?.dateOfHearing}
  };

  const getProfileData = JSON.parse(localStorage?.getItem("profileData"));

  useEffect(() => {
    setProfileData(getProfileData);
    setOriginalProfileData(getProfileData);
    sessionStorage.removeItem("paginatedValue");
    localStorage.removeItem("notes");
  }, []);
  function formatDate(dateString) {
    // Create a new Date object from the input string
    let date = new Date(dateString);

    // Get the year, month, and day parts from the Date object
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() returns month from 0-11
    let day = date.getDate().toString().padStart(2, "0");

    // Construct the formatted date string
    return `${year}-${month}-${day}`;
  }

  const searchFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dateOfHearing: "",
    },
    // onSubmit: async (data) => {
    //   setloading(true);

    //   let filteredProfileData;
    //  console.log("originalProfileData",originalProfileData)
    //   // Check if search criteria is provided
    //   if (data.firstName || data.lastName || data.dateOfHearing) {
    //     filteredProfileData = originalProfileData.filter((row) => {
    //       const firstNameMatch = row.firstname
    //         .toLowerCase()
    //         .includes(data.firstName.toLowerCase());
    //       const lastNameMatch = row.lastname
    //         .toLowerCase()
    //         .includes(data.lastName.toLowerCase());
    //       const dateOfHearingMatch =
    //         formatDate(row.dateofhearing) === data.dateOfHearing;
    //       return firstNameMatch && lastNameMatch && dateOfHearingMatch;
    //     });
    //   } else {
    //     // If search criteria is empty, show all data
    //     filteredProfileData = [...originalProfileData];
    //   }
    //   console.log("the filteredPofile data is",filteredProfileData)

    //   setProfileData(filteredProfileData);

    //   formik.resetForm();
    //   setloading(false);
    // },
    onSubmit: async (data) => {
      setloading(true);

      let filteredProfileData;

      // Check if any search criteria are provided
      if (data.firstName || data.lastName || data.dateOfHearing) {
        filteredProfileData = originalProfileData.filter((row) => {
          const firstNameMatch = data.firstName
            ? row.firstname.toLowerCase().includes(data.firstName.toLowerCase())
            : true;

          const lastNameMatch = data.lastName
            ? row.lastname.toLowerCase().includes(data.lastName.toLowerCase())
            : true;

          const dateOfHearingMatch = data.dateOfHearing
            ? formatDate(row.dateofhearing) === data.dateOfHearing
            : true;

          // Include the row if all provided criteria match
          return firstNameMatch && lastNameMatch && dateOfHearingMatch;
        });
      } else {
        // If search criteria are empty, show all data
        filteredProfileData = [...originalProfileData];
      }

      setProfileData(filteredProfileData);
      formik.resetForm();
      setloading(false);
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const onSearch = () => {
    // profileData
  };

  const handledClear = (e) => {
    e.preventDefault();
    searchFormik.resetForm();
    getProfilesData();
  };

  return (
    <>
      {/* <IndexDBDOTMasterTable />
      <IndexDBEnvironmentalDemandLevel />
      <IndexDBEnvironmentalDemand />
      <IndexDBAptitude />
      <IndexDBPhysicalDemandLevels />
      <IndexDBDotPhysicalDemands />
      <IndexDB2010To2018Soc />
      <IndexDBOewsNationalM2022 />
      <IndexDBOnnetSocConversions />
      <IndexDBDotGeds />
      <IndexDBDotGedsLevels />
      <IndexDBOesCodeDefination />
      <IndexDBOapCodeDefinations /> */}
      {/* <IndexDBOrsPhysicalDemands />
      <IndexDBOrsEnvironmentalDemands />
      <IndexDBOrsCognitiveMentalDemands />
      <OrsEducationalTrainingDemands />
      <IndexDBOrsFootnoteCodes /> */}

      {/* <IndexDBOrsDataManager /> */}
      {/* <IndexDBDataManager1/> */}

      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Box className="bg-white" padding={2} borderRadius={"10px"}>
              <form onSubmit={searchFormik.handleSubmit}>
                <Stack direction={"row"} gap={2}>
                  <Box flexGrow={1}>
                    <TextField
                      hiddenLabel
                      fullWidth
                      size="small"
                      variant="filled"
                      placeholder="First Name"
                      id="firstName"
                      name="firstName"
                      value={searchFormik.values.firstName || ""}
                      onChange={searchFormik.handleChange}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <TextField
                      hiddenLabel
                      fullWidth
                      size="small"
                      variant="filled"
                      placeholder="Last Name"
                      id="lastName"
                      name="lastName"
                      value={searchFormik.values.lastName || ""}
                      onChange={searchFormik.handleChange}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <TextField
                      hiddenLabel
                      fullWidth
                      size="small"
                      variant="filled"
                      type="date"
                      placeholder="Date of Hearing"
                      id="dateOfHearing"
                      name="dateOfHearing"
                      value={searchFormik.values.dateOfHearing || ""}
                      onChange={searchFormik.handleChange}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center", // Vertically align items in the center
                      gap: 2, // Add space between the buttons (you can adjust the gap as needed)
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<ClearIcon />}
                      onClick={handledClear}
                    >
                      Clear
                    </Button>
                    <Button
                      type="submit"
                      disabled={loading}
                      variant="contained"
                      startIcon={<SearchIcon />}
                    >
                      Search
                    </Button>
                  </Box>
                </Stack>
              </form>

              <TableContainer
                component={Paper}
                sx={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  overflow: "scroll",
                  height: `${tableHeight}px`,
                  boxShadow: "none",
                  marginTop: "10px",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">First Name</TableCell>
                      <TableCell align="left">Last Name</TableCell>
                      <TableCell align="left">Date of Hearing</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {profileData?.map((value, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          "&:hover": { backgroundColor: "#E6F3FB66" },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {value?.firstname}
                        </TableCell>
                        <TableCell align="left">{value?.lastname}</TableCell>
                        <TableCell align="left">
                          {value?.dateofhearing
                            ? new Date(value.dateofhearing).toLocaleDateString(
                                "en-CA"
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction={"row"}
                            spacing={1}
                            sx={{ justifyContent: "end" }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => {
                                sessionStorage.removeItem("DOTFiltersData");
                                sessionStorage.removeItem("ORSFiltersData");
                                handledViewData(value, index);
                              }}
                              className="EyeIconButton"
                            >
                              {profileLoader && profileLoader === index ? (
                                <CircularProgress
                                  style={{ width: 25, height: 25 }}
                                />
                              ) : (
                                <SvgIcon component={EyeIcon} inheritViewBox />
                              )}
                            </IconButton>
                            <IconButton
                              size="small"
                              className="DeleteIconButton"
                              onClick={() => handleClickOpen(value)}
                            >
                              <SvgIcon component={DeleteIcon} inheritViewBox />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <form className="" onSubmit={formik.handleSubmit}>
              <Box className="bg-white" padding={2} borderRadius={"10px"}>
                <Box
                  sx={{ borderBottom: " 1px solid #96969E", paddingBottom: 1 }}
                >
                  <Typography variant="h6">Create Profile</Typography>
                </Box>
                <Box mt={2}>
                  <TextField
                    hiddenLabel
                    fullWidth
                    size="small"
                    variant="filled"
                    id="firstname"
                    name="firstname"
                    value={formik.values.firstname || ""}
                    onChange={formik.handleChange}
                    placeholder="First Name"
                  />
                  {getFormErrorMessage("firstname")}
                </Box>
                <Box mt={2}>
                  <TextField
                    hiddenLabel
                    fullWidth
                    id="lastname"
                    name="lastname"
                    value={formik.values.lastname || ""}
                    onChange={formik.handleChange}
                    size="small"
                    variant="filled"
                    placeholder="Last Name"
                  />
                  {getFormErrorMessage("lastname")}
                </Box>
                {/* <Box mt={2}>
                  <TextField
                    hiddenLabel
                    fullWidth
                    size="small"
                    variant="filled"
                    type="date"
                    id="dateofhearing"
                    name="dateofhearing"
                    value={formik.values.dateofhearing || ""}
                    onChange={formik.handleChange}
                    // placeholder="Date of Hearing"
                     label="Date of Hearing"
                  />
                  {getFormErrorMessage("dateofhearing")}
                </Box> */}
                <Box mt={2}>
                  <TextField
                    label="Date of Hearing"
                    fullWidth
                    size="small"
                    variant="filled"
                    type="date"
                    id="dateofhearing"
                    name="dateofhearing"
                    value={formik.values.dateofhearing || ""}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true, // Ensures label does not overlap
                    }}
                  />
                  {getFormErrorMessage("dateofhearing")}
                </Box>
                <Box mt={2} textAlign={"end"}>
                  <Button type="submit" disabled={loading} variant="contained">
                    Create Profile
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>

      <Fragment>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent sx={{ padding: 3 }}>
            <Stack gap={1} alignItems={"center"}>
              <Box>
                <img src={DeleteModelIcon} alt="" />
              </Box>
              <Typography variant="p" paddingTop={1}>
                Are you sure to delete {profileRowData?.firstname}{" "}
                {profileRowData?.lastname} Profile
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ padding: 3, paddingTop: 0, gap: 2 }}>
            <Box flexGrow={1}>
              <Button
                sx={{ width: "100%" }}
                variant="contained"
                color="error"
                onClick={() => handledDeleteProfileData(profileRowData)}
              >
                Delete
              </Button>
            </Box>
            <Box flexGrow={1}>
              <Button
                color="grey"
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Fragment>
    </>
  );
};

export default Dashboard;
