import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import logo from "../../assets/images/logos/ve-toolkit-logo.svg";
import LoginImage from "../../assets/images/SideImage/LoginSideImage.png";
import "./Signup.scss";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { handlePostRequest } from "../../services/PostTemplate";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";

const Signup = () => {
  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    companyName: Yup.string().required("Company name is required."),
    jobTitle: Yup.string().required("Company name is required."),
    phoneNo: Yup.string().required("Company name is required."),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required."),
    password: Yup.string().required("Password is required."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm Password is required."),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      jobTitle: "",
      email: "",
      phoneNo: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (data) => {
      setloading(true);
      // delete data["ConfirmPassword"];
      const formattedData = {
        firstname: data.firstName,
        lastname: data.lastName,
        company: data.companyName,
        jobtitle: data.jobTitle,
        email: data.email,
        phonenumber: data.phoneNo,
        password: data.password,
        confirmpassword: data.confirmPassword,
      };

      const res = await dispatch(
        handlePostRequest(formattedData, "auth/signup", true, true)
      );

      localStorage.setItem("token", res?.data?.token);

      if (res?.statusCode === 201) {
        //   navigate("/");
        navigate("/subscriptionplan");
        setloading(false);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  return (
    <>
      <form className="signup-bg" onSubmit={formik.handleSubmit}>
        <div className="">
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
              <Box p={3} pb={2} sx={{ padding: { xs: 2, sm: 3 } }}>
                <img src={logo} alt="" />
                <Typography
                  variant="h5"
                  mt={6}
                  sx={{
                    fontSize: "28px",
                  }}
                >
                  <b>
                    Sign up to
                    <Typography
                      variant="span"
                      color={"primary.main"}
                      mb={2}
                      sx={{
                        fontSize: "28px",
                      }}
                    >
                      &nbsp;VE-Toolkit
                    </Typography>
                  </b>
                </Typography>
              </Box>
              <Box
                sx={{
                  // paddingX: { xs: 2, md: 5, lg: 15 },
                  padding: 3,
                  // paddingBottom: { xs: 3, lg: 5 },
                }}
              >
                <Stack gap={2} direction={"row"}>
                  <Box flexGrow={1}>
                    <InputLabel>
                      First Name <span style={{ color: "red" }}>*</span>{" "}
                    </InputLabel>
                    <TextField
                      id="firstName"
                      name="firstName"
                      value={formik.values.firstName || ""}
                      onChange={formik.handleChange}
                      placeholder="Enter first name"
                      variant="outlined"
                      color="primary"
                      fullWidth
                    />
                    {getFormErrorMessage("firstName")}
                  </Box>
                  <Box flexGrow={1}>
                    <InputLabel>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      id="lastName"
                      name="lastName"
                      value={formik.values.lastName || ""}
                      onChange={formik.handleChange}
                      placeholder="Enter last name"
                      variant="outlined"
                      color="primary"
                      fullWidth
                    />
                    {getFormErrorMessage("lastName")}
                  </Box>
                </Stack>
                <Box mt={2}>
                  <InputLabel>
                    Company<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    id="companyName"
                    name="companyName"
                    value={formik.values.companyName || ""}
                    onChange={formik.handleChange}
                    placeholder="Enter company name"
                    variant="outlined"
                    color="primary"
                    fullWidth
                  />
                  {getFormErrorMessage("companyName")}
                </Box>
                <Box mt={2}>
                  <InputLabel>
                    Job Title<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    id="jobTitle"
                    name="jobTitle"
                    value={formik.values.jobTitle || ""}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder="Enter job title"
                    variant="outlined"
                    color="primary"
                  />
                  {getFormErrorMessage("jobTitle")}
                </Box>

                <Stack mt={2} gap={2} direction={"row"}>
                  <Box flexGrow={1}>
                    <InputLabel>
                      Email<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      id="email"
                      name="email"
                      value={formik.values.email || ""}
                      onChange={formik.handleChange}
                      placeholder="Enter email address"
                      variant="outlined"
                      color="primary"
                      fullWidth
                    />
                    {getFormErrorMessage("email")}
                  </Box>
                  <Box flexGrow={1}>
                    <InputLabel>
                      Phone No<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      id="phoneNo"
                      name="phoneNo"
                      value={formik.values.phoneNo || ""}
                      onChange={formik.handleChange}
                      fullWidth
                      placeholder="Enter phone no"
                      variant="outlined"
                      color="primary"
                    />
                    {getFormErrorMessage("phoneNo")}
                  </Box>
                </Stack>

                <Stack mt={2} gap={2} direction={"row"}>
                  <Box flexGrow={1}>
                    <InputLabel>
                      Create Password<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      id="password"
                      name="password"
                      value={formik.values.password || ""}
                      onChange={formik.handleChange}
                      type="password"
                      placeholder="Enter new password"
                      variant="outlined"
                      color="primary"
                      fullWidth
                    />
                    {getFormErrorMessage("password")}
                  </Box>
                  <Box flexGrow={1}>
                    <InputLabel>
                      Confirm Password<span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <TextField
                      id="confirmPassword"
                      name="confirmPassword"
                      value={formik.values.confirmPassword || ""}
                      onChange={formik.handleChange}
                      fullWidth
                      type="password"
                      placeholder="confirm your password"
                      variant="outlined"
                      color="primary"
                    />
                    {getFormErrorMessage("confirmPassword")}
                  </Box>
                </Stack>

                <Grid container spacing={2} my={2}>
                  <Grid item xs={12}>
                    <Box flexGrow={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          width: "100%",
                          paddingBottom: "15px",
                          paddingTop: "15px",
                        }}
                      >
                        Sign up
                      </Button>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Box flexGrow={1}>
                      <Button
                        type="submit"
                        variant="outlined"
                        sx={{
                          width: "100%",
                          paddingBottom: "15px",
                          paddingTop: "15px",
                        }}
                      >
                        Payment Information
                      </Button>
                    </Box>
                  </Grid> */}
                </Grid>

                <Box textAlign={"center"}>
                  <Typography variant="subtitle1" color="initial">
                    If you are Registered Already
                    <Link to="/">
                      <Typography variant="span" color={"primary.main"} my={2}>
                        {" "}
                        Login here
                      </Typography>
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: { xs: "none", md: "block" } }}>
              <div className="h-screen">
                <img
                  src={LoginImage}
                  alt="SideImageLogin"
                  className="ms-auto w-auto h-screen object-contain"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};

export default Signup;
