// useWorker.js
import { useEffect, useRef, useState } from "react";

export const useOrsSearchWorker = () => {
  const workerRef = useRef(null);
  const [result, setResult] = useState([]);

  useEffect(() => {
    workerRef.current = new Worker(
      new URL("../workers/SearchORS.worker.js", import.meta.url)
    );

    workerRef.current.onmessage = (e) => {
      setResult(e.data);
    };

    return () => {
      workerRef.current.terminate();
    };
  }, []);

  const runOrsSearchWorker = (data) => {
    console.log("the data in runOrsSearchWorker",data)
    workerRef.current.postMessage(data);
  };

  const runOrsSortingWorker = (data) => {
    workerRef.current.postMessage(data);
  };

  return { result, runOrsSearchWorker, runOrsSortingWorker };
};
