import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M10.316 14.12C9.852 14.12 9.424 14.064 9.032 13.952C8.648 13.832 8.316 13.664 8.036 13.448C7.764 13.224 7.552 12.956 7.4 12.644C7.248 12.332 7.172 11.98 7.172 11.588C7.172 11.1 7.288 10.68 7.52 10.328C7.76 9.976 8.076 9.708 8.468 9.524C8.156 9.34 7.912 9.1 7.736 8.804C7.56 8.508 7.472 8.156 7.472 7.748C7.472 7.268 7.588 6.864 7.82 6.536C8.06 6.2 8.392 5.94 8.816 5.756C9.248 5.572 9.748 5.48 10.316 5.48C10.876 5.48 11.368 5.572 11.792 5.756C12.224 5.932 12.556 6.188 12.788 6.524C13.028 6.86 13.148 7.264 13.148 7.736C13.148 8.144 13.06 8.5 12.884 8.804C12.708 9.1 12.468 9.34 12.164 9.524C12.556 9.708 12.868 9.976 13.1 10.328C13.34 10.68 13.46 11.1 13.46 11.588C13.46 12.108 13.328 12.56 13.064 12.944C12.8 13.32 12.432 13.612 11.96 13.82C11.488 14.02 10.94 14.12 10.316 14.12ZM10.316 13.16C10.684 13.16 11.016 13.096 11.312 12.968C11.608 12.84 11.844 12.66 12.02 12.428C12.196 12.188 12.284 11.908 12.284 11.588C12.284 11.26 12.196 10.984 12.02 10.76C11.844 10.528 11.608 10.352 11.312 10.232C11.016 10.104 10.684 10.04 10.316 10.04C9.948 10.04 9.612 10.104 9.308 10.232C9.012 10.352 8.776 10.528 8.6 10.76C8.424 10.984 8.336 11.26 8.336 11.588C8.336 11.908 8.424 12.188 8.6 12.428C8.776 12.66 9.012 12.84 9.308 12.968C9.612 13.096 9.948 13.16 10.316 13.16ZM10.316 9.092C10.62 9.092 10.9 9.036 11.156 8.924C11.42 8.812 11.628 8.66 11.78 8.468C11.94 8.276 12.02 8.044 12.02 7.772C12.02 7.5 11.944 7.264 11.792 7.064C11.648 6.864 11.444 6.712 11.18 6.608C10.924 6.496 10.636 6.44 10.316 6.44C9.996 6.44 9.704 6.496 9.44 6.608C9.184 6.712 8.98 6.864 8.828 7.064C8.676 7.264 8.6 7.5 8.6 7.772C8.6 8.052 8.676 8.292 8.828 8.492C8.98 8.684 9.184 8.832 9.44 8.936C9.704 9.04 9.996 9.092 10.316 9.092Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M10.316 14.12C9.852 14.12 9.424 14.064 9.032 13.952C8.648 13.832 8.316 13.664 8.036 13.448C7.764 13.224 7.552 12.956 7.4 12.644C7.248 12.332 7.172 11.98 7.172 11.588C7.172 11.1 7.288 10.68 7.52 10.328C7.76 9.976 8.076 9.708 8.468 9.524C8.156 9.34 7.912 9.1 7.736 8.804C7.56 8.508 7.472 8.156 7.472 7.748C7.472 7.268 7.588 6.864 7.82 6.536C8.06 6.2 8.392 5.94 8.816 5.756C9.248 5.572 9.748 5.48 10.316 5.48C10.876 5.48 11.368 5.572 11.792 5.756C12.224 5.932 12.556 6.188 12.788 6.524C13.028 6.86 13.148 7.264 13.148 7.736C13.148 8.144 13.06 8.5 12.884 8.804C12.708 9.1 12.468 9.34 12.164 9.524C12.556 9.708 12.868 9.976 13.1 10.328C13.34 10.68 13.46 11.1 13.46 11.588C13.46 12.108 13.328 12.56 13.064 12.944C12.8 13.32 12.432 13.612 11.96 13.82C11.488 14.02 10.94 14.12 10.316 14.12ZM10.316 13.16C10.684 13.16 11.016 13.096 11.312 12.968C11.608 12.84 11.844 12.66 12.02 12.428C12.196 12.188 12.284 11.908 12.284 11.588C12.284 11.26 12.196 10.984 12.02 10.76C11.844 10.528 11.608 10.352 11.312 10.232C11.016 10.104 10.684 10.04 10.316 10.04C9.948 10.04 9.612 10.104 9.308 10.232C9.012 10.352 8.776 10.528 8.6 10.76C8.424 10.984 8.336 11.26 8.336 11.588C8.336 11.908 8.424 12.188 8.6 12.428C8.776 12.66 9.012 12.84 9.308 12.968C9.612 13.096 9.948 13.16 10.316 13.16ZM10.316 9.092C10.62 9.092 10.9 9.036 11.156 8.924C11.42 8.812 11.628 8.66 11.78 8.468C11.94 8.276 12.02 8.044 12.02 7.772C12.02 7.5 11.944 7.264 11.792 7.064C11.648 6.864 11.444 6.712 11.18 6.608C10.924 6.496 10.636 6.44 10.316 6.44C9.996 6.44 9.704 6.496 9.44 6.608C9.184 6.712 8.98 6.864 8.828 7.064C8.676 7.264 8.6 7.5 8.6 7.772C8.6 8.052 8.676 8.292 8.828 8.492C8.98 8.684 9.184 8.832 9.44 8.936C9.704 9.04 9.996 9.092 10.316 9.092Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" width="20" height="20" rx="4" fill="%230089D7"/><path d="M10.316 14.12C9.852 14.12 9.424 14.064 9.032 13.952C8.648 13.832 8.316 13.664 8.036 13.448C7.764 13.224 7.552 12.956 7.4 12.644C7.248 12.332 7.172 11.98 7.172 11.588C7.172 11.1 7.288 10.68 7.52 10.328C7.76 9.976 8.076 9.708 8.468 9.524C8.156 9.34 7.912 9.1 7.736 8.804C7.56 8.508 7.472 8.156 7.472 7.748C7.472 7.268 7.588 6.864 7.82 6.536C8.06 6.2 8.392 5.94 8.816 5.756C9.248 5.572 9.748 5.48 10.316 5.48C10.876 5.48 11.368 5.572 11.792 5.756C12.224 5.932 12.556 6.188 12.788 6.524C13.028 6.86 13.148 7.264 13.148 7.736C13.148 8.144 13.06 8.5 12.884 8.804C12.708 9.1 12.468 9.34 12.164 9.524C12.556 9.708 12.868 9.976 13.1 10.328C13.34 10.68 13.46 11.1 13.46 11.588C13.46 12.108 13.328 12.56 13.064 12.944C12.8 13.32 12.432 13.612 11.96 13.82C11.488 14.02 10.94 14.12 10.316 14.12ZM10.316 13.16C10.684 13.16 11.016 13.096 11.312 12.968C11.608 12.84 11.844 12.66 12.02 12.428C12.196 12.188 12.284 11.908 12.284 11.588C12.284 11.26 12.196 10.984 12.02 10.76C11.844 10.528 11.608 10.352 11.312 10.232C11.016 10.104 10.684 10.04 10.316 10.04C9.948 10.04 9.612 10.104 9.308 10.232C9.012 10.352 8.776 10.528 8.6 10.76C8.424 10.984 8.336 11.26 8.336 11.588C8.336 11.908 8.424 12.188 8.6 12.428C8.776 12.66 9.012 12.84 9.308 12.968C9.612 13.096 9.948 13.16 10.316 13.16ZM10.316 9.092C10.62 9.092 10.9 9.036 11.156 8.924C11.42 8.812 11.628 8.66 11.78 8.468C11.94 8.276 12.02 8.044 12.02 7.772C12.02 7.5 11.944 7.264 11.792 7.064C11.648 6.864 11.444 6.712 11.18 6.608C10.924 6.496 10.636 6.44 10.316 6.44C9.996 6.44 9.704 6.496 9.44 6.608C9.184 6.712 8.98 6.864 8.828 7.064C8.676 7.264 8.6 7.5 8.6 7.772C8.6 8.052 8.676 8.292 8.828 8.492C8.98 8.684 9.184 8.832 9.44 8.936C9.704 9.04 9.996 9.092 10.316 9.092Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const EightCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default EightCheck;
