import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  FormControl,
  Pagination,
  RadioGroup,
  TextField,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import { NCheck, OCheck, FCheck, CCheck } from "../../Common/CustomCheckbox";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const PhysicalTabs = ({
  physicalTabHeight,
  value,
  getFilterOccupationData,
}) => {
  const [physicalDemandData, setPhysicalDemandData] = useState([]);

  const physicalFormik = useFormik({
    initialValues: {
      physicalDemandLevel: "",
      feeling: "",
      balancing: "",
      talking: "",
      stooping: "",
      hearing: "",
      kneeling: "",
      tastingSmelling: "",
      crouching: "",
      nearAcuity: "",
      crawling: "",
      farAcquity: "",
      reaching: "",
      depthPerception: "",
      handling: "",
      accommodation: "",
      fingering: "",
      colorVision: "",
      notes: "",
    },
    onSubmit: async (data) => {
      getFilterOccupationData([
        {
          name: "Clerk, General",
          id: "456,382-1024",
          detailsLink: "/SOCDOTGroupInfo",
          detailsButtonText: "View Details",
          detailsButtonColor: "DetailsBtn",
          physical: "Medium",
          svp: "03",
          avgOES: "12,000",
          custom: "13,000",
        },
        {
          name: "Clerk, General",
          id: "456,382-1024",
          detailsLink: "/SOCDOTGroupInfo",
          detailsButtonText: "View Details",
          detailsButtonColor: "DetailsBtn",
          physical: "Medium",
          svp: "04",
          avgOES: "12,000",
          custom: "13,000",
        },
        {
          name: "Clerk, General",
          id: "456,382-1024",
          detailsLink: "/SOCDOTGroupInfo",
          detailsButtonText: "View Details",
          detailsButtonColor: "DetailsBtn",
          physical: "Medium",
          svp: "05",
          avgOES: "12,000",
          custom: "13,000",
        },
      ]);
    },
  });

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open("veToolkitDatabase", 31);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["DotPhysicalDemands"], "readonly");
      const objectStore = transaction.objectStore("DotPhysicalDemands");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setPhysicalDemandData(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("DOTPhysicalDemands", { autoIncrement: true });
    };
  }, []);

  // const radioOptions = [
  //   {
  //     label: "Feeling",
  //     name: "feeling",
  //   },
  //   {
  //     label: "Balancing",
  //     name: "balancing",
  //   },
  //   {
  //     label: "Talking",
  //     name: "talking",
  //   },
  //   {
  //     label: "Stooping",
  //     name: "stooping",
  //   },
  //   {
  //     label: "Hearing",
  //     name: "hearing",
  //   },
  //   {
  //     label: "Kneeling",
  //     name: "kneeling",
  //   },
  //   {
  //     label: "Tasting/Smelling",
  //     name: "tastingSmelling",
  //   },
  //   {
  //     label: "Crouching",
  //     name: "crouching",
  //   },
  //   {
  //     label: "Near Acuity",
  //     name: "nearAcuity",
  //   },
  //   {
  //     label: "Crawling",
  //     name: "crawling",
  //   },
  //   {
  //     label: "Far Acquity",
  //     name: "farAcquity",
  //   },
  //   {
  //     label: "Reaching",
  //     name: "reaching",
  //   },
  //   {
  //     label: "Depth Perception",
  //     name: "depthPerception",
  //   },
  //   {
  //     label: "Handling",
  //     name: "handling",
  //   },
  //   {
  //     label: "Accommodation",
  //     name: "accommodation",
  //   },
  //   {
  //     label: "Fingering",
  //     name: "fingering",
  //   },
  //   {
  //     label: "Color Vision",
  //     name: "colorVision",
  //   },
  // ];

  const handlePhysicalDemand = (level) => (e) => {
    e.preventDefault();
    physicalFormik.setFieldValue("physicalDemandLevel", level);
  };

  const physicalDemandLevels = [
    { label: "Sedentary", value: "Secondary" },
    { label: "Light", value: "Light" },
    { label: "Medium", value: "Medium" },
    { label: "Heavy", value: "Heavy" },
    { label: "Very Heavy", value: "Very Heavy" },
  ];

  return (
    <form onSubmit={physicalFormik.handleSubmit}>
      <TabPanel value={value} index={0}>
        <Stack
          direction={"row"}
          my={1}
          gap={1}
          alignItems={"center"}
          sx={{
            "& *": {
              fontSize: "12px",
            },
          }}
        >
          <Box flexGrow={1}>
            <Typography
              variant="p"
              component={"p"}
              fontSize={"14px !important"}
            >
              Physical Demand Level
            </Typography>
          </Box>

          <Box>
            <Grid
              container
              rowGap={2}
              sx={{
                marginTop: "10px",
              }}
            >
              {physicalDemandLevels.map((level, index) => (
                <Grid item key={index}>
                  <Button
                    style={{
                      marginRight: "15px",
                      border: "1px solid #B8B8BE",
                      backgroundColor: "#FFFFFF",
                    }}
                    color="greyPrimary"
                    variant="contained"
                    size="small"
                    onClick={() => handlePhysicalDemand(level.value)}
                  >
                    {level.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledSecondary(e)}
              >
                Sedentary
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledLight(e)}
              >
                Light
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledMedium(e)}
              >
                Medium
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledHeavy(e)}
              >
                Heavy
              </Button>
            </Box>

            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledVeryHeavy(e)}
              >
                Very Heavy
              </Button>
            </Box> */}
        </Stack>
        <Box
          sx={{
            height: `${physicalTabHeight}px`,
            overflow: "scroll",
          }}
        >
          <Grid
            container
            spacing={"15px"}
            marginTop={"10px"}
            sx={{
              "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                borderRight: "1px solid #666672",
              },
            }}
          >
            {physicalDemandData?.slice(0, 19)?.map((option, index) => (
              <Grid item xs={6} key={index}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  paddingRight={"15px"}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "#E6F3FB66" },
                  }}
                >
                  <Typography variant="p">{option.Name}</Typography>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormControl>
                      <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                        {["N", "O", "F", "C"]?.map((radioValue, index) => (
                          <React.Fragment key={index}>
                            {getRadioButton(
                              radioValue,
                              option.Name,
                              physicalFormik
                            )}
                          </React.Fragment>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Stack
          direction={"row"}
          spacing={"10px"}
          marginTop={"20px"}
          alignItems={"flex-end"}
        >
          <Box flexGrow={1}>
            <TextField
              variant="filled"
              multiline
              rows={1}
              maxRows={4}
              fullWidth
              placeholder="Notes"
              name="notes"
              value={physicalFormik.values.notes}
              onChange={physicalFormik.handleChange}
              sx={{
                "& .MuiInputBase-multiline": {
                  padding: "10px 12px 8px",
                },
              }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              size="large"
              startIcon={<SearchIcon />}
              type="submit"
            >
              Search
            </Button>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"space-between"}
          className="HypotheticalPagination"
          mt={"10px"}
          sx={{ borderTop: "1px solid #666672", paddingTop: "10px" }}
        >
          <Pagination count={4} color="primary" />
          <Stack direction={"row"} spacing={2}>
            <Button variant="outlined" color="error" startIcon={<DeleteIcon />}>
              Delete
            </Button>
            <Button variant="outlined" startIcon={<DuplicateIcon />}>
            Duplicate
            </Button>
            <Button variant="contained" startIcon={<AddIcon />}>
              Add New
            </Button>
          </Stack>
        </Stack>
      </TabPanel>
    </form>
  );
};

// Function to render radio button based on value
const getRadioButton = (value, name, formik) => {
  switch (value) {
    case "N":
      return (
        <NCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "O":
      return (
        <OCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "F":
      return (
        <FCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    case "C":
      return (
        <CCheck
          sx={{ paddingY: "2px", paddingX: "5px" }}
          name={name}
          value={value}
          checked={formik.values[name] === value}
          onChange={formik.handleChange}
        />
      );
    default:
      return null;
  }
};

export default PhysicalTabs;
