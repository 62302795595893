import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M10.312 14C10.232 14 10.164 13.976 10.108 13.928C10.06 13.872 10.036 13.804 10.036 13.724V7.004L8.044 8.54C7.98 8.588 7.912 8.608 7.84 8.6C7.768 8.592 7.708 8.556 7.66 8.492L7.348 8.096C7.3 8.024 7.28 7.952 7.288 7.88C7.304 7.808 7.344 7.748 7.408 7.7L10.024 5.684C10.08 5.644 10.132 5.62 10.18 5.612C10.228 5.604 10.28 5.6 10.336 5.6H10.948C11.028 5.6 11.092 5.628 11.14 5.684C11.188 5.732 11.212 5.796 11.212 5.876V13.724C11.212 13.804 11.188 13.872 11.14 13.928C11.092 13.976 11.028 14 10.948 14H10.312Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M10.312 14C10.232 14 10.164 13.976 10.108 13.928C10.06 13.872 10.036 13.804 10.036 13.724V7.004L8.044 8.54C7.98 8.588 7.912 8.608 7.84 8.6C7.768 8.592 7.708 8.556 7.66 8.492L7.348 8.096C7.3 8.024 7.28 7.952 7.288 7.88C7.304 7.808 7.344 7.748 7.408 7.7L10.024 5.684C10.08 5.644 10.132 5.62 10.18 5.612C10.228 5.604 10.28 5.6 10.336 5.6H10.948C11.028 5.6 11.092 5.628 11.14 5.684C11.188 5.732 11.212 5.796 11.212 5.876V13.724C11.212 13.804 11.188 13.872 11.14 13.928C11.092 13.976 11.028 14 10.948 14H10.312Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M10.312 14C10.232 14 10.164 13.976 10.108 13.928C10.06 13.872 10.036 13.804 10.036 13.724V7.004L8.044 8.54C7.98 8.588 7.912 8.608 7.84 8.6C7.768 8.592 7.708 8.556 7.66 8.492L7.348 8.096C7.3 8.024 7.28 7.952 7.288 7.88C7.304 7.808 7.344 7.748 7.408 7.7L10.024 5.684C10.08 5.644 10.132 5.62 10.18 5.612C10.228 5.604 10.28 5.6 10.336 5.6H10.948C11.028 5.6 11.092 5.628 11.14 5.684C11.188 5.732 11.212 5.796 11.212 5.876V13.724C11.212 13.804 11.188 13.872 11.14 13.928C11.092 13.976 11.028 14 10.948 14H10.312Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const OneCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default OneCheck;
