import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M7.32 14C7.232 14 7.164 13.976 7.116 13.928C7.068 13.872 7.044 13.804 7.044 13.724V5.888C7.044 5.8 7.068 5.732 7.116 5.684C7.164 5.628 7.232 5.6 7.32 5.6H12.216C12.304 5.6 12.372 5.628 12.42 5.684C12.468 5.732 12.492 5.8 12.492 5.888V6.368C12.492 6.456 12.468 6.524 12.42 6.572C12.372 6.62 12.304 6.644 12.216 6.644H8.208V9.44H11.976C12.064 9.44 12.132 9.468 12.18 9.524C12.228 9.572 12.252 9.64 12.252 9.728V10.208C12.252 10.288 12.228 10.356 12.18 10.412C12.132 10.46 12.064 10.484 11.976 10.484H8.208V13.724C8.208 13.804 8.18 13.872 8.124 13.928C8.076 13.976 8.008 14 7.92 14H7.32Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M7.32 14C7.232 14 7.164 13.976 7.116 13.928C7.068 13.872 7.044 13.804 7.044 13.724V5.888C7.044 5.8 7.068 5.732 7.116 5.684C7.164 5.628 7.232 5.6 7.32 5.6H12.216C12.304 5.6 12.372 5.628 12.42 5.684C12.468 5.732 12.492 5.8 12.492 5.888V6.368C12.492 6.456 12.468 6.524 12.42 6.572C12.372 6.62 12.304 6.644 12.216 6.644H8.208V9.44H11.976C12.064 9.44 12.132 9.468 12.18 9.524C12.228 9.572 12.252 9.64 12.252 9.728V10.208C12.252 10.288 12.228 10.356 12.18 10.412C12.132 10.46 12.064 10.484 11.976 10.484H8.208V13.724C8.208 13.804 8.18 13.872 8.124 13.928C8.076 13.976 8.008 14 7.92 14H7.32Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
 "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M7.32 14C7.232 14 7.164 13.976 7.116 13.928C7.068 13.872 7.044 13.804 7.044 13.724V5.888C7.044 5.8 7.068 5.732 7.116 5.684C7.164 5.628 7.232 5.6 7.32 5.6H12.216C12.304 5.6 12.372 5.628 12.42 5.684C12.468 5.732 12.492 5.8 12.492 5.888V6.368C12.492 6.456 12.468 6.524 12.42 6.572C12.372 6.62 12.304 6.644 12.216 6.644H8.208V9.44H11.976C12.064 9.44 12.132 9.468 12.18 9.524C12.228 9.572 12.252 9.64 12.252 9.728V10.208C12.252 10.288 12.228 10.356 12.18 10.412C12.132 10.46 12.064 10.484 11.976 10.484H8.208V13.724C8.208 13.804 8.18 13.872 8.124 13.928C8.076 13.976 8.008 14 7.92 14H7.32Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const FCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default FCheck;
