import {
  Box,
  Container,
  Stack,
  TextField,
  Grid,
  Button,
  IconButton,
  Typography,
  SvgIcon,
  Tab,
  FormControl,
  Pagination,
  Tabs,
  Checkbox,
  InputLabel,
  RadioGroup,
  CircularProgress,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../assets/images/icons/search-icon.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/images/icons/right-arrow-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/delet-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/images/icons/add-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/images/icons/duplicate-icon.svg";
import React, { useEffect, useRef, useState } from "react";
import {
  NCheck,
  OCheck,
  FCheck,
  CCheck,
  OneCheck,
  TwOCheck,
  ThreeCheck,
  FourCheck,
  FiveCheck,
  SixCheck,
} from "../../Common/CustomCheckbox";
import { Link, useNavigate } from "react-router-dom";
import PhysicalTabs from "./PhysicalTabs";
import CognitiveTabs from "./CognitiveTabs";
import EnvironmentalTabs from "./EnvironmentalTabs";
import SortByDialog from "./SortByDialog";
import { useFormik } from "formik";
import { handleGetRequest } from "../../services/GetTemplate";
import AnalyzerSorting from "./helperComponents/analyzerSorting";
import AnalyzerFilters from "./helperComponents/analyzerFilter";
import {
  getCheckBox,
  getCheckBoxCognitive,
  getCheckBoxEnvironmental,
  getCheckBoxEnvironmentalMultilevel,
  joinArraysOnDotcode,
  onAddNewFilters,
  physicalDemandLevels,
  sortAsc,
  sortDesc,
  transformFiltersToSave,
  transformSubmissionData,
} from "./utils";
import { searchDOTfilters } from "../../services/postDOTSearchFilters";
import { postDOTfilters } from "../../services/postDOTFilters";
import { handleDeleteRequest } from "../../services/DeleteTemplate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HypotheticalAnalyzer = () => {
  const localStorageNotes = localStorage.getItem("notes");
  const localStoragepaginatedValue = sessionStorage.getItem("paginatedValue");

  const [occupationsHeight, setOccupationsHeight] = useState();
  const [physicalTabHeight, setPhysicalTabHeight] = useState();
  const [physicalTab0Height, setPhysicalTab0Height] = useState();
  const [value, setValue] = useState(0);
  const [filtersSorting, setFiltersSorting] = useState({
    sorting: 1,
    filters: "",
    searchText: "",
  });

  const [occupationData, setOccupationData] = useState([]);
  const [filterOccupationData, setfilterOccupationData] = useState([]);
  //allfilteredOccupations are use to store the result of all filters
  const [allFilteredOccupations, setAllFilteredOccupations] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [oewsNationalData, setOewsNationalData] = useState([]);
  const [jobCountBySoc, setJobCountBySoc] = useState(0);
  const [activeButtonPhysical, setActiveButtonPhysical] = useState();
  const [DOTTableData, setDOTTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(
    Number(localStoragepaginatedValue)
  );
  const [isloading, setLoading] = useState(false);
  const [isDeleteloading, setDeleteLoading] = useState(false);
  const [isAddNewloading, setAddNewLoading] = useState(false);
  const [notes, setNotes] = useState(localStorageNotes);
  const [loadData, setLoadData] = useState(false);
  const [selectedFilteredOccupationsCode, setSelectedFilteredOccupationsCode] =
    useState([]);
  const [physicalDemandData, setPhysicalDemandData] = useState([]);
  const [environmentalDemandData, setEnvironmentalDemandData] = useState([]);
  const [cognitiveDemandData, setCognitiveDemandData] = useState([]);
  const [movePaginationToLast, setMovePaginationToLast] = useState(false);
  const [updateLoadStorage, setUpdateLoadStorage] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);

  const textareaRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [notes]);

  useEffect(() => {
    if (!updateLoadStorage) return;
    // debugger
    // const updatedData = transformSubmissionData(
    //   formik.values,
    //   physicalDemandData,
    //   cognitiveDemandData,
    //   environmentalDemandData
    // );

    const data = DOTTableData;
    data.orsFilters[currentPage - 1].filters.DotFilters = formik.values;
    localStorage.setItem("hypotheticalFilters", JSON.stringify(data));

    setUpdateLoadStorage(false);
  }, [updateLoadStorage]);

  useEffect(() => {
    if (loadData) {
      setLoadData(false);
      getTableData();
    }
  }, [loadData]);

  useEffect(() => {
    const localStorageDotFilters = JSON.parse(
      sessionStorage.getItem("DOTFiltersData")
    );

    const localHypothicalAnalyzer = JSON.parse(
      localStorage.getItem("hypotheticalFilters")
    );

    setTimeout(() => {
      if (localStorageDotFilters) {
        const results = localStorageDotFilters.results;
        setAppliedFilters(
          localStorageDotFilters.filters ? localStorageDotFilters.filters : []
        );
        setDisabledSaveButton(true);
        setOccupationData(
          localStorageDotFilters.results ? localStorageDotFilters.results : []
        );
      } else if (localHypothicalAnalyzer) {
        const filters = localHypothicalAnalyzer.orsFilters[currentPage - 1];
        if (!filters) return;
        const filtersData = filters.filters.DotFilters;
        setAppliedFilters(filtersData);
        setDisabledSaveButton(true);
        setOccupationData(filters.results.DotResults);
      }
    }, [1000]);
  }, []);

  useEffect(() => {
    if (
      !!physicalDemandData?.length &&
      !!cognitiveDemandData?.length &&
      !!environmentalDemandData?.length
    ) {
      // debugger
      const newValues = generateInitialValues(
        physicalDemandData,
        cognitiveDemandData,
        environmentalDemandData,
        appliedFilters
      );
      formik.setValues(newValues);
    }
  }, [
    physicalDemandData,
    environmentalDemandData,
    cognitiveDemandData,
    appliedFilters,
  ]);

  useEffect(() => {
    const updateHeight = () => {
      const innerHeight = window.innerHeight;
      setOccupationsHeight(innerHeight - 175 - 47);
      setPhysicalTabHeight(innerHeight - 364);
      setPhysicalTab0Height(innerHeight - 422);
    };
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [window.innerHeight]); // eslint-disable-line

  useEffect(() => {
    /**
     * On filterSorting (contains filters, search, and sorting order) changes
     * filter all the data
     */
    let filteredArray = allFilteredOccupations || [];
    if (!!filtersSorting.searchText || filtersSorting.searchText === "") {
      filteredArray = filteredArray.filter(
        (obj) =>
          obj.Title &&
          obj.Title.toLowerCase().includes(
            filtersSorting.searchText.toLowerCase()
          )
      );
      setfilterOccupationData(filteredArray);
    }
    if (!!filtersSorting.filters) {
      const keys = ["customemployment", "avgOES", "SVP", "PhysicalDemands"];
      const keyToSortBy = keys[filtersSorting.filters - 1];
      filteredArray =
        !!filtersSorting.sorting && filtersSorting.sorting === 2
          ? (filteredArray = sortDesc(filteredArray, keyToSortBy))
          : (filteredArray = sortAsc(filteredArray, keyToSortBy));
    }
    setfilterOccupationData(filteredArray ? [...filteredArray] : []);
  }, [filtersSorting]);

  useEffect(() => {
    //  getTableData()
    setfilterOccupationData(occupationData);
    setAllFilteredOccupations(occupationData);
    setFiltersSorting({
      sorting: 1,
      filters: "",
      searchText: "",
    });
  }, [occupationData]);

  var dbName = "veToolkitDB";
  var dbCurrentVersion;

  useEffect(() => {
    var openRequestver = window.indexedDB.open(dbName);

    openRequestver.onsuccess = function (event) {
      var dbver = event.target.result;
      var currentVersion = dbver.version;
      dbCurrentVersion = currentVersion;
      // console.log("Current IndexedDB version:", currentVersion);

      // Perform other operations as needed
    };

    openRequestver.onerror = function (event) {
      console.error("Error opening database:", event.target.error);
    };

    // Cleanup function
    return () => {
      openRequestver?.result?.close();
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTableData = async () => {
    const profileId = Number(localStorage.getItem("profileId"));
    const response = await handleGetRequest(`filter?profileId=${profileId}`);
    // have to map this values to showing data
    if (response?.data) {
      if (!response?.data?.count) {
        sessionStorage.removeItem("paginatedValue");
        sessionStorage.removeItem("selectedFiltersId");
      }

      localStorage.setItem(
        "hypotheticalFilters",
        JSON.stringify(response?.data)
      );
      setDOTTableData(response?.data);

      if (movePaginationToLast || currentPage === 0) {
        const filters = response?.data?.orsFilters;
        setCurrentPage(filters.length);
        sessionStorage.setItem("paginatedValue", filters.length);
        sessionStorage.setItem(
          "selectedFiltersId",
          filters[filters.length - 1]?.id
        );
        setOccupationData(filters[filters.length - 1]?.results?.DotResults);
      }
    }
    setMovePaginationToLast(false);
    setAddNewLoading(false);
    setDeleteLoading(false);
  };

  const onAddNew = async () => {
    setAddNewLoading(true);
    await onAddNewFilters();

    setAppliedFilters({});
    setDisabledSaveButton(true);
    setActiveButtonPhysical("");
    sessionStorage.removeItem("paginatedValue");
    sessionStorage.removeItem("selectedFiltersId");

    sessionStorage.removeItem("DOTFiltersData");
    sessionStorage.removeItem("ORSFiltersData");
    localStorage.removeItem("notes");
    setNotes("");
    
    await onAddNewFilters();
    setMovePaginationToLast(true);
    setLoadData(true);
  };

  const onDuplicate = async () => {
    localStorage.setItem("duplicate", true);
    setAddNewLoading(true);
    await onAddNewFilters();

    sessionStorage.removeItem("paginatedValue");
    sessionStorage.removeItem("selectedFiltersId");

    await onAddNewFilters();
    setMovePaginationToLast(true);
    setLoadData(true);
    setAddNewLoading(false);
  };

  const onSave = async () => {
    setAddNewLoading(true);
    await onAddNewFilters();
    setLoadData(true);
  };

  const onDelete = async () => {
    setDeleteLoading(true);
    const asd = DOTTableData?.orsFilters[currentPage - 1];
    const profileId = Number(localStorage.getItem("profileId"));

    if (asd.id) {
      await handleDeleteRequest(
        `filter?filterId=${asd.id}&profileId=${profileId}`
      );
      DOTTableData?.orsFilters.splice(currentPage - 1, 1);
      const data = {
        count: DOTTableData?.count - 1,
        orsFilters: DOTTableData?.orsFilters,
      };

      localStorage.setItem("hypotheticalFilters", JSON.stringify(data));
      onPaginationChange({}, 1);
    }
    setLoadData(true);
  };

  const onPaginationChange = (e, value) => {
    getTableData();
    const filters = DOTTableData?.orsFilters[value - 1];
    const filtersData = filters?.filters.DotFilters;
    const notes = filters.filters.notes;

    setNotes(notes);
    localStorage.setItem("notes", notes);

    setAppliedFilters(filtersData);
    setDisabledSaveButton(true);
    setOccupationData(
      filters?.results?.DotResults ? filters?.results?.DotResults : []
    );
    // handleSearch(transformedData, filtersData, value);
    setCurrentPage(value);
    onSaveResultInLocalStorage(
      { ...filtersData },
      filters?.results?.DotResults,
      value
    );
    // saved selected Strength
    if (filtersData.Strength) {
      handlePhysicalDemand(null, filtersData.Strength);
    }
    sessionStorage.setItem("selectedFiltersId", filters?.id);
    sessionStorage.setItem(
      "ORSResults",
      JSON.stringify(
        filters?.results?.OrsResults ? filters?.results?.OrsResults : []
      )
    );
  };

  // console.log("OesCodeDefination", OesCodeDefinationData);

  // console.log("EnvironmentalDemandsDataEnvironmentalDemandsData",EnvironmentalDemandsData)

  const generateInitialValues = (
    physicalData,
    aptitudesData,
    environmentalData,
    appliedFilters
  ) => {
    const initialValues = {};

    if (!appliedFilters) appliedFilters = {};
    const keys = Object.keys(appliedFilters);

    physicalData?.forEach((item) => {
      initialValues[item.Name] = keys?.includes(item.Name)
        ? appliedFilters[item.Name]
        : "";
    });

    aptitudesData?.forEach((item) => {
      initialValues[item.Description] = keys?.includes(item.Description)
        ? appliedFilters[item.Description]
        : "";
    });

    environmentalData?.forEach((item) => {
      initialValues[item.Description] = keys?.includes(item.Description)
        ? appliedFilters[item.Description]
        : "";
    });

    if (appliedFilters?.SVP) {
      initialValues["SVP"] = appliedFilters.SVP;
    }

    return initialValues;
  };

  const formik = useFormik({
    initialValues: generateInitialValues(
      physicalDemandData,
      cognitiveDemandData,
      environmentalDemandData,
      appliedFilters
    ),
    onSubmit: async (data) => {
      const updatedData = transformSubmissionData(
        data,
        physicalDemandData,
        cognitiveDemandData,
        environmentalDemandData
      );
      setAppliedFilters(data);
      setDisabledSaveButton(true);
      // console.log("updatedData", updatedData);
      await handleSearch(updatedData, data);
    },
  });

  useEffect(() => {
    if (
      !!physicalDemandData.length &&
      !!cognitiveDemandData.length &&
      !!environmentalDemandData.length
    ) {
      const newValues = generateInitialValues(
        physicalDemandData,
        cognitiveDemandData,
        environmentalDemandData,
        appliedFilters
      );
      formik.setValues(newValues);
    }
  }, [currentPage]);

  const onSaveResultInLocalStorage = (filters, results, value) => {
    let obj = {
      filters,
      results,
    };

    sessionStorage.removeItem("DOTFiltersData");
    sessionStorage.setItem("DOTFiltersData", JSON.stringify(obj));

    if (value) {
      sessionStorage.setItem("paginatedValue", value);
      const data = DOTTableData.orsFilters[value - 1];
      obj = {
        filters: data?.filters?.OrsFilters,
        results: data?.results?.OrsResults,
      };

      sessionStorage.setItem("ORSFiltersData", JSON.stringify(obj));
    }
  };

  const handleSearch = async (newData, filtersData, value) => {
    setLoading(true);
    const rep = await searchDOTfilters(newData);

    let dotTitles = rep?.data?.dotTitles;
    dotTitles = dotTitles?.map((item) => {
      return {
        ...item,
        avgOES: getAvgOes(item["2018SocCode"]),
      };
    });
    onSaveResultInLocalStorage({ ...filtersData }, dotTitles, value);
    onSave();

    setfilterOccupationData(dotTitles);
    setAllFilteredOccupations(dotTitles);
    setLoading(false);
  };

  useEffect(() => {
    const hypotheticalFilters = localStorage.getItem("hypotheticalFilters");
    if (hypotheticalFilters) {
      const data = JSON.parse(hypotheticalFilters);
      // setActiveButtonPhysical(data?.orsFilters[currentPage?currentPage - 1:0]?.filters?.DotFilters?.Strength||"")
      setDOTTableData(data);
    }
  }, []); // eslint-disable-line
  // var DOTTableData = JSON.parse(localStorage.getItem("DOTTable"));

  useEffect(() => {
    // Open IndexedDB database
    const request = window.indexedDB.open(dbName, dbCurrentVersion);

    request.onerror = (event) => {
      console.error("Error opening database", event.target.error);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Start a transaction to access the object store
      const transaction = db.transaction(["OewsNationalM2022"], "readonly");
      const objectStore = transaction.objectStore("OewsNationalM2022");

      // Retrieve data from the object store
      const getData = objectStore.getAll();

      getData.onsuccess = () => {
        // Update state with the retrieved data
        setOewsNationalData(getData.result);
      };

      getData.onerror = (event) => {
        console.error("Error retrieving data", event.target.error);
      };
    };

    request.onupgradeneeded = (event) => {
      // Handle database upgrade if needed
      const db = event.target.result;
      db.createObjectStore("OewsNationalM2022", { autoIncrement: true });
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const PhysicalDemandsData = JSON.parse(
      localStorage.getItem("PhysicalDemands")
    );
    if (!PhysicalDemandsData) {
      fetch("DotPhysicalDemands.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPhysicalDemandData(data);
        return;
      });
    } else {
      setPhysicalDemandData(PhysicalDemandsData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const EnvironmentalDemandsData = JSON.parse(
      localStorage.getItem("EnvironmentalDemands")
    );
    if (!EnvironmentalDemandsData) {
      fetch("EnvironmentalDemands.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setEnvironmentalDemandData(data);
        return;
      });
    } else {
      setEnvironmentalDemandData(EnvironmentalDemandsData);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const AptitudesData = JSON.parse(localStorage.getItem("Aptitudes"));
    if (!AptitudesData)
      fetch("aptitudes.json").then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCognitiveDemandData(data);
        return;
      });
    else {
      setCognitiveDemandData(AptitudesData);
    }
  }, []); // eslint-disable-line

  const handlePhysicalDemand = (e, level) => {
    e?.preventDefault();
    setActiveButtonPhysical(level);
    formik.setFieldValue("Strength", level);
  };

  // console.log("oewsNationalData", oewsNationalData, physicalDemandData);

  const handledViewDetails = (item) => {
    navigate("/HypotheticalToolDOTExpandedView", { state: item });
  };

  //faisal code for avg oes

  const getNumberOfJobBySoc2018 = async (socCode) => {
    try {
      const request = await window.indexedDB.open(dbName, dbCurrentVersion);

      request.onerror = (event) => {
        console.error("Error opening database", event.target.error);
      };

      request.onsuccess = (event) => {
        const db = event.target.result;

        // Start a transaction to access the object store
        const transaction = db.transaction(["DOTMasterTable"], "readonly");
        const objectStore = transaction.objectStore("DOTMasterTable");

        // Retrieve data from the object store
        const cursorRequest = objectStore.openCursor();
        const results = [];

        cursorRequest.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const value = cursor.value;

            const filterationValues = {
              "2018SocCode": socCode,
            };

            const isMatch = Object.entries(filterationValues).every(
              ([key, expectedValue]) => {
                // console.log("key data", value[key]);
                // console.log("expectedValue", expectedValue);
                return value[key] === expectedValue;
              }
            );

            // console.log("isMatch", isMatch);

            if (isMatch) {
              results.push(value);
            }

            cursor.continue();
          } else {
            // console.log("End of cursor");
            setJobCountBySoc(results.length);
          }
        };

        cursorRequest.onerror = (event) => {
          console.error("Cursor error:", event.target.error);
        };
      };
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAvgOes = (socCode) => {
    const filtered = oewsNationalData?.find((oews) => oews.OccCode === socCode);
    if (!filtered) return "-";

    return filtered.A_Mean;
  };

  const handleResultToggle = (item) => {
    const arr = selectedFilteredOccupationsCode;

    const value = item["2018SocCode"];
    if (!item.isChecked) {
      // Add value if it doesn't exist
      if (!arr.includes(value)) {
        arr.push(value);
      }
    } else {
      // Remove value if it exists
      const index = arr.indexOf(value);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    setSelectedFilteredOccupationsCode([...arr]);
    setAllFilteredOccupations((prevItems) => {
      const updatedState = prevItems.map((prevItem) =>
        prevItem.id === item.id
          ? { ...prevItem, isChecked: !prevItem.isChecked }
          : prevItem
      );
      const sortedState = updatedState.sort((a, b) => {
        if (a.isChecked === b.isChecked) {
          return 0;
        } else if (a.isChecked === true) {
          return -1;
        } else if (b.isChecked === true) {
          return 1;
        } else if (a.isChecked === false) {
          return -1;
        } else if (b.isChecked === false) {
          return 1;
        } else {
          return 0;
        }
      });
      onSaveResultInLocalStorage(appliedFilters, sortedState);
      return sortedState;
    });
    setfilterOccupationData((prevItems) => {
      const updatedState = prevItems.map((prevItem) =>
        prevItem.id === item.id
          ? { ...prevItem, isChecked: !prevItem.isChecked }
          : prevItem
      );
      const sortedState = updatedState.sort((a, b) => {
        if (a.isChecked === b.isChecked) {
          return 0;
        } else if (a.isChecked === true) {
          return -1;
        } else if (b.isChecked === true) {
          return 1;
        } else if (a.isChecked === false) {
          return -1;
        } else if (b.isChecked === false) {
          return 1;
        } else {
          return 0;
        }
      });
      // putFiltersResult(selectedEstimateCode, sortedState);
      onSaveResultInLocalStorage(appliedFilters, sortedState);
      return sortedState;
    });
    setDisabledSaveButton(false);
  };

  const handleFilterChange = (event) => {
    formik.handleChange(event);
    setUpdateLoadStorage(true);
  };

  // useEffect(() => {
  //   const data = DOTTableData;
  //   if (!data || !data.count) return;
  //   console.log("formik.values ===>", formik.values);
  //   const updatedData = transformSubmissionData(
  //     formik.values,
  //     physicalDemandData,
  //     cognitiveDemandData,
  //     environmentalDemandData
  //   );
  //   data.orsFilters[currentPage -1].filters.DotFilters = updatedData;
  //   localStorage.setItem(
  //     "hypotheticalFilters",
  //     JSON.stringify(data)
  //   );
  //   setDOTTableData(data);
  // }, [formik.values]);
  return (
    <>
      <Container
        maxWidth={false}
        sx={{ paddingTop: "80px", paddingBottom: 2, px: { xs: "10px" } }}
      >
        <Grid container spacing={"10px"}>
          <Grid item xs={6}>
            <Box className="bg-white" padding={2} borderRadius={"10px"}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Typography variant="h6">Filter</Typography>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="none"
                    sx={{
                      "& .MuiTabs-flexContainer": {
                        justifyContent: "flex-end",
                        gap: 2,
                      },
                    }}
                  >
                    <Tab
                      label="Physical"
                      {...a11yProps(0)}
                      sx={{
                        color:
                          value === 0
                            ? "#ffffff !important"
                            : "#666672 !important",
                        backgroundColor: value === 0 ? "#0089D7" : "#ffffff",
                        minHeight: "20px",
                        paddingX: "10px",
                        paddingY: "8px",
                        alignItems: "end",
                        border: value === 0 ? "none" : "1px solid #B8B8BE",
                      }}
                    />
                    <Tab
                      label="Cognitive"
                      {...a11yProps(1)}
                      sx={{
                        color:
                          value === 1
                            ? "#ffffff !important"
                            : "#666672 !important",
                        backgroundColor: value === 1 ? "#0089D7" : "#ffffff",
                        minHeight: "20px",
                        paddingX: "10px",
                        paddingY: "8px",
                        alignItems: "end",
                        border: value === 1 ? "none" : "1px solid #B8B8BE",
                      }}
                    />
                    <Tab
                      label="Environmental"
                      {...a11yProps(2)}
                      sx={{
                        color:
                          value === 2
                            ? "#ffffff !important"
                            : "#666672 !important",
                        backgroundColor: value === 2 ? "#0089D7" : "#ffffff",
                        minHeight: "20px",
                        paddingX: "15px",
                        paddingY: "10px",
                        alignItems: "end",
                        border: value === 2 ? "none" : "1px solid #B8B8BE",
                      }}
                    />
                  </Tabs>
                </Box>
              </Stack>
              {/* physical Tab Component */}
              {/* <PhysicalTabs {...physicalProps} /> */}
              <form onSubmit={formik.handleSubmit}>
                <TabPanel value={value} index={0}>
                  <Stack
                    direction={"row"}
                    my={1}
                    gap={1}
                    alignItems={"center"}
                    sx={{
                      "& *": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    <Box flexGrow={1}>
                      <Typography
                        variant="p"
                        component={"p"}
                        fontSize={"14px !important"}
                      >
                        Physical Demand Level
                      </Typography>
                    </Box>

                    <Box>
                      <Grid
                        container
                        rowGap={2}
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        {physicalDemandLevels.map((level, index) => (
                          <Grid item key={index}>
                            <Button
                              style={{
                                marginRight: "15px",
                                border: "1px solid #B8B8BE",
                                backgroundColor: "#FFFFFF",
                              }}
                              color="greyPrimary"
                              variant="contained"
                              className={
                                activeButtonPhysical === level.value
                                  ? "active"
                                  : ""
                              }
                              size="small"
                              onClick={(e) =>
                                handlePhysicalDemand(e, level.value)
                              }
                            >
                              {level.label}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    {/* <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledSecondary(e)}
              >
                Sedentary
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledLight(e)}
              >
                Light
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledMedium(e)}
              >
                Medium
              </Button>
            </Box>
            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledHeavy(e)}
              >
                Heavy
              </Button>
            </Box>

            <Box>
              <Button
                color="greyPrimary"
                variant="contained"
                size="small"
                onClick={(e) => handledVeryHeavy(e)}
              >
                Very Heavy
              </Button>
            </Box> */}
                  </Stack>
                  <Box
                    sx={{
                      height: `${physicalTab0Height}px`,
                      overflow: "auto",
                    }}
                  >
                    <Grid
                      container
                      spacing={"15px"}
                      marginTop={"10px"}
                      sx={{
                        "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                          borderRight: "1px solid #666672",
                        },
                      }}
                    >
                      {physicalDemandData?.map((option, index) => (
                        <Grid item xs={6} key={index}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            paddingRight={"15px"}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              // "&:hover": { backgroundColor: "#E6F3FB66" },
                            }}
                          >
                            <Typography variant="p">{option.Name}</Typography>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <FormControl>
                                {/* <RadioGroup row sx={{ flexWrap: "nowrap" }}> */}
                                <Stack direction={"row"}>
                                  {["N", "O", "F", "C"]?.map(
                                    (radioValue, index) => (
                                      <React.Fragment key={index}>
                                        {getCheckBox(
                                          radioValue,
                                          option.Name,
                                          formik,
                                          handleFilterChange
                                        )}
                                      </React.Fragment>
                                    )
                                  )}
                                </Stack>
                                {/* </RadioGroup> */}
                              </FormControl>
                            </Stack>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </TabPanel>

                {/* <CognitiveTabs {...cognitiveProps} /> */}
                <TabPanel value={value} index={1}>
                  <Box
                    sx={{
                      height: `${physicalTabHeight}px`,
                      overflow: "auto",
                    }}
                  >
                    <Grid item xs={6} key={-1}>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        paddingRight={"15px"}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          // "&:hover": { backgroundColor: "#E6F3FB66" },
                        }}
                      >
                        <Typography variant="p">SVP</Typography>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <FormControl>
                            <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                              {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                                (radioValue, index) => (
                                  <React.Fragment key={index}>
                                    {getCheckBoxCognitive(
                                      radioValue,
                                      "SVP",
                                      // option.Description,
                                      formik,
                                      handleFilterChange
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid
                      container
                      spacing={"15px"}
                      marginTop={"10px"}
                      // sx={{}}
                      sx={{
                        "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                          borderRight: "1px solid #666672",
                        },
                      }}
                    >
                      {cognitiveDemandData?.map(
                        (
                          option,
                          index // it is cognitiveDemandData
                        ) => (
                          <Grid item xs={6} key={index}>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              paddingRight={"15px"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                // "&:hover": { backgroundColor: "#E6F3FB66" },
                              }}
                            >
                              <Typography variant="p">
                                {option.Description}
                              </Typography>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <FormControl>
                                  <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                                    {["1", "2", "3", "4", "5", "6"].map(
                                      (radioValue, index) => (
                                        <React.Fragment key={index}>
                                          {getCheckBoxCognitive(
                                            radioValue,
                                            option.Description,
                                            formik,
                                            handleFilterChange
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Stack>
                            </Stack>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                </TabPanel>
                {/* <EnvironmentalTabs {...environmentProps} /> */}
                <TabPanel value={value} index={2}>
                  <Box
                    sx={{
                      height: `${physicalTabHeight}px`,
                      overflow: "auto",
                    }}
                  >
                    <Grid
                      container
                      spacing={"15px"}
                      marginTop={"10px"}
                      // sx={{}}
                      sx={{
                        "& > .MuiGrid-item:nth-child(odd) > .MuiStack-root": {
                          borderRight: "1px solid #666672",
                        },
                      }}
                    >
                      {environmentalDemandData?.map((option, index) => (
                        <Grid item xs={6} key={index}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            paddingRight={"15px"}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              // "&:hover": { backgroundColor: "#E6F3FB66" },
                            }}
                          >
                            <Typography variant="p">
                              {option.Description}
                            </Typography>
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <FormControl>
                                <RadioGroup row sx={{ flexWrap: "nowrap" }}>
                                  {option.IsMultiLevel
                                    ? ["1", "2", "3", "4", "5"].map(
                                        (radioValue, index) => (
                                          <React.Fragment key={index}>
                                            {getCheckBoxEnvironmentalMultilevel(
                                              radioValue,
                                              option.Description,
                                              formik,
                                              handleFilterChange
                                            )}
                                          </React.Fragment>
                                        )
                                      )
                                    : ["N", "O", "F", "C"].map(
                                        (radioValue, index) => (
                                          <React.Fragment key={index}>
                                            {getCheckBoxEnvironmental(
                                              radioValue,
                                              option.Description,
                                              formik,
                                              handleFilterChange
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </TabPanel>
                <Stack
                  direction={"row"}
                  spacing={"10px"}
                  marginTop={"20px"}
                  alignItems={"flex-end"}
                >
                  <Box flexGrow={1}>
                    <textarea
                      ref={textareaRef}
                      value={notes || ""}
                      onChange={(e) => {
                        localStorage.setItem("notes", e.target.value);
                        setNotes(e.target.value);
                      }}
                      style={{
                        padding: "10px 12px 8px",
                        backgroundColor: "rgb(229, 231, 235)",
                        width: "100%",
                      }}
                      placeholder="Notes"
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      size="large"
                      startIcon={<SearchIcon />}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Box>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent={"space-between"}
                  className="HypotheticalPagination"
                  mt={"10px"}
                  sx={{ borderTop: "1px solid #666672", paddingTop: "10px" }}
                >
                  <Pagination
                    count={DOTTableData?.count}
                    color="primary"
                    page={currentPage}
                    onChange={onPaginationChange}
                  />
                  <Stack direction={"row"} spacing={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={onDelete}
                    >
                      {isDeleteloading ? (
                        <CircularProgress
                          style={{ width: 25, height: 25, color: "red" }}
                        />
                      ) : (
                        "Delete"
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<DuplicateIcon />}
                      onClick={onDuplicate}
                    >
                      {isAddNewloading ? (
                        <CircularProgress style={{ width: 25, height: 25 }} />
                      ) : (
                        "Duplicate"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={onAddNew}
                    >
                      {isAddNewloading ? (
                        <CircularProgress
                          style={{ width: 25, height: 25, color: "white" }}
                        />
                      ) : (
                        "Add New"
                      )}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box className="bg-white" padding={2} borderRadius={"10px"}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingBottom={1}
              >
                <Box>
                  <Typography variant="h6">Filtered Occupations</Typography>
                </Box>
                <Stack direction={"row"} spacing={1}>
                  <Box>
                    <Button variant="contained" fullWidth color="primary">
                      DOT
                    </Button>
                  </Box>
                  <Box>
                    <Link to="/HypotheticalToolSummaryORS">
                      <Button variant="contained" fullWidth color="greyPrimary">
                        ORS
                      </Button>
                    </Link>
                  </Box>
                  <AnalyzerFilters
                    filtersSorting={filtersSorting}
                    setFiltersSorting={setFiltersSorting}
                  />
                  <AnalyzerSorting
                    filtersSorting={filtersSorting}
                    setFiltersSorting={setFiltersSorting}
                  />
                </Stack>
              </Stack>
              <Box
                sx={{
                  height: `${occupationsHeight}px`,
                  overflowY: "auto",
                  position: "relative",
                }}
              >
                {isloading && (
                  <Stack
                    sx={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 4,
                      backgroundColor: "#95959512",
                      borderRadius: "10px",
                    }}
                  >
                    <CircularProgress />
                  </Stack>
                )}
                {filterOccupationData?.map((item, index) => {
                  return (
                    <Box
                      sx={{
                        padding: "12px",
                        marginTop: "5px",
                        borderBottom: "1px solid #666672",
                      }}
                      key={item?.Id || item?.id}
                    >
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Stack direction={"row"} alignItems={"center"}>
                          <input
                            type="checkbox"
                            disableRipple
                            size="small"
                            id={`CheckBoxOwner${index}`}
                            sx={{ padding: 0 }}
                            checked={item.isChecked}
                            name={item["2018SocCode"]}
                            onClick={() => {
                              handleResultToggle(item);
                            }}
                          />
                          <InputLabel
                            htmlFor={`CheckBoxOwner${index}`}
                            sx={{ cursor: "pointer", marginLeft: "5px" }}
                          >
                            <Typography variant="p" color={"text.primary"}>
                              <b> {item?.Title || item?.title}</b>
                              <Typography
                                variant="span"
                                component={"span"}
                                color={"text.grey"}
                                marginLeft={"8px"}
                              >
                                {item?.DotCodeFormated}
                              </Typography>
                            </Typography>
                          </InputLabel>
                        </Stack>
                        <Box>
                          <Button
                            variant="outlined"
                            size="small"
                            color="DetailsBtn"
                            endIcon={<RightArrowIcon />}
                            onClick={() => handledViewDetails(item)}
                          >
                            View Details
                          </Button>
                        </Box>
                      </Stack>

                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid
                          container
                          sx={{
                            marginTop: "15px",
                            "& > div:not(:first-of-type)": {
                              borderLeft: "1px solid #666672",
                              paddingLeft: "15px",
                            },
                          }}
                        >
                          <Grid item xs={3}>
                            <Stack direction={"row"} flexGrow={1}>
                              <Typography variant="p" color={"text.grey"}>
                                Physical:
                              </Typography>
                              <Typography
                                flexGrow={1}
                                variant="p"
                                component={"p"}
                                color={"text.primary"}
                                marginLeft={"8px"}
                                textAlign={"center"}
                              >
                                {
                                  physicalDemandLevels?.find(
                                    (value) =>
                                      value?.id ===
                                      (item?.Strength || item?.physical)
                                  )?.label
                                }
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={3}>
                            <Stack direction={"row"} flexGrow={1}>
                              <Typography variant="p" color={"text.grey"}>
                                SVP
                              </Typography>
                              <Typography
                                flexGrow={1}
                                variant="p"
                                component={"p"}
                                color={"text.primary"}
                                marginLeft={"8px"}
                                textAlign={"center"}
                              >
                                {item?.SVP || item?.svp}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={3}>
                            <Stack direction={"row"} flexGrow={1}>
                              <Typography variant="p" color={"text.grey"}>
                                Mean OEWS
                              </Typography>
                              <Typography
                                flexGrow={1}
                                variant="p"
                                component={"p"}
                                color={"text.primary"}
                                marginLeft={"8px"}
                                textAlign={"center"}
                              >
                                {item
                                  ? Number(item.avgOES)?.toLocaleString("en-US")
                                  : ""}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={3}>
                            <Stack direction={"row"} flexGrow={1}>
                              <Typography variant="p" color={"text.grey"}>
                                Custom
                              </Typography>
                              <Typography
                                flexGrow={1}
                                variant="p"
                                component={"p"}
                                color={"text.primary"}
                                marginLeft={"8px"}
                                textAlign={"center"}
                              >
                                {Number(
                                  item?.custom || item?.customEmployment
                                ).toLocaleString("en-US")}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Box>
                  );
                })}
              </Box>

              <Button
                variant="contained"
                size="large"
                sx={{ marginTop: 0.5 }}
                onClick={onSave}
                disabled={disabledSaveButton}
              >
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HypotheticalAnalyzer;
