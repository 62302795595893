import React, { Fragment } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  Stack,
  DialogActions,
} from "@mui/material";
import DeleteModelIcon from "../../assets/images/icons/delete-modal-icon.svg";
import { useNavigate } from "react-router";

const SignOutModel = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.clear();
    window.location.reload();
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "25%", borderRadius: "25px" } }}
      >
        <DialogContent sx={{ padding: 3 }}>
          <Stack gap={1} alignItems={"center"}>
            <Box>
              <img src={DeleteModelIcon} alt="" />
            </Box>
            <Typography variant="p" paddingTop={1}>
              Are you sure to to Sign Out?
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: 3, paddingTop: 0, gap: 2 }}>
          <Box flexGrow={1}>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              color="error"
              onClick={handleLogout}
            >
              Sign Out
            </Button>
          </Box>
          <Box flexGrow={1}>
            <Button
              color="grey"
              variant="contained"
              sx={{ width: "100%" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default SignOutModel;
