import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Logo from "../../../assets/images/logos/ve-toolkit-logo.svg";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import CreditCardIcon from '@mui/icons-material/CreditCard';
// import PaymentIcon from '@mui/icons-material/Payment';
// import DateRangeIcon from '@mui/icons-material/DateRange';

function CardInfoSection() {
  return (
    <Container maxWidth sx={{  paddingBottom: '20px', px: { xs: "40px" } }}>
      <Box paddingTop={'40px'} paddingBottom={'50px'} > <img src={Logo} alt="" /> </Box>
      <Typography
        mb={'28px'}
        fontSize={"28px"}
        fontWeight={"600"}
        color={"primary.main"}
      >
        Select Subscription{" "}
        <span style={{ color: "#000" }}>Package to Continue</span>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Card Holder Name</Typography>
          <TextField
            fullWidth
            placeholder="Enter cardholder name"
            variant="filled"
            hiddenLabel
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <PersonOutlineIcon /> */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Card Number</Typography>
          <TextField
            fullWidth
            placeholder="Enter your Number"
            variant="filled"
            hiddenLabel
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <CreditCardIcon /> */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Expiry Date</Typography>
          <TextField
            fullWidth
            placeholder="MM/YY"
            variant="filled"
            hiddenLabel
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <DateRangeIcon /> */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">CCV Number</Typography>
          <TextField
            fullWidth
            placeholder="Enter CCV number"
            variant="filled"
            hiddenLabel
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <PaymentIcon /> */}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
     <Box mt={2} width="100%" display={"flex"} justifyContent={'right'} >
        <Button variant="contained" color="primary" size="large" >Continue to Pay</Button>
      </Box>
    </Container>
  );
}

export default CardInfoSection;
