import { styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
const BpIcon = styled("span")(() => ({
  borderRadius: "5px",
  width: 20,
  height: 20,
  backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F0F0F0"/><path d="M6.82 14C6.732 14 6.664 13.976 6.616 13.928C6.568 13.872 6.544 13.804 6.544 13.724V5.888C6.544 5.8 6.568 5.732 6.616 5.684C6.664 5.628 6.732 5.6 6.82 5.6H7.372C7.468 5.6 7.54 5.624 7.588 5.672C7.636 5.712 7.664 5.744 7.672 5.768L11.656 11.888V5.888C11.656 5.8 11.68 5.732 11.728 5.684C11.776 5.628 11.844 5.6 11.932 5.6H12.508C12.596 5.6 12.664 5.628 12.712 5.684C12.768 5.732 12.796 5.8 12.796 5.888V13.712C12.796 13.792 12.768 13.86 12.712 13.916C12.664 13.972 12.6 14 12.52 14H11.944C11.856 14 11.788 13.976 11.74 13.928C11.7 13.88 11.672 13.848 11.656 13.832L7.684 7.772V13.724C7.684 13.804 7.656 13.872 7.6 13.928C7.552 13.976 7.484 14 7.396 14H6.82Z" fill="%23666672"/></svg>')`,
  "input:hover ~ &": {
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%23F4FAFD"/><path d="M6.82 14C6.732 14 6.664 13.976 6.616 13.928C6.568 13.872 6.544 13.804 6.544 13.724V5.888C6.544 5.8 6.568 5.732 6.616 5.684C6.664 5.628 6.732 5.6 6.82 5.6H7.372C7.468 5.6 7.54 5.624 7.588 5.672C7.636 5.712 7.664 5.744 7.672 5.768L11.656 11.888V5.888C11.656 5.8 11.68 5.732 11.728 5.684C11.776 5.628 11.844 5.6 11.932 5.6H12.508C12.596 5.6 12.664 5.628 12.712 5.684C12.768 5.732 12.796 5.8 12.796 5.888V13.712C12.796 13.792 12.768 13.86 12.712 13.916C12.664 13.972 12.6 14 12.52 14H11.944C11.856 14 11.788 13.976 11.74 13.928C11.7 13.88 11.672 13.848 11.656 13.832L7.684 7.772V13.724C7.684 13.804 7.656 13.872 7.6 13.928C7.552 13.976 7.484 14 7.396 14H6.82Z" fill="%230089D7"/></svg>')`,
  },
  //   'input:disabled ~ &': {
  //     background:'rgba(206,217,224,.5)',
  //   },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  "&::before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="20" height="20" rx="4" fill="%230089D7"/><path d="M6.82 14C6.732 14 6.664 13.976 6.616 13.928C6.568 13.872 6.544 13.804 6.544 13.724V5.888C6.544 5.8 6.568 5.732 6.616 5.684C6.664 5.628 6.732 5.6 6.82 5.6H7.372C7.468 5.6 7.54 5.624 7.588 5.672C7.636 5.712 7.664 5.744 7.672 5.768L11.656 11.888V5.888C11.656 5.8 11.68 5.732 11.728 5.684C11.776 5.628 11.844 5.6 11.932 5.6H12.508C12.596 5.6 12.664 5.628 12.712 5.684C12.768 5.732 12.796 5.8 12.796 5.888V13.712C12.796 13.792 12.768 13.86 12.712 13.916C12.664 13.972 12.6 14 12.52 14H11.944C11.856 14 11.788 13.976 11.74 13.928C11.7 13.88 11.672 13.848 11.656 13.832L7.684 7.772V13.724C7.684 13.804 7.656 13.872 7.6 13.928C7.552 13.976 7.484 14 7.396 14H6.82Z" fill="white"/></svg>')`,
    content: '""',
  },
});
const NCheck = (props) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default NCheck;
