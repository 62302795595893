import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "utilitySlice",
  initialState: {
    isLoading: true,
    isLoadingComponent: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    LOADING_SUCCESS: (state, action) => {
      return {
        ...state,
        isLoadingComponent: action.payload,
      };
    },
  },
});

export const { LOADING_SUCCESS, setLoading } = slice.actions;
export default slice.reducer;
